import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import ContractItem from "../Partial/ContractItem";
import Order from 'model/order';
import { getOrderDoc, getOrderESignContracts, getOrderInkSignContracts, uploadContractPage, uploadOrderDocPage, getOrderShipping } from "helpers/backendHelper";
import Preloader from "components/Shared/Preloader";
import NotFound from "pages/Error/NotFound";
import { Link } from "react-router-dom";
import { useSocketOn } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import OrderDoc from "model/orderDoc";
import { route } from "helpers/routeHelper";
import PropTypes from "prop-types";
import ContractPages from "../Partial/ContractPages";
import HowToUse from "../Partial/HowToUse";
import { showError } from "helpers/utilHelper";
import TrackingModal from "components/Shared/TrackingModal";
import { formatDate, formats } from "helpers/dateHelper";

// receives shipping data and transforms it into an array of objects containing date, description and address
const normalizeTrackingData = (shippingCompany, data) => {
  if (shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
    const events = data.map(event => {
      const address = [];
      const { city, stateOrProvinceCode, postalCode } = event.scanLocation;
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateOrProvinceCode) address.push(stateOrProvinceCode);
      if (postalCode) address.push(postalCode);
      // format the date that is of type ISO string
      const date = formatDate(event.date, formats.TRACKING_DATE);
      return {
        date,
        description: event.eventDescription,
        address,
      }
    })
    return events;
  }
  if (shippingCompany === Order.SHIPPING_COMPANY_UPS) {
    const events = data.map(event => {
      const address = [];
      const { city, stateProvince, postalCode } = event.location?.address || {};
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateProvince) address.push(stateProvince);
      if (postalCode) address.push(postalCode);
      // format the date that is of type YYYYMMDD
      const date = formatDate(event.date, formats.TRACKING_DATE);
      return {
        date,
        description: event.status?.description,
        address,
      }
    })
    return events;
  }
  return data;
}

const Contracts = ({ hasESign, goBack, order }) => {

  /********** STATE ********/

  // stores all captures for each contract
  const [contractCaptures, setContractCaptures] = useState([]);
  // stores open/closed state for each tab
  const [selectedContract, setSelectedContract] = useState(null);
  const [isBusy, setIsBusy] = useState(true);
  const [contracts, setContracts] = useState(null);
  const [contractsError, setContractsError] = useState(false);
  // check whether we can continue or finish
  const [isInkSignComplete, setIsInkSignComplete] = useState(false);
  const [canMoveForward, setCanMoveForward] = useState(false);
  const [moveForwardRoute, setMoveForwardRoute] = useState("finish");
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  // Data for modals
  const [shippingData, setShippingData] = useState(null);
  const [shippingDataError, setShippingDataError] = useState(false);

  /********** EFFECTS ********/

  // runs once on component mount
  useEffect(() => {
    getOrderInkSignContracts().then(res => {
      setIsBusy(false);
      if (res.docs) {
        // set default state for documents, captures and tabs state
        let capturesDefault = [];
        let tabsStateDefault = [];
        let docs = [];
        res.docs.forEach((contract, index) => {
          // contract is refered to by index, when performing captures
          // this helps keeping contracts sorted
          contract.index = index;
          // order uuid needed for page captures retrieval
          contract.orderSuuid = res.orderSuuid;
          capturesDefault[contract.id] = [];
          tabsStateDefault[contract.id] = false;
          docs[index] = contract;
        })
        setContractCaptures(capturesDefault);
        setContracts(docs);
        setSelectedContract(docs[0]);
      } else {
        // error fetching documents
        setContractsError(true);
        return;
      }
    }).catch(err => {
      // error fetching documents
      setContractsError(true);
      setIsBusy(false);
    })
    // check if order has e-sign required
    // we need to know if we must continue with e-sign or finish
    if (!hasESign) {
      setCanMoveForward(true);
      return;
    }
    setMoveForwardRoute("e_signature");
    getOrderESignContracts().then(res => {
      // if there is no e-sign doc ready to sign yet, user shouldn't move forward
      if (!res.docs?.length || !res.docs.find(doc => doc.status > OrderDoc.STATUS_PENDING_SIGNATURES_PLACEMENT)) {
        return;
      }
      let allSubmitted = true;
      res.docs.forEach(doc => {
        if (doc.signingId == null) {
          allSubmitted = false;
        }
      })
      if (allSubmitted) {
        setMoveForwardRoute("finish");
      }
      setCanMoveForward(true);
    }).catch(err => {
      // error fetching documents
    })
  }, []);

  // make verifications in order to determine if we
  // are able to continue with ink-sign or finish
  useEffect(() => {
    if (!contracts?.length) return
    let localCanMove = true;
    // if there are still some ink contracts to sign, don't allow the user to move to the next step
    contracts.forEach(contract => {
      if (contract.status < OrderDoc.STATUS_PENDING_DEALER_REVIEW || contract.status == OrderDoc.STATUS_REJECTED) {
        localCanMove = false;
      }
    })
    // otherwise, allow the user to move to the next step
    setIsInkSignComplete(localCanMove);
  }, [contracts]);

  // Shipping
  useEffect(() => {
    if (order?.shippingPackageAwb) {
      getShippingData();
    }
  }, [order?.shippingPackageAwb]);

  const toggleShippingModal = () => setIsShippingModalOpen(!isShippingModalOpen);

  const getShippingData = () => {
    getOrderShipping(order.id)
      .then(response => {
        // the shipping response object is different for UPS/Fedex, we need to bring it to a standard form
        const normalizedData = normalizeTrackingData(order.shippingCompany, response.response.events);
        setShippingData(normalizedData);
      })
      .catch(err => setShippingDataError(true));
  }


  /****** UPLOAD ******/

  // upload single image file
  const upload = async (file, pageId, contractId) => {
    const image = await toBase64(file)
    let updatedCaptures = contractCaptures;
    if (!updatedCaptures[contractId]) {
      updatedCaptures[contractId] = [];
    }
    updatedCaptures[contractId][pageId] = image;
    setContractCaptures(updatedCaptures);
    uploadBinaryImage(contractId, pageId, file);
  }

  const uploadBinaryImage = async (docId, pageNum, image) => {
    const formData = new FormData();
    formData.append("pageImg", image);
    try {
      await uploadOrderDocPage(formData, docId, pageNum);
    } catch (err) {
      showError("Unable to upload page");
    } finally {

    }
  }

  /****** UTILS *******/

  const selectContract = (contract) => {
    setSelectedContract(contract);
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  /********** EVENT LISTENERS ********/

  // runs whenever the order doc changed event is received from the backend
  // in order to update the contracts
  const onOrderDocChanged = useCallback(({ id }) => {
    getOrderDoc(id).then(res => {
      if (!res.orderDoc) {
        return;
      }
      setIsBusy(true);
      const orderDoc = res.orderDoc;
      let oldDocs = [...contracts];
      const index = oldDocs.findIndex(elem => elem.id == orderDoc.id);
      // set the new doc status, and pages statuses
      oldDocs[index].status = orderDoc.status;
      oldDocs[index].pages = orderDoc.pages;
      setContracts(oldDocs);
      setSelectedContract(oldDocs[selectedContract.index])
      setIsBusy(false);
    }).catch(err => {
      // if socket fails, don't show error.
      // page refresh will fix the issue
    })
  }, [contracts, selectedContract?.index]);

  // listen for the order doc changed event
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  return <React.Fragment>
    {contracts && <>
      <Card id="contracts_card">
        <CardBody id="contracts_card_body" className="pb-4">
          <Row className="contracts-row">
            <Col md={3}>
              <div className="tracking-number-parent">
                <div className="tracking-text">Tracking number</div>
                <div className="text-center">
                  {order?.shippingPackageAwb ? <span className='tracking-link cursor-pointer' onClick={() => setIsShippingModalOpen(true)}>
                    {order?.shippingPackageAwb}</span> : '--'}
                </div>
              </div>
              {!!contracts.length && contracts.map((contract, index) => (
                <ContractItem contract={contract} key={index} captures={contractCaptures} selectTab={selectContract} selectedContract={selectedContract} />
              ))}
            </Col>
            <Col md={9} className="ps-4 pe-5">
              <Row>
                <HowToUse />
              </Row>
              <Row>
                {selectedContract &&
                  <ContractPages contract={selectedContract} captures={contractCaptures} upload={upload} />
                }
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="btns-wrapper mt-4">
              <button id="backBtn" className="btn btn-outline-primary" onClick={goBack}>Back</button>
              {isInkSignComplete && canMoveForward && <Link id="continueBtn" className="btn btn-primary" to={route(moveForwardRoute)}>Continue</Link>}
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
    }
    {/* Shipping Modal */}
    <TrackingModal
      isOpen={isShippingModalOpen}
      toggle={toggleShippingModal}
      modalData={shippingData}
      modalDataError={shippingDataError}
      closeModal={() => setIsShippingModalOpen(false)}
      trackingNumber={order?.shippingPackageAwb}
    />
    {isBusy && <Preloader />}
    {contractsError && <NotFound />}
  </React.Fragment >
}

Contracts.propTypes = {
  hasESign: PropTypes.number,
  goBack: PropTypes.func,
  order: PropTypes.object,
};

export default Contracts;
