import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Container } from "reactstrap";

const Consent = ({ accept, back }) => {

  return <React.Fragment>
    <div id="id_consent" className="account-pages">
      <Container>
        <Row className="justify-content-center">
          <Col md={9} lg={6} xl={5} className="vid-column">
            <Card className="overflow-hidden">
              <button className="mt-2 exit-consent" onClick={back}><i className="fas fa-times"></i></button>
              <h3 className="mt-4 mb-4">Consent to the use and release of my digital photograph</h3>
              <p>Maverick has been requested by the Dealer to verify your identity through the use of its VerifyID<sup>1</sup> application.</p>
              <p>This request has been made as part of the vehicle transaction you have entered into with the Dealer.</p>
              <p>As part of the VerifyID process you will be asked to provide a digital copy of your identity card and of yourself using a cell phone or other digital process.</p>
              <p>These digital copies will then be transmitted to Maverick and the Dealer where they will be compared to one another or to other personal identifiers to validate your identity.</p>
              <p className="mt-4">Maverick maintains the materials, information and digital copies provided by you for a short time after they have been provided.</p>
              <p>Maverick’s purpose in obtaining your digital portrait and any copies provided in using its VerifyID application is to insure integrity, security and reliability in the transaction and validate your identity in completing the transaction you have requested from the Dealer.</p>
              <p>They are not used, disseminated, or maintained by Maverick for any other commercial purpose. Maverick does not make public, sell, use or otherwise disseminate any information collected from its VerifyID process or application.</p>
              <p className="mt-4">Only Maverick’s authorized personnel have access to the information maintained by Maverick through a computer system that is encrypted and password protected. </p>
              <p>The digital information is typically retained for a one (1) year period to satisfy the purpose of insuring the integrity and verification of identification in completing the transaction you requested and the digital record is then destroyed and rendered unrecoverable by any means no later than when the purpose for collecting has been satisfied, or three (3) years after the date of the transaction, whichever occurs first.<sup>2</sup> </p>
              <p className="mt-4">I acknowledge this Notice and consent to the verification of my identity through the VerifyID validation software application.</p>
              <p>I understand that I must remove any mask and/or all face shielding for the taking of the digital photograph and for the ID validation software to be used.</p>
              <p>I agree to the use of VerifyID validation software and this process.</p>
              <hr></hr>
              <p className="footer-info"><sup>1</sup>Trademark and copyrights pending</p>
              <p className="footer-info"><sup>2</sup>For residents of Illinois, the storage term is limited to three years from the date of the transaction or when the purpose for collecting the thumbprint is satisfied, whichever occurs first.</p>
            </Card>
            <Row className="mb-3 consent-actions">
              <Col>
                <button className="btn btn-outline-primary w-100 back" onClick={back}>Back</button>
              </Col>
              <Col>
                <button className="btn btn-primary w-100 accept" onClick={accept}>Accept</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

Consent.propTypes = {
  accept: PropTypes.func,
  back: PropTypes.func,
};

export default Consent;