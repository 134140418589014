import React, { useState } from "react";
import PropTypes from "prop-types";
import { Offcanvas, OffcanvasBody } from "reactstrap";
import StepRecipient from "./StepRecipient";
import StepOptions from "./StepOptions";
import classnames from "classnames";

const MobileContactModal = ({ order, open, onToggle, onOpenChat }) => {

  const [selectedPerson, setSelectedPerson] = useState();

  const clearSelectedPerson = () => {
    setSelectedPerson(null);
  }

  return (
    <Offcanvas
      id="contact-modal"
      className={classnames("contact-offcanvas", { "expanded": !!selectedPerson })}
      isOpen={open}
      toggle={onToggle}
      onClosed={clearSelectedPerson}
      direction="bottom"
    >
      <OffcanvasBody>
        <div className="line" />
        {!selectedPerson && <StepRecipient order={order} selectPerson={person => setSelectedPerson(person)} onOpenChat={onOpenChat} />}
        {!!selectedPerson && <StepOptions selectedPerson={selectedPerson} onOpenChat={onOpenChat} personRoleLabel={'Contact your '} />}
      </OffcanvasBody>
    </Offcanvas>
  );
}

MobileContactModal.propTypes = {
  order: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onOpenChat: PropTypes.func.isRequired,
}

export default MobileContactModal;