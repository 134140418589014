import React from 'react';
import PropTypes from 'prop-types';
import DocViewerLayout from './Layout';
import DocViewerContext from './Context';

const DocViewer = props => {

  const { docId } = props;

  // Although this is not a list item, we still want to use a key
  // to force the viewer component to be destroyed and recreated each time a new doc is loaded
  // therefore resetting its state (sizes and zoom calculations, etc)
  // https://twitter.com/sebmarkbage/status/1262937670444974081
  return <DocViewerContext key={'docvwr' + docId} {...props}>
    <DocViewerLayout />
  </DocViewerContext>
}

DocViewer.propTypes = {
  docId: PropTypes.number.isRequired,
  currentSignerId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  onSigningStarted: PropTypes.func,
  onViewerClosed: PropTypes.func,
  onSigningComplete: PropTypes.func,
  onDocLoaded: PropTypes.func,
}

export default DocViewer;