import { route } from "helpers/routeHelper";
import VidIndex from "pages/Vid/Index/Index";
import Appointment from "pages/Appointment/Index";
import InkIndex from "pages/InkSignature/Index/Index";
import ElectronicIndex from "pages/ESignature/Index/Index";
import SignaturesIndex from "pages/Signatures/Index";
import Finance from "pages/FinanceDocs/Index";
import Home from "pages/Home/Home";
import Finish from "pages/Finish/Index";
import Welcome from "pages/Welcome";

const verifiedUserRoutes = [
  { path: route('home'), component: Home },
  // there isn't a separate component for messages screen, because the chat is rendered in a modal
  // all the request are routed to Home, where the menu components extract any URL parameters and handle the modal states
  { path: route('messages'), component: Home },
  { path: route('vid'), component: VidIndex },
  { path: route('appointment'), component: Appointment },
  { path: route('signatures'), component: SignaturesIndex },
  { path: route('finance'), component: Finance },
  { path: route('ink_signature'), component: InkIndex },
  { path: route('e_signature'), component: ElectronicIndex },
  { path: route('finish'), component: Finish },
];

const unverifiedUserRoutes = [
  { path: route('welcome'), component: Welcome },
]

export { verifiedUserRoutes, unverifiedUserRoutes }
