import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Modal, ModalBody, Card } from "reactstrap";
import ChatSidebar from "./Chat/Sidebar";
import ChatModalHeader from "./Chat/ModalHeader";
import ChatConversation from "./Chat/Conversation";
import { useDispatch } from "react-redux";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import { getMessages } from "store/actions";
import socketEvent from "constants/socketEvent";
import "react-perfect-scrollbar/dist/css/styles.css";
import channels from "constants/channels";

const DesktopChatModal = ({ open, onToggle, channel }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const [openChannel, setOpenChannel] = useState(channel);

  /********** OTHER **********/

  // load all messages for each channel the user has access to
  const getAllMessages = useCallback(() => {
    Object.keys(channels).map(chId => {
      dispatch(getMessages(chId));
    });
  }, []);

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  // refresh messages whenever a `messageReceived` event is coming from the backend
  // this event is emitted for every message sent, including own messages
  const onMessageReceived = useCallback(() => getAllMessages(), [getAllMessages]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we need to fetch all messages from the start so we can display the latest message in the sidebar
    getAllMessages();
  }, []);

  // sync local state to channel received
  useEffect(() => {
    setOpenChannel(channel)
  }, [channel]);

  /********** HANDLERS **********/

  const handleOpenChannel = id => event => {
    // prevent the Link component from redirecting
    event.preventDefault();

    setOpenChannel(id);
  };

  return <Modal id="desktop_chat_modal" fullscreen isOpen={open} toggle={onToggle}>
    <ChatModalHeader onCloseChat={onToggle} />
    <ModalBody className="pt-0">
      <Container fluid>
        <Row>
          <Col>
            <div id="messages_desktop_wrapper" className="d-lg-flex">
              <ChatSidebar channelId={+openChannel} onOpenChannel={handleOpenChannel} />
              <Col sm={12} lg={9} className="user-chat">
                <Card>
                  <ChatConversation channelId={+openChannel} height={470} />
                </Card>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
}

DesktopChatModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  channel: PropTypes.number.isRequired,
};

export default DesktopChatModal;