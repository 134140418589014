import React from "react";
import PropTypes from "prop-types";

const CameraPermissionDenied = ({ exitDialog }) => {

  CameraPermissionDenied.propTypes = {
    exitDialog: PropTypes.func
  };

  return <React.Fragment>
    <div className="camera-permission-dialog-wrapper">
      <button id="closeDialogBtn" onClick={exitDialog}>
        <i className="fas fa-times"></i>
      </button>
      <div className="camera-permission-dialog-content">
        <div className="mt-3 info">You must allow camera access in order to perform documents/selfie capture.</div>
        <div className="btns-wrapper mt-4">
          <button className="btn btn-primary" onClick={exitDialog}>Ok, got it</button>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default CameraPermissionDenied;
