import React, { useState } from 'react';
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import OrderDoc from 'model/orderDoc';
import { useSubscribeToOrderDoc } from 'hooks/socket';
import { getBeUrl } from 'helpers/utilHelper';
import { addAccessToken } from 'helpers/tokenHelper';
import { timestamp } from 'helpers/dateHelper';

const ContractItem = ({ contract, selectTab, selectedContract }) => {

  // start receiving updates about this particular order doc
  useSubscribeToOrderDoc(contract.id)

  ContractItem.propTypes = {
    contract: PropTypes.object,
    selectTab: PropTypes.func,
    selectedContract: PropTypes.object,
  };

  const [reasonsExpanded, setReasonsExpanded] = useState(false);

  const getPagesRejectionReason = () => {
    let content = [];
    // get array of rejected pages
    const rejectedPages = Object.entries(contract.pages).reduce((list, entry) => {
      if (entry[1].status == OrderDoc.PAGE_STATUS_REJECTED) {
        list.push({ ...entry[1], num: entry[0] });
      }
      return list;
    }, []);
    // add reason for each rejected page
    rejectedPages.forEach((page, index) => {
      if (!reasonsExpanded && index > 0) {
        return 0;
      }
      const fullReason = page.rejectionReason;
      let shortReason = page.rejectionReason.substr(0, 120);
      if (shortReason != fullReason) {
        shortReason += ' ...';
      }
      let contentPage = <span key={index}>
        <div className="reason-label">Page {page.num} rejected reason</div>
        <div className="rejection-description">{reasonsExpanded ? fullReason : shortReason}</div>
      </span>;
      content.push(contentPage);
    })
    if (rejectedPages.length > 1 || (rejectedPages.length >= 1 && rejectedPages[0].rejectionReason.length > 120)) {
      // show 'see more' btn only if there are more than 1 reasons, or the one existing reason has more than 120 characters
      let btn = <button className="toggle-reasons-btn" onClick={() => setReasonsExpanded(!reasonsExpanded)} key="btn-key">
        {!reasonsExpanded && "see more"}
        {reasonsExpanded && "see less"}
      </button>
      content.push(btn);
    }
    return content;
  }

  // an order doc status is the min status of its pages, therefore we have to find out if any of the pages was rejected and display the correct label
  let isRejected = false;
  if (contract.pages) {
    const pagesArr = Object.values(contract.pages);
    isRejected = Boolean(pagesArr.find(page => page.status === OrderDoc.PAGE_STATUS_REJECTED));
  }

  return (
    <div className="accordion-item" key={contract.id} contract-id={contract.id}>
      <Row className={"contract-item " + (selectedContract && selectedContract.id == contract.id ? 'active' : '')} onClick={contract.status >= OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE ? () => selectTab(contract) : null}>
        <Col className="p-0">
          <div className="contract-title">Contract number {contract.index + 1}</div>
          <div className="contract-desc">{contract.name}</div>
          <div className="badges-section">
            {contract.status < OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
              <span className="badge rounded-pill bg-secondary contract-badge status-name">Not ready<i className="bx bx-hourglass ms-1"></i></span>
              <span className="badge rounded-pill bg-secondary contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
              <span className="badge rounded-pill bg-primary contract-badge status-name">Ready to sign<i className="mdi mdi-credit-card-scan-outline ms-1"></i></span>
              <span className="badge rounded-pill bg-primary contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_PENDING_DEALER_REVIEW && <>
              <span className="badge rounded-pill bg-warning contract-badge status-name"><span>Pending review</span><i className="bx bx-time-five ms-1"></i></span>
              <span className="badge rounded-pill bg-warning contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_REJECTED && <>
              <span className="badge rounded-pill bg-danger contract-badge status-name">Rejected<i className="bx bx-x-circle ms-1"></i></span>
              <span className="badge rounded-pill bg-danger contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_COMPLETE && <>
              <span className="badge rounded-pill bg-success contract-badge status-name">Accepted<i className="bx bx-check-circle ms-1"></i></span>
              <span className="badge rounded-pill bg-success contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
          </div>
          <div className="font-size-11">
            {contract.signingInstructions}
          </div>
          {isRejected &&
            <div className="rejected-reason-wrapper ink-sign">
              {getPagesRejectionReason()}
            </div>
          }
        </Col>
        {contract.status >= OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
          {!!contract.isUploaded &&
            <Col xs={1} className="p-0 accord-print">
              <a href={getBeUrl(addAccessToken(`order-doc/${contract.id}/pdf/download?${timestamp()}`))} >
                <i className="bx bxs-printer"></i>
              </a>
            </Col>
          }
          <Col xs={1} className="p-0 accord-arrow">
            <i className="fas fa-angle-right"></i>
          </Col>
        </>
        }
      </Row>
    </div >
  );
};

export default ContractItem;