import React, { useState } from "react";
import { Row, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import IDFrontInfo from "../Partial/IDFrontInfo";
import IDBackInfo from "../Partial/IDBackInfo";
import SelfieInfo from "../Partial/SelfieInfo";
import Tips from "../Partial/Tips";
import classnames from "classnames";

import exampleFront from 'assets/images/instructions-examples/id-front-example.png';
import exampleBack from 'assets/images/instructions-examples/id-back-example.png';
import exampleSelfie from 'assets/images/instructions-examples/selfie-example-2.png';

const DriverLicenceInstructions = ({ frontSrc, backSrc, selfieSrc, startCapture, back, complete }) => {

  /********** STATE **********/
  const [isTipsVisible, setIsTipsVisible] = useState(false);

  /********** OTHER **********/
  const IMAGE_TYPE_DL_FRONT = 'DL_FRONT';
  const IMAGE_TYPE_DL_BACK = 'PDF417_BARCODE';
  const IMAGE_TYPE_SELFIE = 'SELFIE';

  const isFrontInfo = !frontSrc;
  const isBackInfo = frontSrc && !backSrc;
  const isSelfieInfo = frontSrc && backSrc;

  return <React.Fragment>
    <div className="id-instructions">
      <CardBody>
        <div id="actionsRow" className="mb-4">
          <button className="back-btn" onClick={back}><i className="fas fa-arrow-left"></i></button>
          <button className={classnames('btn', 'tips-btn', { active: isTipsVisible })}
            onClick={() => setIsTipsVisible(!isTipsVisible)}>Important Tips</button>
        </div>

        {isTipsVisible && <Tips isFrontInfo={isFrontInfo}
          isBackInfo={isBackInfo}
          isSelfieInfo={isSelfieInfo}
        />}

        <div className="preview-row">
          <div id="frontPreview" className="small-preview">
            <label className={!frontSrc || 'success'}>1. Front of ID</label>
            <div className="image-wrapper">
              <img src={frontSrc ? frontSrc : exampleFront}></img>
            </div>
            {!frontSrc ?
              <button className="btn btn-primary" onClick={() => startCapture(IMAGE_TYPE_DL_FRONT)}>Scan</button>
              :
              <button className="btn btn-success" onClick={() => startCapture(IMAGE_TYPE_DL_FRONT)}>Retake</button>
            }
          </div>
          <div id="backPreview" className="small-preview">
            <label className={!backSrc || 'success'}>2. Back of ID</label>
            <div className="image-wrapper">
              <img src={backSrc ? backSrc : exampleBack}></img>
            </div>
            {!backSrc ?
              <button className={'btn btn-primary ' + (!frontSrc ? 'disabled' : '')} onClick={() => startCapture(IMAGE_TYPE_DL_BACK)}>Scan</button>
              :
              <button className="btn btn-success" onClick={() => startCapture(IMAGE_TYPE_DL_BACK)}>Retake</button>
            }
          </div>
          <div id="selfiePreview" className="small-preview">
            <label className={!selfieSrc || 'success'}>3. Selfie</label>
            <div className="image-wrapper">
              <img src={selfieSrc ? selfieSrc : exampleSelfie}></img>
            </div>
            {!selfieSrc ?
              <button className={'btn btn-primary ' + (!backSrc ? 'disabled' : '')} onClick={() => startCapture(IMAGE_TYPE_SELFIE)}>Scan</button>
              :
              <button className="btn btn-success" onClick={() => startCapture(IMAGE_TYPE_SELFIE)}>Retake</button>
            }
          </div>
        </div>
        {!frontSrc && <IDFrontInfo />}
        {frontSrc && !backSrc && <IDBackInfo />}
        {frontSrc && backSrc && <SelfieInfo />}
        <div className="submit-wrapper">
          <button className={'btn btn-primary w-100 ' + (!frontSrc || !backSrc || !selfieSrc ? 'disabled' : '')} onClick={complete}>Submit</button>
        </div>
      </CardBody>
    </div>
  </React.Fragment>
}

DriverLicenceInstructions.propTypes = {
  frontSrc: PropTypes.string,
  backSrc: PropTypes.string,
  selfieSrc: PropTypes.string,
  startCapture: PropTypes.func,
  back: PropTypes.func,
  complete: PropTypes.func,
};

export default DriverLicenceInstructions;