import { useEffect, useCallback } from 'react';
import { useSocket } from 'context/socket';
import socketEvent from 'constants/socketEvent';

export const useSocketOn = (eventName, eventHandler, condition) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled()) {
      socket.on(eventName, eventHandler);
    }
    return () => {
      if (isEnabled()) {
        socket.off(eventName, eventHandler);
      }
    }
  }, [eventName, eventHandler, isEnabled]);
}

export const useSubscribeToVidReq = id => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToVidReq, { id });
    }
    return () => {
      if (socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromVidReq, { id });
      }
    }
  }, [id, socket.id]);
}

export const useSubscribeToOrderDoc = id => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToOrderDoc, { id });
    }
    return () => {
      if (socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromOrderDoc, { id });
      }
    }
  }, [id, socket.id]);
}

export const useSubscribeToOrderMessages = () => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected) {
      socket.emit(socketEvent.subscribeToOrderMessages);
    }
    return () => {
      if (socket.connected) {
        socket.emit(socketEvent.unsubscribeFromOrderMessages);
      }
    }
  }, [socket.id]);
}

export const useSubscribeToOrderSigner = (id, condition) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled() && socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToOrderSigner, { id });
    }
    return () => {
      if (isEnabled() && socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromOrderSigner, { id });
      }
    }
  }, [id, isEnabled, socket.id]);
}