import {
  GET_ORDER,
  GET_ORDER_OK,
  GET_ORDER_ERR,
} from "./actionTypes";

const defaultState = {
  order: null,
  orderError: null,
  isBusy: false,
};

const Index = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ORDER:
      state = {
        ...state,
        orderError: null,
        isBusy: true,
      };
      break
    case GET_ORDER_OK:
      state = {
        ...state,
        order: action.payload.order,
        isBusy: false,
      };
      break
    case GET_ORDER_ERR:
      state = {
        ...state,
        order: null,
        orderError: action.payload,
        isBusy: false,
      };
      break
  }
  return state;
}

export default Index;
