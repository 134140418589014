export default class OrderSigner {

  static TYPE_CUSTOMER = 1;
  static TYPE_DEALER = 2;

  static ACTIVITY_STATUS_OFFLINE = 0;
  static ACTIVITY_STATUS_IDLE = 1;
  static ACTIVITY_STATUS_ACTIVE = 2;

  static getActivityStatusMap() {
    return {
      [this.ACTIVITY_STATUS_OFFLINE]: 'Offline',
      [this.ACTIVITY_STATUS_IDLE]: 'Idle',
      [this.ACTIVITY_STATUS_ACTIVE]: 'Active',
    };
  }

  static getActivityStatusName(id) {
    return this.getActivityStatusMap()[id];
  }
}