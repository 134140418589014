import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Mitek from "pages/Vid/MobileContent/Mitek/Mitek";
import Preloader from "components/Shared/Preloader";
import { getVidRequest, retryVid } from "helpers/backendHelper";
import Instructions from "./Steps/Instructions";
import VidComplete from "./Steps/VidComplete";
import VidFail from "./Steps/VidFail";
import VidSuccess from "./Steps/VidSuccess";
import socketEvent from "constants/socketEvent";
import vidTokenStatus from "constants/vidTokenStatus";
import NotFound from "pages/Error/NotFound";
import { uploadImages } from "helpers/backendHelper";
import { useSocket } from "context/socket";
import MobileMenu from "components/Shared/MobileMenu";
import { useSocketOn } from "hooks/socket";
import Consent from "./Steps/Consent";
import VidLimitReached from "./Steps/VidLimitReached";
import VidMitekApiFail from "./Steps/VidMitekFail";
import menuSteps from "constants/menuSteps";
import { useNavigator } from "context/navigator";
import config from 'config';

// steps of the VID process
const STEP_INSTRUCTIONS = 0;
const STEP_CONSENT = 1;
const STEP_MITEK = 2;
const STEP_COMPLETE = 3;
const STEP_FAIL = 4;
const STEP_SUCCESS = 5;
const STEP_LIMIT_REACHED = 6;
const STEP_MITEK_API_FAIL = 7;

const MobileContent = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  // hook that emits socket events
  const { socketEmit } = useSocket();

  /********** STATE ********/

  const [currentStep, setCurrentStep] = useState(STEP_INSTRUCTIONS);
  const [isBusy, setIsBusy] = useState(false);
  const [diagnose, setDiagnose] = useState('');
  const [hint, setHint] = useState('');
  const [vidRequest, setVidRequest] = useState(null);
  const [vidRequestError, setVidRequestError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [name, setName] = useState('');
  // check status only after successful image upload
  const [canCheckStatus, setCanCheckStatus] = useState(false);
  const [uploadProgressValue, setUploadProgressValue] = useState(0);

  /********** EFFECTS ********/

  // runs whenever the history object (that contains the URLs visited by the user) changes
  // including at component mount
  useEffect(() => {
    setIsBusy(true);
    getVidRequest().then(res => {
      const vidReq = res.vidRequest;
      if (!vidReq) {
        setVidRequestError(true);
      } else {
        setVidRequest(vidReq);
        // subscribe to vid request
        socketEmit(socketEvent.subscribeToVidReq, { id: vidReq.id });
        socketEmit(socketEvent.takeOverVidReq, { id: vidReq.id });
        // check vidRequest status in order to render the correct screen
        switch (vidReq.status) {
          case vidTokenStatus.PENDING:
          case vidTokenStatus.ESCALATED:
            setCanCheckStatus(true);
            setCurrentStep(STEP_COMPLETE);
            checkBackendResult();
            break;
          case vidTokenStatus.FAILED:
            if (vidReq.isLastTry) {
              setCurrentStep(STEP_LIMIT_REACHED);
              break;
            }
            setCurrentStep(STEP_FAIL);
            setDiagnose(vidReq.diagnose)
            setHint(vidReq.hint)
            break;
          case vidTokenStatus.MITEK_API_FAIL:
            setCurrentStep(STEP_MITEK_API_FAIL);
            break;
          case vidTokenStatus.SUCCESS:
            setCurrentStep(STEP_SUCCESS);
            break;
          case vidTokenStatus.SKIPPED:
            return navigate(getNextRoute());
        }
      }
      setIsBusy(false);
    }).catch(err => {
      setIsBusy(false);
      setVidRequest(null);
      setVidRequestError(true);
    });
  }, [history]);

  /********** HANDLERS ********/

  const decreaseStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const startMitek = (name, termsAccepted) => {
    setName(name);
    setTermsAccepted(termsAccepted)
    setCurrentStep(STEP_MITEK)
  }

  const acceptTerms = () => {
    setTermsAccepted(true);
    setCurrentStep(STEP_INSTRUCTIONS)
  }

  const openConsentPage = (terms, name) => {
    setTermsAccepted(terms);
    setName(name);
    setCurrentStep(STEP_CONSENT);
  }

  // perform new verification
  const tryAgain = () => {
    setIsBusy(true);
    retryVid().then(res => {
      setIsBusy(false);
      if (res.id) {
        setCurrentStep(STEP_MITEK);
      }
    }).catch(err => {
      setIsBusy(false);
    });;
  }

  // upload images in order to start mitek verification
  const upload = (payload) => {
    // until mitek verification result, go to complete step
    setCurrentStep(STEP_COMPLETE);
    checkBackendResult();
    // emit complete event for desktop
    emitComplete();
    uploadImages(payload, { onUploadProgress }).catch(err => {
      setCurrentStep(STEP_MITEK_API_FAIL)
    });
  }

  // listen for upload progress updates from the API
  // in order to display the % that has been uploaded
  const onUploadProgress = progressEvent => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setUploadProgressValue(percent);
  }

  // mobile vid steps
  const steps = [
    {
      component: Instructions,
      options: {
        next: startMitek,
        consent: openConsentPage,
        accepted: termsAccepted,
        customerName: name
      }
    },
    {
      component: Consent,
      options: {
        accept: acceptTerms,
        back: decreaseStep
      }
    },
    {
      component: Mitek,
      options: {
        upload: upload,
        id: vidRequest ? vidRequest.id : null,
        consentName: name,
        termsAccepted: termsAccepted
      }
    },
    {
      component: VidComplete,
      options: {
        canCheckStatus: canCheckStatus,
        progressValue: uploadProgressValue
      }
    },
    {
      component: VidFail,
      options: {
        diagnose: diagnose,
        hint: hint,
        tryAgain: tryAgain
      }
    },
    {
      component: VidSuccess,
    },
    {
      component: VidLimitReached,
    },
    {
      component: VidMitekApiFail,
      options: {
        tryAgain: tryAgain
      }
    }
  ];

  const checkBackendResult = () => {
    const mitekResultInterval = setInterval(() => {
      getVidRequest().then(res => {
        const vidReq = res.vidRequest;
        if (!vidReq) {
          return;
        }
        switch (vidReq.status) {
          case vidTokenStatus.FAILED:
            if (vidReq.isLastTry) {
              setCurrentStep(STEP_LIMIT_REACHED);
              break;
            }
            setCurrentStep(STEP_FAIL);
            setDiagnose(vidReq.diagnose)
            setHint(vidReq.hint)
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            break;
          case vidTokenStatus.MITEK_API_FAIL:
            setCurrentStep(STEP_MITEK_API_FAIL);
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            break;
          case vidTokenStatus.SUCCESS:
            setCurrentStep(STEP_SUCCESS);
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            break;
          case vidTokenStatus.SKIPPED:
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            return navigate(getNextRoute());
        }
      }).catch(err => {
        // if call fails, don't show error.
        // page refresh will fix the issue
      })
    }, config.FALLBACK_MITEK_RESULT_INTERVAL);
  }

  /********** SOCKET EVENTS **********/

  const emitComplete = () => {
    socketEmit(socketEvent.vidProcessComplete, { id: vidRequest.id })
  }

  /********* SOCKET LISTENERS ***********/

  // runs whenever the vid success event is received from the backend
  const onVidSuccess = useCallback(data => {
    // redirect to success screen
    setCurrentStep(STEP_SUCCESS);
  }, []);

  // listen for the vid success event
  useSocketOn(socketEvent.vidSuccess, onVidSuccess);

  // runs whenever the vid fail event is received from the backend
  const onVidFail = useCallback(data => {
    // refresh vid request
    getVidRequest().then(res => {
      const vidReq = res.vidRequest;
      if (!vidReq) {
        return;
      }
      if (vidReq.status == vidTokenStatus.FAILED) {
        // all the orders have a max number of vid request retries set on the backend
        // if this limit is reached, don't allow the user to retry the vid
        if (vidReq.isLastTry) {
          setCurrentStep(STEP_LIMIT_REACHED);
          return;
        }
        setCurrentStep(STEP_FAIL);
        setDiagnose(vidReq.diagnose)
        setHint(vidReq.hint)
      }
    }).catch(err => {
      // if socket fails, don't show error.
      // page refresh will fix the issue
    })
  }, []);

  // listen for the vid fail event
  useSocketOn(socketEvent.vidFail, onVidFail);

  // runs whenever the vid mitek api fail event is received from the backend
  const onVidMitekApiFail = useCallback(data => {
    // redirect to error page
    setCurrentStep(STEP_MITEK_API_FAIL);
  }, []);

  // listen for the vid mitek fail event
  useSocketOn(socketEvent.vidMitekApiFail, onVidMitekApiFail);

  const ActiveStep = steps[currentStep].component;

  return <React.Fragment>
    {vidRequest &&
      <>
        <MobileMenu activeStep={menuSteps.VID} />
        <div id="vid_mobile_wrapper">
          <ActiveStep {...steps[currentStep].options} />
        </div>
      </>
    }
    {isBusy && <Preloader />}
    {vidRequestError && <NotFound />}
  </React.Fragment>
}

export default MobileContent;
