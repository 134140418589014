import { takeEvery, put, call, all } from "redux-saga/effects";

import {
  GET_DOC_VIEWER_ORDER_DOC,
  SIGN_ORDER_DOC,
} from "./actionTypes";

import {
  getDocViewerOrderDocOk,
  getOrderDocErr,
  signOrderDocOk,
  signOrderDocErr,
} from "./actions";

import {
  getOrderDoc,
  signOrderDoc,
  getOrderDocSigners,
  getOrderDocSignings,
  getAuthUserSignatures,
} from "helpers/backendHelper";

function* onGetDocViewerOrderDoc({ payload: { id } }) {
  try {
    const [docResponse, signersResponse, signingsResponse, signaturesResponse] = yield all([
      call(getOrderDoc, id),
      call(getOrderDocSigners, id),
      call(getOrderDocSignings, id),
      call(getAuthUserSignatures),
    ]);
    yield put(getDocViewerOrderDocOk(docResponse, signersResponse, signingsResponse, signaturesResponse));
  } catch (error) {
    yield put(getOrderDocErr(error));
  }
}

function* onSignOrderDoc({ payload: { data, id } }) {
  try {
    const response = yield call(signOrderDoc, data, id);
    yield put(signOrderDocOk(response));
  } catch (error) {
    yield put(signOrderDocErr(error));
  }
}

function* orderDocSaga() {
  yield takeEvery(GET_DOC_VIEWER_ORDER_DOC, onGetDocViewerOrderDoc);
  yield takeEvery(SIGN_ORDER_DOC, onSignOrderDoc);
}

export default orderDocSaga;