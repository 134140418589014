import React from 'react';
import DocViewerContent from './Body/Content';
import DocViewerLeftBar from './Body/LeftBar';

const DocViewerBody = () => {

  return <div className="doc-viewer-body">
    <DocViewerLeftBar />
    <DocViewerContent />
  </div>
}

export default DocViewerBody;