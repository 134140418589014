import config from "config";
import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {

  return <React.Fragment>
    <div id="desktop_footer">
      <Container fluid={true}>
        <Row>
          <Col md={6}>{new Date().getFullYear()} © {config.APP_TITLE}.</Col>
          <Col md={6} className="text-end">v {config.APP_VERSION}</Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default Footer;