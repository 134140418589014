import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from "reactstrap";
import MobileContactModal from './ContactModal/Mobile';
import MobileChatModal from './MobileChatModal';
import logoSrc from 'assets/images/mavsign-logo-white.svg';
import vidSrc from 'assets/images/mobile-menu/id-verif.png';
import contactSrc from 'assets/images/contact-modal/contact-topbar.svg';
import eSrc from 'assets/images/mobile-menu/e-sign.png';
import finishSrc from 'assets/images/mobile-menu/finish.png';
import { getOrder } from 'helpers/backendHelper';
import { route } from 'helpers/routeHelper';
import { useMessageChannel } from 'hooks/messageChannel';
import { useLocation } from 'react-router-dom';
import menuSteps from 'constants/menuSteps';
import { useAuth } from 'context/auth';
import OrderSigner from 'model/orderSigner';

const MobileMenu = ({ activeStep }) => {

  const { pathname } = useLocation();
  const { user: authUser } = useAuth();

  /********** STATE **********/

  // extract the channel from url params
  // this is useful when the user receives an SMS with the URL for a specific message channel, for which we need to open the chat modal
  const routeChannel = useMessageChannel();
  const [channel, setChannel] = useState(routeChannel);

  // in case of redirect to chat
  // wait until we are redirected from `/messages` to `/` to open the chat
  const shouldOpenChat = !!channel && pathname !== route("messages");

  const [order, setOrder] = useState(null);

  const [chatModal, showChatModal] = useState(shouldOpenChat);
  const [menu, setMenu] = useState([]);
  const [contactModal, showContactModal] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      let localMenu = [];
      // if there is no order, the menu doesn't have any items
      if (!res.order) {
        return;
      }
      setOrder(res.order);
      // after the data is loaded, we build the steps menu according to the services added to the order (VID, ink-sign, e-sign)
      // localMenu is a list of objects with the following properties:
      // - label -> name of the step
      // - imgSrc -> representative icon of the step
      // - step -> an unique step number that helps us display the user progress
      // (all steps lower than or equal to the active step are marked as completed in the menu UI)
      if (res.order.isVidRequired) {
        localMenu.push({
          label: "ID verification",
          imgSrc: vidSrc,
          step: menuSteps.VID,
        });
      }
      if (res.order.isInkSignRequired || res.order.isEsignRequired) {
        localMenu.push({
          label: "Signatures",
          imgSrc: eSrc,
          step: menuSteps.SIGNATURES,
        });
      }
      // no matter the type of order, the menu should always contain a "Finish" step
      localMenu.push({
        label: "Finish",
        imgSrc: finishSrc,
        step: menuSteps.FINISH,
      });
      setMenu(localMenu);
    })
  }, []);

  /********** HANDLERS **********/

  const toggleContactModal = () => {
    showContactModal(current => !current);
  };

  const toggleChatModal = () => {
    showChatModal(current => !current);
  };

  const openChatModal = channel => {
    setChannel(channel)
    showContactModal(false);
    showChatModal(true);
  };

  return <React.Fragment>
    <div id="mobileMenu">
      <Col xs={12} md={9} lg={6} xl={5}>
        <div id="topMenuWrapper">
          <div id="header">
            <div className="header-logo">
              <img src={logoSrc} />
            </div>
            {/* hide contact modal for dealer users, as they shouldn't have access to the customer's ways of communication */}
            {authUser.type !== OrderSigner.TYPE_DEALER && <div className="header-btn-wrapper">
              <button className="header-btn" onClick={toggleContactModal}>
                <img src={contactSrc} alt="contact" /> Contact us
              </button>
            </div>}
          </div>
          <div id="menu">
            <hr id="mobile-menu-hr"></hr>
            {menu.map((item, index) => (
              <div className={`menu-item ${activeStep >= item.step ? 'active' : ''}`} key={index}>
                <div className="item-img-wrapper">
                  <img src={item.imgSrc} className="menu-item-image"></img>
                </div>
                <span className="menu-item-label">{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      </Col>
    </div>
    {order && <MobileContactModal order={order} open={contactModal} onToggle={toggleContactModal} onOpenChat={openChatModal} />}
    <MobileChatModal open={chatModal} onToggle={toggleChatModal} channel={channel} />
  </React.Fragment>
}

MobileMenu.propTypes = {
  activeStep: PropTypes.number,
  title: PropTypes.string,
}

export default MobileMenu;