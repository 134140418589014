import React from "react";
import { Row, CardBody, Col, Card } from "reactstrap";
import PropTypes from "prop-types";

const IdOptions = ({ driverLicenseSelected, passportSelected }) => {

  IdOptions.propTypes = {
    driverLicenseSelected: PropTypes.func,
    passportSelected: PropTypes.func,
  };

  return <React.Fragment>
    <div id="id_options" className="account-pages my-3">
      <div className="justify-content-center">
        <Col lg={6} xl={5} className="vid-column">
          <Card>
            <CardBody className="pt-3">
              <div className="p-2 mb-2">
                <h5 className="confirm-title">Scan your <strong>driver license/passport</strong> and take a selfie to <strong>confirm your identity</strong></h5>
              </div>
              <div className="alert alert-danger fade show">
                <i className="fas fa-info-circle me-2"></i>
                Temporary IDs or paper IDs are not valid
              </div>
              <div className="mb-3 mt-4">Make sure:</div>
              <div className="mb-3">
                <i className="fas fa-address-card me-3 fa-lg"></i>
                You have your Driver License/Passport
              </div>
              <div className="mb-3">
                <i className="fas fa-address-card me-3 fa-lg"></i>
                For Passport Cards go to Driver License tab
              </div>
              <div className="mb-3">
                <i className="fas fa-wifi me-3 fa-lg"></i>
                You have good internet connection
              </div>
              <div className="mb-2">
                <i className="fas fa-sun me-3 fa-lg"></i>
                You are in a well-lit space
              </div>
            </CardBody>
          </Card>
          <Row className="bottom-row">
            <Col>
              <button className="btn btn-primary w-100" onClick={driverLicenseSelected}>Use Driver License</button>
            </Col>
            <Col>
              <button className="btn btn-primary w-100" onClick={passportSelected}>Use Passport</button>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  </React.Fragment>

}

export default IdOptions;