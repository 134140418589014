import React from "react";

const IDBackInfo = () => {

  return <div className="instructions-wrapper">
    <div className="mb-2 instructions-label">Instructions:</div>
    <div>
      <i className="fas fa-check me-3 fa-lg instr-i"></i>
      Place your ID on a flat and dark surface
    </div>
    <div>
      <i className="fas fa-check me-3 fa-lg instr-i"></i>
      Ensure all edges of your ID are visible
    </div>
    <div>
      <i className="fas fa-check me-3 fa-lg instr-i"></i>
      Make sure the picture taken is not blurry
    </div>
  </div>
}

export default IDBackInfo;
