import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dealerIcon from "assets/images/contact-modal/contact-dealer.svg";
import schedulerIcon from "assets/images/contact-modal/contact-scheduler.svg";
import notaryIcon from "assets/images/contact-modal/contact-notary.svg";
import customerSupportIcon from "assets/images/contact-modal/contact-customer-support.svg";
import Message from "model/message";

const StepRecipient = ({ order, selectPerson, onOpenChat }) => {

  const contactDealer = event => {
    // prevent Link component from redirecting
    event.preventDefault();

    selectPerson({ dealerFullName: order.userFullName, phone: order.userPhone, role: "dealer" });
  };

  const contactScheduler = event => {
    // prevent Link component from redirecting
    event.preventDefault();

    selectPerson({ schedulerFullName: order.schedulerFullName, phone: order.schedulerPhone, role: "scheduler" });
  };

  const contactNotary = event => {
    // prevent Link component from redirecting
    event.preventDefault();

    selectPerson({ notaryFullName: order.orderNotary?.notaryFullName, phone: order.orderNotary?.notaryPhone, role: "notary" });
  };

  // chat is the only way of communication with customer support
  // therefore we are redirecting the customer to the message channel
  const contactCustomerSupport = event => {
    // prevent Link component from redirecting
    event.preventDefault();

    onOpenChat(Message.CHANNEL_SUPPORT_DEALER_CUSTOMER);
  };

  return (
    <React.Fragment>
      <div className="contact-modal-header">
        <h4 className="contact-modal-title">Select recipient</h4>
        <p className="contact-modal-subtitle">Choose the recipient from below</p>
      </div>
      <ul className="list-unstyled chat-list" id="recent-list">
        <li>
          <Link to="" onClick={contactDealer}>
            <div className="d-flex">
              <div className="align-self-center me-3">
                <img src={dealerIcon} alt="chat" />
              </div>

              <div className="flex-grow-1 overflow-hidden my-auto">
                <h5 className="contact-item-title text-truncate">
                  {order.userFullName}
                </h5>
                <p className="contact-item-subtitle text-truncate">
                  Dealer
                </p>
              </div>
            </div>
          </Link>
        </li>
        {order.schedulerFullName && (
          <li>
            <Link to="" onClick={contactScheduler}>
              <div className="d-flex">
                <div className="align-self-center me-3">
                  <img src={schedulerIcon} alt="chat" />
                </div>

                <div className="flex-grow-1 overflow-hidden my-auto">
                  <h5 className="contact-item-title text-truncate">
                    {order.schedulerFullName}
                  </h5>
                  <p className="contact-item-subtitle text-truncate">
                    Scheduler
                  </p>
                </div>
              </div>
            </Link>
          </li>
        )}
        {order.orderNotary?.notaryFullName && (
          <li>
            <Link to="" onClick={contactNotary}>
              <div className="d-flex">
                <div className="align-self-center me-3">
                  <img src={notaryIcon} alt="chat" />
                </div>

                <div className="flex-grow-1 overflow-hidden my-auto">
                  <h5 className="contact-item-title text-truncate">
                    {order.orderNotary?.notaryFullName}
                  </h5>
                  <p className="contact-item-subtitle text-truncate">
                    Notary
                  </p>
                </div>
              </div>
            </Link>
          </li>
        )}
        <li>
          <Link to="" onClick={contactCustomerSupport}>
            <div className="d-flex">
              <div className="align-self-center me-3">
                <img src={customerSupportIcon} alt="chat" />
              </div>

              <div className="flex-grow-1 overflow-hidden my-auto">
                <h5 className="contact-item-title text-truncate">
                  Mavsign
                </h5>
                <p className="contact-item-subtitle text-truncate">
                  Customer Support
                </p>
              </div>
            </div>
          </Link>
        </li>
      </ul>
    </React.Fragment>
  )
}

StepRecipient.propTypes = {
  order: PropTypes.object.isRequired,
  selectPerson: PropTypes.func.isRequired,
  onOpenChat: PropTypes.func.isRequired,
}

export default StepRecipient;