import React from "react";
import iconSrc from "assets/images/appointment/accepted.png";
import { Alert, Row } from "reactstrap";
import Col from "components/Shared/Col";
import locationIcon from '../../../assets/images/appointment/location-icon.svg';
import timeIcon from '../../../assets/images/appointment/time-icon.svg';
import {useAppointment} from "../../../context/appointment";
import {formats, formatTimestampTz} from "helpers/dateHelper";
import NotaryInfo from "../../../components/Shared/NotaryInfo";

const AppointmentAccepted = () => {

  const { order } = useAppointment();

  return <div className="appointment-result pb-4">
    <div className="text-center">
      <img className="mt-3" src={iconSrc}></img>
      <h5 className="mt-4">Congratulations!</h5>
      <div className="mt-2">Your appointment is set!</div>
      <NotaryInfo notaryInfo={order.orderNotary} />
    </div>
    <div className='d-flex align-items-stretch justify-content-center mt-3 mb-4'>
      <div className='box d-flex align-items-start'>
        <img src={timeIcon} alt='time-icon' />
        <div className='ms-2'>
          <div className='font-weight-semibold'>Appointment Time</div>
          <div className='appointment-date'>{formatTimestampTz(order.orderNotary.meetingTs, formats.APPOINTMENT_DATETIME, order.orderNotary.notaryTimezone)}</div>
        </div>
      </div>
      <div className='box ms-2 d-flex align-items-start ms-2'>
        <img src={locationIcon} alt='location-icon' />
        <div className='ms-2'>
          <div className='font-weight-semibold'>Appointment Location</div>
          <div>{order.orderNotary.meetingAddress}</div>
        </div>
      </div>
    </div>
    <div className="pt-5">
      <h6>Make sure:</h6>
      <Row className="justify-content-center mb-3">
        <Col className="col-1">
          <i className="mdi mdi-card-account-details-outline font-size-22"></i>
        </Col>
        <Col className="col-11">
          <h6>Please have a 2nd ID available should the dealership request one to be collected.</h6>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col className="col-1">
          <i className="mdi mdi-pencil-outline font-size-22"></i>
        </Col>
        <Col className="col-11">
          <h6>You will provide your signature and a clear thumbprint on a document called an Affidavit of Identity.</h6>
          <Alert color="primary" className="mb-2">
            <h6>If this can not be collected or this is refused at the time of the signing a STOP order may be placed on the purchase and potential delays and/or a cancellation may ensue.</h6>
          </Alert>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col className="col-1">
          <i className="mdi mdi-file-document-multiple-outline font-size-22"></i>
        </Col>
        <Col className="col-11">
          <h6>There are usually about 20 or so pages you will need to sign. The notary will instruct you where to sign and/or initial.</h6>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-1">
          <i className="bx bx-time-five align-middle font-size-22" />
        </Col>
        <Col className="col-11">
          <h6>The signing, on average, takes about 30-40 mins; per vehicle, per signer.</h6>
        </Col>
      </Row>
    </div>
  </div>
}

export default AppointmentAccepted;
