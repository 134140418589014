import { isObject } from './utilHelper';

const routes = {
  home: '/',
  vid: '/vid',
  appointment: '/appointment',
  ink_signature: '/ink-sign',
  e_signature: '/e-sign',
  signatures: '/signatures',
  finance: '/finance',
  finish: '/finish',
  welcome: '/welcome',
  messages: '/messages',
};

export const route = (name, params, query) => {
  let route = routes[name];
  if (!route) {
    return;
  }
  if (params) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (!param) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (!params[key]) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (!params) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};