import React from "react";
import logoSrc from "assets/images/mavsign-logo-white.svg";

const DesktopHeader = () => {

  return <React.Fragment>
    <div id="vid_desktop_header">
      <img id="header_logo" src={logoSrc} />
      <div id="opacity_overlay"></div>
    </div>
  </React.Fragment>
}

export default DesktopHeader;