import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import AuthContext from "context/auth";
import SocketContext from "context/socket";
import NavigatorContext from "context/navigator";

const app = (
  <Provider store={store}>
    <AuthContext>
      <SocketContext>
        <NavigatorContext>
          <App />
        </NavigatorContext>
      </SocketContext>
    </AuthContext>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);
