import React, { useState } from "react";
import DocViewer from 'components/Shared/DocViewer';
import PropTypes from "prop-types";
import { useAuth } from "context/auth";
import EsignConfirmation from "../Partial/EsignConfirmation";

const Sign = ({ id, goBack }) => {

  const { user: authUser } = useAuth();

  // by default, hide the signature fields so the user can read the doc
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  return (
    <React.Fragment>
      <div className="pdfwrapper e-sign">
        <DocViewer
          docId={id}
          currentSignerId={authUser.id}
          readOnly={isReadOnly}
          onSigningStarted={() => setIsConfirmationVisible(true)}
          onViewerClosed={() => console.log('doc viewer cancelled')}
          onSigningComplete={() => goBack()}
          onDocLoaded={doc => doc.signingId ? setIsReadOnly(false) : setIsReadOnly(true)} />
      </div>
      {isConfirmationVisible && <EsignConfirmation
        onConfirm={() => {
          setIsConfirmationVisible(false);
          setIsReadOnly(false);
        }}
        onCancel={() => { setIsConfirmationVisible(false); goBack() }}>
        <strong>Your E-Signatures is needed. You consent to signing this document electronically on a mobile device.</strong> I agree that the signature and the initials will be the electronic
        representation of my signature and initials for all purposes when I (or my agent) use them on my document, including legally binding contracts - just the same
        as pen-and-paper signature or initial.
      </EsignConfirmation>}
    </React.Fragment>
  )
}

Sign.propTypes = {
  id: PropTypes.number,
  goBack: PropTypes.func
};

export default Sign;
