import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { doOrderDocSigningCleanup, doOrderDocSingleCleanup, getDocViewerOrderDoc, signOrderDoc } from 'store/actions';
import { showBriefSuccess, showError } from 'helpers/utilHelper';
import OrderDoc from 'model/orderDoc';
import { ReactComponent as SignatureIcon } from 'assets/images/docviewer/signature.svg';
import { ReactComponent as InitialsIcon } from 'assets/images/docviewer/initials.svg';
import { ReactComponent as NameIcon } from 'assets/images/docviewer/name.svg';
import useBeforeUnload from 'hooks/beforeUnload';

const DocViewerContext = React.createContext();

/**
 * Maximum value (percentage) allowed for zooming in
 * @type {int}
 */
const maxZoomLevel = 200;

/**
 * Zooming in/out will increase/decrease the zoom level with this number of units (percentage)
 * @type {int}
 */
const zoomStep = 10;

/**
 * The width (in px) of the page list that corresponds to a 100% zoom level
 * This needs to be chosen based on the resolution of the images (pages)
 * and needs to be correlated with the sizes of the signature fields
 * @type {int}
 */
const naturalPageListWidth = 1200;

const DocViewerProvider = props => {

  /**
   * Component props:
   * docId {int} the db id of the viewed orderDoc
   * currentSignerId {int} the db id of the authenticated user (orderSigner)
   * readOnly {boolean} whether to hide the signature fields so the user can read the doc
   * onSigningStarted {func} callback to call when the user switches from read-only to sign mode
   * onViewerClosed {func} callback to call when the user closes the viewer
   * onSigningComplete {func} callback to call when the user signs the doc
   * onDocLoaded {func} callback to call after the orderDoc has been loaded from api
   */
  const { docId, currentSignerId, readOnly, onSigningStarted, onViewerClosed, onSigningComplete, onDocLoaded } = props;

  /**
   * Redux action dispatcher
   */
  const dispatch = useDispatch();

  /********** STATE **********/

  /**
   * Store state vars:
   * orderDoc {object} the order doc fetched from the backend
   * orderDocError {object} error encountered while trying to fetch the order doc
   * isLoadBusy {boolean} TRUE while fetch request is in progress
   */
  const { orderDoc, orderDocError, isLoadBusy } = useSelector(state => state.OrderDoc.Single);

  /**
   * Store state vars:
   * signed {boolean} TRUE if the doc has been signed successfully
   * isSignBusy {boolean} TRUE while the save request is in progress
   */
  const { signed, isBusy: isSignBusy } = useSelector(state => state.OrderDoc.Signing);

  /**
   * List of fields that have been added to the document
   * Each field is an object with multiple properties such as size, position, text, etc
   */
  const [addedFields, setAddedFields] = useState([]);

  /**
   * List of signatures that the user has added to the doc
   * This object holds one signature per field type
   * We use this to capture signatures once and apply them multiple times with a click
   */
  const [capturedSignatures, setCapturedSignatures] = useState({});

  /**
   * Bool flag that turns the left bar on and off
   * The left bar will be rendered or not based on this value
   * The setter of this state var is not exposed (see: numSizeChanges)
   */
  const [isLeftBarOpen, _setIsLeftBarOpen] = useState(true);

  /**
   * Bool flag that turns full screen mode on and off
   * Full screen will be activated or deactivated based on this value
   */
  const [isFullScreen, setIsFullScreen] = useState(false);

  /**
   * the number of the page that is to be shown to the user
   * when this changes, content will jump to the top of the respective page
   */
  const [activePageNum, _setActivePageNum] = useState(1);

  /**
   * Counter that increments each time there is a change of page that requires scrolling into view
   * We listen to this counter and know when to scroll
   */
  const [numPageScrollCommands, _setNumPageScrollCommands] = useState(0);

  /**
   * The current zoom level (percentage)
   * Content will be scaled based on this value
   * Initial value is 0 (not initialized) before the fit-width and fit-height zoom levels are determined
   * Then the zoom level will be automatically set to fit-width
   * Nested components know to skip certain processing while this value is 0
   */
  const [zoomLevel, setZoomLevel] = useState(0);

  /**
   * The zoom level at which page width fits perfectly inside the content
   * This value is determined on first render and then whenever there are size changes (see: numSizeChanges)
   * It is NOT however recalculated on window resize (for performance reasons)
   */
  const [fitWidthZoomLevel, setFitWidthZoomLevel] = useState(0);

  /**
   * The zoom level at which page height fits perfectly inside the content
   * This value is determined on first render and then whenever there are size changes (see: numSizeChanges)
   * It is NOT however recalculated on window resize (for performance reasons)
   */
  const [fitHeightZoomLevel, setFitHeightZoomLevel] = useState(0);

  /**
   * Counter that increments each time there is a layout change (other than zoom) that affects page size
   * Example of such changes: toggle left bar, toggle full screen
   * We want to be able to track changes in page size because fields sizes need to be recalculated accordingly
   * However listening for each change individually is tedious
   * So we listen to this counter and instruct all other size-changing state vars to update it
   */
  const [numSizeChanges, _setNumSizeChanges] = useState(0);

  /**
   * Bool flag that indicates whether the user has made changes since the last save
   * Warning message will be shown on close based on this value
   */
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  /**
   * Bool flag that indicates whether the "save before close" dialog is open
   * Save dialog will be shown on close based on this value
   */
  const [isCloseConfVisible, setIsCloseConfVisible] = useState(false);

  /**
   * Id of the next field to be signed
   * We use this to scroll to the next field as the user signs
   */
  const [activeFieldId, _setActiveFieldId] = useState(null);

  /**
   * Counter that increments each time there is a change of active field that requires scrolling into view
   * We listen to this counter and know when to scroll
   */
  const [numFieldScrollCommands, _setNumFieldScrollCommands] = useState(0);

  /**
   * Custom setter for the 'isLeftBarOpen' state var
   * Calls the 'native' setter but also increments 'numSizeChanges'
   * @param {bool|func} arg
   */
  const setIsLeftBarOpen = arg => {
    _setIsLeftBarOpen(arg);
    reportSizeChange();
  }

  /**
   * Custom setter for the 'activePageNum' state var
   * Calls the 'native' setter but also sets 'numPageScrollCommands'
   * @param {bool|func} arg
   * @param {bool} [shouldScroll] - whether the content should scroll to the newly activated page
   */
  const setActivePageNum = (arg, shouldScroll = true) => {
    _setActivePageNum(arg);
    if (shouldScroll) {
      _setNumPageScrollCommands(num => num + 1);
    }
  }

  /**
   * Custom setter for the 'activeFieldId' state var
   * Calls the 'native' setter but also sets 'numFieldScrollCommands'
   * @param {bool|func} arg
   */
  const setActiveFieldId = arg => {
    _setActiveFieldId(arg);
    _setNumFieldScrollCommands(num => num + 1);
  }

  /**
   * Custom setter for the 'numSizeChanges' state var
   * With each call, it increments the value by 1
   * @returns {void}
   */
  const reportSizeChange = () => _setNumSizeChanges(num => num + 1);

  /********** EFFECTS **********/

  /**
   * This effect runs whenever the doc id changes
   * Which only happens on the first render
   * We use it to fetch the order doc by id
   */
  useEffect(() => {
    // make the initial remote call to fetch the doc data
    refreshOrderDoc();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocSingleCleanup());
      dispatch(doOrderDocSigningCleanup());
    }
  }, [docId]);

  /**
   * This effect runs whenever the orderDoc changes
   * Which happens whenever the data is refreshed
   * For example after each save
   * We use it to populate the added fields
   */
  useEffect(() => {
    // orderDoc is null before the fetch call completes
    if (!orderDoc) {
      return;
    }
    if (!!onDocLoaded) {
      onDocLoaded(orderDoc);
    }
    // if the doc has no added fields then there is nothing we need to do
    if (!orderDoc.fields) {
      return;
    }
    // fields coming from backend only contain signerId
    // we need the full signer object attached to the field
    for (const index in orderDoc.fields) {
      const field = orderDoc.fields[index];
      const signer = orderDoc.signers.find(s => s.id == field.signerId);
      field.signer = signer;
      orderDoc.fields[index] = field;
    }
    // check if we already captured signatures for this signer
    if (!!orderDoc.capturedSignatures) {
      setCapturedSignatures(orderDoc.capturedSignatures);
    }
    // populate the added fields from the doc fields
    // so that the fields will be rendered visually
    setAddedFields(orderDoc.fields);
  }, [orderDoc]);

  /**
   * This effect runs whenever the 'signed' flag changes
   * Which happens after the signing action
   * We use it to display a notification and to refresh the orderDoc
   */
  useEffect(() => {
    if (signed === true) {
      showBriefSuccess('Document has been signed');
      // changes have been saved so reset the flag
      setHasUnsavedChanges(false);
      // used to call refreshOrderDoc() here to update the viewer with the signed doc
      // we removed it since signing a document now closes the viewer
      onSigningComplete();
    } else if (signed === false) {
      showError('Unable to sign document');
    }
  }, [signed]);

  /**
   * This effect runs whenever the 'fit-width' zoom level is recalculated
   * Which happens after each layout change that affects page size
   * We use it to set the initial zoom level to fit-width
   */
  useEffect(() => {
    // fitWidthZoomLevel is 0 on first render
    // check if it has a value and if the zoom level has not yet been set
    // we want this to run only once, in the beginning
    if (!!fitWidthZoomLevel && !zoomLevel) {
      // set the content zoom level to fit-width
      // but not exceed 100%
      setZoomLevel(Math.min(fitWidthZoomLevel, 100));
    }
  }, [fitWidthZoomLevel]);

  // show warning if there are unsaved changes and the user tries to leave the page
  useBeforeUnload(hasUnsavedChanges);

  /**
   * This effect runs whenever the 'readOnly' prop changes
   * Which happens when the user has reviewed the document and proceeds to signing it
   * We use it to scroll the first field into view
   */
  useEffect(() => {
    if (!readOnly) {
      // find the first unsigned field of the current signer
      const firstField = addedFields.find(f => f.signerId == currentSignerId && !f.signature);
      if (!!firstField) {
        setActiveFieldId(firstField.id);
      }
    }
  }, [readOnly]);

  /********** HANDLERS **********/

  /**
   * Updates a field (in the list of added fiels) with new data
   * This is called whenever field properties change (ex. signature)
   * @param {object} field
   */
  const updateField = async field => {
    setAddedFields(fields => {
      // find the field in the list of added fields by id
      const theField = fields.find(f => f.id == field.id);
      // get the field index
      const index = fields.indexOf(theField);
      // add new data to the field
      fields[index].signature = field.signature;
      // return the new list
      // it is important to return a copy or the list and not the original
      // else react might fail to detect that the value has changed
      return [...fields];
    });
    // store this signature for this field type
    // so it can be appliend later to other fields
    const fieldType = field.type;
    setCapturedSignatures(signatures => ({
      ...signatures,
      [fieldType]: field.signature,
    }));
    // note that changes have been made which the user might want to save
    setHasUnsavedChanges(true);
    // find the next field to be signed (only search fields intended for the current signer)
    // since the setState() calls are asynchronous, this code will most likely run before setAddedFields() completes
    // which means that current field might not have been updated with the signature
    // so there is a chance we could pick the current field as the next field to sign
    // to avoid that we add a condition that the id of the next field is different
    const nextField = addedFields.find(f => f.signerId == currentSignerId && !f.signature && f.id != field.id);
    if (!!nextField) {
      // scroll the next field into view
      // but do that with a delay so the user gets the chance to see the signature being applied
      setTimeout(() => setActiveFieldId(nextField.id), 300);
    }
  }

  /**
   * Performs a backend request to sign the doc
   * @returns {void}
   */
  const signDoc = () => {
    const saveFields = [];
    // validate that all fields intended for the current signer have been signed
    for (const field of addedFields) {
      // check if the field is intended for the current signer
      if (field.signerId != currentSignerId) {
        continue;
      }
      if (!field.signature) {
        showError('Please sign all fields');
        // mark the field as the next field to sign
        // so it is scrolled into view
        setActiveFieldId(field.id);
        return;
      }
      // send only the signature fields
      // no other changes are allowed at this stage
      saveFields.push({
        id: field.id,
        signature: field.signature,
      });
    }
    dispatch(signOrderDoc({ fields: saveFields, signatures: capturedSignatures }, orderDoc.id));
  }

  /**
   * Checks whether there are unsaved changes and alerts the user accordingly
   * Closes the document viewer
   */
  const tryCloseViewer = () => {
    if (hasUnsavedChanges) {
      setIsCloseConfVisible(true);
    } else {
      closeViewer();
    }
  }

  /**
   * Handler that is supposed to close the doc viewer
   */
  const closeViewer = onViewerClosed;

  /**
   * Handler that is supposed to switch the doc viewer form readonly to signing mode
   */
  const enableSigning = () => {
    // scroll the doc viewer to the top
    setActivePageNum(1);
    onSigningStarted();
  }

  /**
   * Performs a backend request to fetch the orderDoc
   * @returns {void}
   */
  const refreshOrderDoc = () => dispatch(getDocViewerOrderDoc(docId));

  /**
   * Returns the respective icon for a field type
   * @param {int} fieldType
   * @returns {ReactComponent}
   */
  const getFieldIcon = fieldType => {
    switch (fieldType) {
      case OrderDoc.FIELD_TYPE_SIGNATURE:
        return SignatureIcon;
      case OrderDoc.FIELD_TYPE_INITIALS:
        return InitialsIcon;
      case OrderDoc.FIELD_TYPE_NAME:
        return NameIcon;
      default:
        return <React.Fragment />
    }
  }

  /**
   * Checks if the current conditions allow the doc to be signed
   * Returns TRUE if the document is not already signed by the current signer
   */
  const canSign = () => !!orderDoc && !orderDoc.signings.some(s => s.orderSignerId == currentSignerId);

  /**
   * Returns the current scale factor based on the current zoom level
   * @returns {float}
   */
  const getZoomScaleFactor = () => zoomLevel / 100;

  /**
   * Minimum value (percentage) allowed for zooming out
   * @type {int}
   */
  const minZoomLevel = fitHeightZoomLevel;

  /**
   * Calculates the natural size of a scaled size based on the current zoom level
   * Natural size - size at 100% zoom
   * a.k.a. what would this size be if it weren't scaled
   * @param {number} size
   * @returns {float}
   */
  const getNaturalSize = size => size / getZoomScaleFactor();

  /**
   * Calculates the scaled size of a natural size based on the current zoom level
   * Natural size - size at 100% zoom
   * a.k.a. what would this size be if it were scaled by the current zoom level
   * @param {number} size
   * @returns {float}
   */
  const getZoomedSize = size => size * getZoomScaleFactor();

  /**
   * Calculates the width of the content based on the current zoom level
   * @returns {float}
   */
  const getZoomedPageListWidth = () => getZoomScaleFactor() * naturalPageListWidth;

  /**
   * Sets the zoom level to the value that would produce a fit-width effect
   * @returns {void}
   */
  const zoomToFitWidth = () => setZoomLevel(fitWidthZoomLevel);

  /**
   * Sets the zoom level to the value that would produce a fit-height effect
   * @returns {void}
   */
  const zoomToFitHeight = () => setZoomLevel(fitHeightZoomLevel);

  /**
   * Checks whether the current zoom conditions allow for a fit-width operation
   * Essentially checks if the zoom level is not fit-width already
   * @returns {void}
   */
  const canZoomToFitWidth = () => zoomLevel !== fitWidthZoomLevel;

  /**
   * Checks whether the current zoom conditions allow for a fit-height operation
   * Essentially checks if the zoom level is not fit-height already
   * @returns {void}
   */
  const canZoomToFitHeight = () => zoomLevel !== fitHeightZoomLevel;

  /**
   * Checks if a field is intended for the current signer
   * @param {object} field
   * @returns {boolean}
   */
  const fieldIsMine = field => field.signerId == currentSignerId;

  /**
   * Checks if a field is permanently signed
   * a.k.a. the signature is recorded in db and applied to the pdf doc
   * @param {object} field
   * @returns {boolean}
   */
  const fieldIsSigned = field => !!field.signingId;

  return <DocViewerContext.Provider value={{
    orderDoc, orderDocError, isLoadBusy, isSignBusy, canSign, currentSignerId, readOnly,
    addedFields, updateField, capturedSignatures, signDoc,
    activeFieldId, numFieldScrollCommands,
    activePageNum, setActivePageNum, numPageScrollCommands,
    isLeftBarOpen, setIsLeftBarOpen,
    isFullScreen, setIsFullScreen,
    numSizeChanges, reportSizeChange,
    zoomLevel, setZoomLevel, maxZoomLevel, minZoomLevel, zoomStep,
    getZoomScaleFactor, getNaturalSize, getZoomedSize, getZoomedPageListWidth, naturalPageListWidth,
    setFitWidthZoomLevel, setFitHeightZoomLevel, zoomToFitWidth, zoomToFitHeight, canZoomToFitWidth, canZoomToFitHeight,
    getFieldIcon, fieldIsMine, fieldIsSigned, tryCloseViewer, closeViewer, enableSigning,
    isCloseConfVisible, setIsCloseConfVisible,
  }} {...props} />
}

DocViewerProvider.propTypes = {
  docId: PropTypes.number.isRequired,
  currentSignerId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  onSigningStarted: PropTypes.func,
  onViewerClosed: PropTypes.func,
  onSigningComplete: PropTypes.func,
  onDocLoaded: PropTypes.func,
}

// helper hook that makes context data available
export const useDocViewer = () => React.useContext(DocViewerContext);

export default DocViewerProvider;