import React from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import iconSrc from 'assets/images/desktop/vid-error-icon.png';

const VidLimitReached = () => {

  return <React.Fragment>
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="vid-column mt-4">
          <Card>
            <CardBody>
              <div className="pt-2 text-center">
                <img className="mt-3" src={iconSrc}></img>
                <h5 className="mt-3 mb-4">Verifications limit reached!</h5>
                <div className="mt-4">Identity verification failed.</div>
                <div className="mb-4">You have reached your identity verification tries limit.</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}

export default VidLimitReached;
