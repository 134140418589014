export default class OrderNotary {

  static STATUS_ASSIGNED = 0;
  static STATUS_DOCS_RECEIVED = 1;
  static STATUS_APPOINTMENT_SET = 2;
  static STATUS_APPOINTMENT_DECLINED = 3;
  static STATUS_APPOINTMENT_ACCEPTED = 4;
  static STATUS_DOCS_PRINTED = 5;
  static STATUS_DOCS_UPLOADED = 6;
  static STATUS_ORDER_COMPLETE = 7;
}