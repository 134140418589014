export const saveAccessToken = token => window.localStorage.setItem('authToken', token);

export const getAccessToken = () => window.localStorage.getItem('authToken');

export const addAccessToken = url => {
  if (url.includes('?')) {
    url += '&';
  } else {
    url += '?';
  }
  url += `access_token=${getAccessToken()}.${getVerificationToken()}`;
  return url;
}

export const saveVerificationToken = token => window.localStorage.setItem('verificationToken', token);

export const getVerificationToken = () => window.localStorage.getItem('verificationToken');

export const removeVerificationToken = () => window.localStorage.removeItem('verificationToken');

export const isUserVerifiedLocal = () => !!getVerificationToken();

export const saveVerifCodeSent = () => window.sessionStorage.setItem('verifCodeSent', true);

export const getVerifCodeSent = () => window.sessionStorage.getItem('verifCodeSent');

export const removeVerifCodeSent = () => window.sessionStorage.removeItem('verifCodeSent');

export const isVerifCodeSent = () => !!getVerifCodeSent();

export const resetVerification = () => {
  removeVerificationToken();
  removeVerifCodeSent();
}