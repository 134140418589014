import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopContent from "./DesktopContent";
import MobileContent from "./MobileContent";

const Index = () => {

  return <React.Fragment>
    <MetaTitle>Document Signature</MetaTitle>
    <BrowserView>
      <DesktopContent />
    </BrowserView>
    <MobileView>
      <MobileContent />
    </MobileView>
  </React.Fragment>
}

export default Index;
