import React from "react";
import PropTypes from "prop-types";
import Slider from "./Slider";

/* ID Front Info & Passport Info - images */
import frontSlide0 from 'assets/images/carousel/front/gif-1.gif';
import frontSlide1 from "assets/images/carousel/front/id-front-slide-1.png";
import frontSlide2 from "assets/images/carousel/front/id-front-slide-2.png";
import frontSlide3 from "assets/images/carousel/front/id-front-slide-3.png";
import frontSlide4 from "assets/images/carousel/front/id-front-slide-4.png";

/* ID Back Info - images */
import backSlide0 from 'assets/images/carousel/back/gif-3.gif';
import backSlide1 from "assets/images/carousel/back/id-back-slide-1.png";
import backSlide2 from "assets/images/carousel/back/id-back-slide-2.png";
import backSlide3 from "assets/images/carousel/back/id-back-slide-3.png";
import backSlide4 from "assets/images/carousel/back/id-back-slide-4.png";

/* Selfie Info - images */
import selfieSlide0 from 'assets/images/carousel/selfie/selfie-slide-1.png';
import selfieSlide1 from "assets/images/carousel/selfie/selfie-slide-2.png";
import selfieSlide2 from "assets/images/carousel/selfie/selfie-slide-3.png";
import selfieSlide3 from "assets/images/carousel/selfie/selfie-slide-4.png";
import selfieSlide4 from "assets/images/carousel/selfie/selfie-slide-5.png";

// Constants for descriptions
const defaultDescriptions = [
  'Make sure the picture of the ID is taken in landscape way',
  'Do not cover any corners of the ID document',
  'Avoid any glare, shadows or dark spots on the ID document',
  'Do not use a textured background as a marble top or a print pattern',
  'Make sure the picture of the ID is clear and not blurry',
];

const selfieDescriptions = [
  'Hold your phone in a portrait position',
  'Remove your mask while taking the selfie',
  'Avoid a busy or lively background',
  'Do not use sun glasses or glasses',
  'Make sure you have enough light around',
];

// Info arrays
const IDFrontInfo = [
  { id: 0, imgUrl: frontSlide0, description: defaultDescriptions[0] },
  { id: 1, imgUrl: frontSlide1, description: defaultDescriptions[1] },
  { id: 2, imgUrl: frontSlide2, description: defaultDescriptions[2] },
  { id: 3, imgUrl: frontSlide3, description: defaultDescriptions[3] },
  { id: 4, imgUrl: frontSlide4, description: defaultDescriptions[4] },
];

const IDBackInfoItems = [
  { id: 0, imgUrl: backSlide0, description: defaultDescriptions[0] },
  { id: 1, imgUrl: backSlide1, description: defaultDescriptions[1] },
  { id: 2, imgUrl: backSlide2, description: defaultDescriptions[2] },
  { id: 3, imgUrl: backSlide3, description: defaultDescriptions[3] },
  { id: 4, imgUrl: backSlide4, description: defaultDescriptions[4] },
];

const PassportInfo = [
  { id: 0, imgUrl: frontSlide0, description: defaultDescriptions[0] },
  { id: 1, imgUrl: frontSlide1, description: defaultDescriptions[1] },
  { id: 2, imgUrl: frontSlide2, description: defaultDescriptions[2] },
  { id: 3, imgUrl: frontSlide3, description: defaultDescriptions[3] },
  { id: 4, imgUrl: frontSlide4, description: defaultDescriptions[4] },
];

const SelfieInfo = [
  { id: 0, imgUrl: selfieSlide0, description: selfieDescriptions[0] },
  { id: 1, imgUrl: selfieSlide1, description: selfieDescriptions[1] },
  { id: 2, imgUrl: selfieSlide2, description: selfieDescriptions[2] },
  { id: 3, imgUrl: selfieSlide3, description: selfieDescriptions[3] },
  { id: 4, imgUrl: selfieSlide4, description: selfieDescriptions[4] },
];

const Tips = ({ isFrontInfo, isBackInfo, isSelfieInfo, isPassportInfo }) => {
  const getInfoItems = () => {
    if (isFrontInfo) return IDFrontInfo;
    if (isBackInfo) return IDBackInfoItems;
    if (isSelfieInfo) return SelfieInfo;
    if (isPassportInfo) return PassportInfo;
  };

  return (
    <div className="tips-wrapper">
      <h4 className="tips-title ms-2">Do not</h4>
      <Slider items={getInfoItems()} />
    </div>
  );
};

Tips.propTypes = {
  isFrontInfo: PropTypes.bool,
  isBackInfo: PropTypes.bool,
  isSelfieInfo: PropTypes.bool,
  isPassportInfo: PropTypes.bool,
};

export default Tips;
