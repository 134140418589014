import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import phoneIcon from "assets/images/contact-modal/contact-phone-icon.svg";
import chatIcon from "assets/images/contact-modal/contact-chat-icon.svg";
import videoIcon from "assets/images/contact-modal/contact-video-icon.svg";
import Message from "model/message";
import { createOrderMeeting } from "helpers/backendHelper";
import { openInNewWindow, showError } from "helpers/utilHelper";

const StepOptions = ({ selectedPerson, onOpenChat, personRoleLabel }) => {

  const openChat = event => {
    // prevent the Link component from redirecting
    event.preventDefault();

    let channel;

    switch (selectedPerson.role) {
      case 'dealer':
        channel = Message.CHANNEL_DEALER_CUSTOMER;
        break;
      case 'scheduler':
        channel = Message.CHANNEL_SCHEDULER_CUSTOMER;
        break;
      case 'notary':
        channel = Message.CHANNEL_NOTARY_CUSTOMER;
        break;
    }

    onOpenChat(channel);
  };

  const startVideoCall = event => {
    event.preventDefault();

    createMeeting(selectedPerson.role)
  };

  const createMeeting = async role => {
    try {
      const { link } = await createOrderMeeting(role);
      if (!link) {
        throw new Error();
      } else {
        openInNewWindow(link);
      }
    } catch (err) {
      showError("Unable to start meeting");
    }
  };

  return (
    <React.Fragment>
      <div className="contact-modal-header">
        <h4 className="contact-modal-title">Contact options</h4>
        <p className="contact-modal-subtitle">Choose one of the contact options</p>
      </div>
      <ul className="list-unstyled chat-list" id="recent-list">
        <li>
          <a href={`tel:${selectedPerson?.phone}`}>
            <div className="d-flex">
              <div className="align-self-center me-3">
                <img src={phoneIcon} alt="chat" />
              </div>

              <div className="flex-grow-1 overflow-hidden my-auto">
                <h5 className="contact-item-title text-truncate">
                  Phone call
                </h5>
                <p className="contact-item-subtitle text-truncate">
                  {selectedPerson.phone || "--"}
                </p>
              </div>
            </div>
          </a>
        </li>
        <li>
          <Link to={""} onClick={openChat}>
            <div className="d-flex">
              <div className="align-self-center me-3">
                <img src={chatIcon} alt="chat" />
              </div>

              <div className="flex-grow-1 overflow-hidden my-auto">
                <h5 className="contact-item-title text-truncate">
                  Chat
                </h5>
                <p className="contact-item-subtitle text-truncate">
                  {`${personRoleLabel} ${selectedPerson.role}`}
                </p>
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link to="" onClick={startVideoCall}>
            <div className="d-flex">
              <div className="align-self-center me-3">
                <img src={videoIcon} alt="chat" />
              </div>

              <div className="flex-grow-1 overflow-hidden my-auto">
                <h5 className="contact-item-title text-truncate">
                  Video call
                </h5>
              </div>
            </div>
          </Link>
        </li>
      </ul>
    </React.Fragment>
  )
}

StepOptions.propTypes = {
  selectedPerson: PropTypes.object.isRequired,
  onOpenChat: PropTypes.func,
  personRoleLabel: PropTypes.string,
}

export default StepOptions;