import React from "react";
import { Col, Button } from "reactstrap";
import iconSrc from 'assets/images/desktop/vid-error-icon.png';
import progressSrc from 'assets/images/desktop/progress-bar-complete.png';
import ValidationNav from "../Partial/ValidationNav";
import PropTypes from "prop-types";

const VidFail = (props) => {

  return <React.Fragment>
    <div id="vid_fail_parent" className="vid-progress">
      <ValidationNav />
      <Col sm={7} md={7} xl={7} className="text-center m-auto pt-5">
        <img id="vid_icon" src={iconSrc}></img>
        <h5 id="vid_title" className="mt-5">Try again!</h5>
        <img id="progress_bar" src={progressSrc}></img>
        <div className="vid-desc">Documents reviewed. Please try again</div>
        <div className="vid-desc">Status: <span className="vid-status">{props.diagnose}</span></div>
        <div className="vid-desc">{props.hint}</div>
        <div className="vid-desc">Read instructions before each step</div>
        <Button className="main-btn mt-5" onClick={props.tryAgain}>Try again</Button>
      </Col>
    </div>
  </React.Fragment>
}

VidFail.propTypes = {
  diagnose: PropTypes.string,
  hint: PropTypes.string,
  tryAgain: PropTypes.func,
};

export default VidFail;