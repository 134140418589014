import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Row, CardBody, Card, Container } from "reactstrap";
import DesktopHeader from "./Partial/DesktopHeader";
import Topbar from "./Partial/Topbar";
import socketEvent from "constants/socketEvent";
import { useSocketOn } from "hooks/socket";
import VidPending from "./Steps/VidPending";
import VidComplete from "./Steps/VidComplete";
import QrCode from "./Steps/QrCode";
import VidSuccess from "./Steps/VidSuccess";
import VidFail from "./Steps/VidFail";
import { getVidRequest, retryVid } from "helpers/backendHelper";
import Preloader from "components/Shared/Preloader";
import vidTokenStatus from "constants/vidTokenStatus";
import NotFound from "pages/Error/NotFound";
import { useSocket } from "context/socket";
import Footer from "components/Shared/Footer";
import VidLimitReached from "./Steps/VidLimitReached";
import { useNavigator } from "context/navigator";
import config from 'config';

// steps of the VID process
const STEP_QR = 0;
const STEP_PENDING = 1;
const STEP_COMPLETE = 2;
const STEP_SUCCESS = 3;
const STEP_FAIL = 4;
const STEP_LIMIT_REACHED = 5;

const DesktopContent = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  // hook that emits socket events
  const { socketEmit } = useSocket();

  /********** STATE ********/

  const [currentStep, setCurrentStep] = useState(STEP_QR);
  const [diagnose, setDiagnose] = useState('');
  const [hint, setHint] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [qrSrc, setQrSrc] = useState('');
  const [vidRequest, setVidRequest] = useState(null);
  const [vidRequestError, setVidRequestError] = useState(false);

  /********** EFFECTS ********/

  // runs once on component mount
  useEffect(() => {
    setIsBusy(true);
    getVidRequest().then(res => {
      const vidReq = res.vidRequest;
      if (!vidReq) {
        setVidRequestError(true);
        setIsBusy(false);
      } else {
        setVidRequest(vidReq);
        // subscribe to vid request
        socketEmit(socketEvent.subscribeToVidReq, { id: vidReq.id });
        // check vid request status in order to render the correct screen
        switch (vidReq.status) {
          case vidTokenStatus.PENDING:
          case vidTokenStatus.ESCALATED:
            setCurrentStep(STEP_COMPLETE);
            checkBackendResult();
            break;
          case vidTokenStatus.FAILED:
            if (vidReq.isLastTry) {
              setCurrentStep(STEP_LIMIT_REACHED);
              break;
            }
            setCurrentStep(STEP_FAIL);
            setDiagnose(vidReq.diagnose)
            setHint(vidReq.hint);
            break;
          case vidTokenStatus.SUCCESS:
            setCurrentStep(STEP_SUCCESS);
            break;
          case vidTokenStatus.SKIPPED:
            return navigate(getNextRoute())
        }
        setQrSrc(vidReq.link);
        setTimeout(() => {
          setIsBusy(false);
        }, 1000)
      }
    }).catch(err => {
      setVidRequestError(true);
      setIsBusy(false);
    });
  }, []);

  // perform new verification
  const tryAgain = () => {
    setIsBusy(true);
    retryVid().then(res => {
      setIsBusy(false);
      if (res.id) {
        setCurrentStep(STEP_QR);
      }
    }).catch(err => {
      setIsBusy(false);
    });
  }

  // components and options for each verification step
  const steps = [
    {
      component: QrCode,
      options: {
        qrSrc: qrSrc,
        id: vidRequest ? vidRequest.id : null
      }
    },
    {
      component: VidPending,
    },
    {
      component: VidComplete
    },
    {
      component: VidSuccess
    },
    {
      component: VidFail,
      options: {
        diagnose: diagnose,
        hint: hint,
        tryAgain: tryAgain
      }
    },
    {
      component: VidLimitReached,
    }
  ];

  const checkBackendResult = () => {
    const mitekResultInterval = setInterval(() => {
      getVidRequest().then(res => {
        const vidReq = res.vidRequest;
        if (!vidReq) {
          return;
        }
        switch (vidReq.status) {
          case vidTokenStatus.FAILED:
            if (vidReq.isLastTry) {
              setCurrentStep(STEP_LIMIT_REACHED);
              break;
            }
            setCurrentStep(STEP_FAIL);
            setDiagnose(vidReq.diagnose)
            setHint(vidReq.hint)
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            break;
          case vidTokenStatus.MITEK_API_FAIL:
            setCurrentStep(STEP_MITEK_API_FAIL);
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            break;
          case vidTokenStatus.SUCCESS:
            setCurrentStep(STEP_SUCCESS);
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            break;
          case vidTokenStatus.SKIPPED:
            // stop recursive call, if we have a result from BE
            clearInterval(mitekResultInterval)
            return navigate(getNextRoute());
        }
      }).catch(err => {
        // if call fails, don't show error.
        // page refresh will fix the issue
      })
    }, config.FALLBACK_MITEK_RESULT_INTERVAL);
  }

  /********** EVENT LISTENERS ********/

  // the verification process is done only on the mobile devices
  // we need to listen for the possible updates and give feedback to the user on the desktop device as well

  const onVidReqTakenOver = useCallback(data => setCurrentStep(STEP_QR), []);

  // listen for the takeover event
  useSocketOn(socketEvent.vidReqTakenOver, onVidReqTakenOver);

  const onVidProcessStarted = useCallback(data => setCurrentStep(STEP_PENDING), []);

  // listen for the process-started event
  useSocketOn(socketEvent.vidProcessStarted, onVidProcessStarted);

  const onVidProcessComplete = useCallback(data => setCurrentStep(STEP_COMPLETE), []);

  // listen for the process-complete event
  useSocketOn(socketEvent.vidProcessComplete, onVidProcessComplete);

  const onVidSuccess = useCallback(data => setCurrentStep(STEP_SUCCESS), []);

  // listen for the vid success event
  useSocketOn(socketEvent.vidSuccess, onVidSuccess);

  // runs whenever the vid fail event is received from the backend
  const onVidFail = useCallback(data => {
    // refresh the vid request
    getVidRequest().then(res => {
      const vidReq = res.vidRequest;
      if (!vidReq) {
        return;
      }
      if (vidReq.status == vidTokenStatus.FAILED) {
        // all the orders have a max number of vid request retries set on the backend
        // if this limit is reached, don't allow the user to retry the vid
        if (vidReq.isLastTry) {
          setCurrentStep(STEP_LIMIT_REACHED);
          return;
        }
        setCurrentStep(STEP_FAIL);
        setDiagnose(vidReq.diagnose)
        setHint(vidReq.hint)
      }
    }).catch(err => {
      // if socket fails, don't show error.
      // page refresh will fix the issue
    });
  }, []);

  // listen for the vid fail event
  useSocketOn(socketEvent.vidFail, onVidFail);

  /********** OTHER ********/

  const ActiveStep = steps[currentStep].component;

  return <React.Fragment>
    {vidRequest &&
      <div id="vid_desktop_content">
        <Topbar />
        <Container>
          <Row className="justify-content-center">
            <div id="main_content_col" className="p-0">
              <Card id="vid_desktop_card" className="overflow-hidden">
                <DesktopHeader />
                <CardBody id="vid_desktop_card_body">
                  <ActiveStep {...steps[currentStep].options} />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <Footer />
      </div>
    }
    {isBusy && <Preloader />}
    {vidRequestError && <NotFound />}
  </React.Fragment>
}

export default DesktopContent;
