import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import { useSocketOn, useSubscribeToOrderSigner } from 'hooks/socket';
import socketEvent from 'constants/socketEvent';
import { formats, formatTimestamp } from 'helpers/dateHelper';
import OrderSigner from 'model/orderSigner';

const UserActivityStatus = props => {

  const { id, status, lastSeen, hideText, size, autoUpdate, skipSubscription, className } = props;

  /********** STATE **********/

  const [activityInfo, setActivityInfo] = useState({ status, lastSeen });

  /********** SOCKET **********/

  const condition1 = useCallback(() => autoUpdate && !skipSubscription && !!id, [autoUpdate, skipSubscription, id]);

  // start receiving updates about this particular signer
  // but only if auto-update has been requested
  // and if we should not skip the subscription part
  // and if an id has been provided
  useSubscribeToOrderSigner(id, condition1);

  // since this function does not depend on external variables
  // we can cache it between renders for performance reasons
  // also to prevent adding the event to the socket on every render
  const onUserActivityStatusChanged = useCallback(data => {
    // we subscribed to a single signer id
    // so we should be geeting notifications for that signer alone
    // nevertheless we extra check whether this update refers to the current signer
    if (data.id == id) {
      setActivityInfo({
        status: data.status,
        lastSeen: data.lastActive,
      });
    }
  }, []);

  const condition2 = useCallback(() => autoUpdate && !!id, [autoUpdate, id]);

  // listen for changes on signer activity status
  // but only if auto-update has been requested and an id has been provided
  useSocketOn(socketEvent.orderSignerActivityStatusChanged, onUserActivityStatusChanged, condition2);

  /********** OTHER **********/

  const getStatusColor = () => {
    switch (activityInfo.status) {
      case OrderSigner.ACTIVITY_STATUS_ACTIVE:
        return 'bg-success';
      case OrderSigner.ACTIVITY_STATUS_IDLE:
        return 'bg-warning';
      default:
        return 'bg-secondary';
    }
  }

  return <React.Fragment>
    {activityInfo.status !== undefined && <div className={classnames('user-activity-status', `user-activity-status-${activityInfo.status}`, `user-activity-status-${size}`, className)}>
      <i className={classnames('user-activity-status-circle', getStatusColor())}></i>
      {!hideText && <span className="user-activity-status-text ms-1">
        <span className="user-activity-status-name">{OrderSigner.getActivityStatusName(activityInfo.status)}</span>
        {!!activityInfo.lastSeen && activityInfo.status != OrderSigner.ACTIVITY_STATUS_ACTIVE && <span className="user-activity-status-last-seen ms-2 badge bg-light text-secondary align-middle">since {formatTimestamp(activityInfo.lastSeen, formats.SHORT_US_DATETIME)}</span>}
      </span>}
    </div>}
  </React.Fragment>
}

UserActivityStatus.propTypes = {
  // the id of the signer whose status we are showing
  // only relevant if 'autoUpdate' is TRUE
  // because we need to subscribe and listen to changes of this signer
  id: PropTypes.number,
  // the activity status value
  status: PropTypes.number,
  // the last activity timestamp
  lastSeen: PropTypes.number,
  // whether to hide the status text and only show the icon
  hideText: PropTypes.bool,
  // the display size of the widget (sm, md, lg, etc)
  size: PropTypes.string.isRequired,
  // whether to subscribe and listen to signer changes
  // and update the status in real time
  autoUpdate: PropTypes.bool,
  // whether to skip the subscription part when auto-updating
  // and only listen for changes
  // set this to TRUE when the subscription is handled by a parent component
  // only relevant when 'autoUpdate' is TRUE
  skipSubscription: PropTypes.bool,
  // the css class value
  className: PropTypes.string,
}

export default UserActivityStatus;