import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Card, Col } from "reactstrap";
import ChatSidebar from "./Chat/Sidebar";
import ChatConversation from "./Chat/Conversation";
import ChatModalHeader from "./Chat/ModalHeader";
import { useDispatch } from "react-redux";
import { doMessagesCleanup, getMessages } from "store/actions";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import channels from "constants/channels";

const MobileChatModal = ({ open, onToggle, channel }) => {

  const dispatch = useDispatch();

  /********** STATE **********/

  // local state
  const [openChannel, setOpenChannel] = useState();

  /********** OTHER **********/

  // load all messages for each channel the user has access to
  const getAllMessages = useCallback(() => {
    Object.keys(channels).map(chId => {
      dispatch(getMessages(chId));
    });
  }, []);

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  // refresh messages whenever a `messageReceived` event is coming from the backend
  // this event is emitted for every message sent, including own messages
  const onMessageReceived = useCallback(() => getAllMessages(), [getAllMessages]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we need to fetch all messages from the start so we can display the latest message in the menu
    getAllMessages();

    return () => {
      dispatch(doMessagesCleanup());
    }
  }, []);

  // sync local state to channel received
  useEffect(() => {
    setOpenChannel(channel)
  }, [channel]);

  /********** HANDLERS **********/

  const handleOpenChannel = id => event => {
    // prevent the Link component from redirecting
    event.preventDefault();

    setOpenChannel(id);
  };

  const handleCloseChannel = () => {
    setOpenChannel(null);
  }

  return <Modal id="mobile_chat_modal" className="modal-fullscreen" isOpen={open} toggle={onToggle} backdropClassName="white-backdrop">
    {!openChannel && <div className="px-4">
      <ChatModalHeader onCloseChat={onToggle} />
      <ChatSidebar onOpenChannel={handleOpenChannel} />
    </div>}
    {!!openChannel && <Col sm={12} lg={9} id="messages_view_mobile_wrapper" className="w-100 user-chat">
      <Card className="h-100 mb-0">
        <ChatConversation channelId={+openChannel} onGoBack={handleCloseChannel} />
      </Card>
    </Col>}
  </Modal>
}

MobileChatModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  channel: PropTypes.number,
};

export default MobileChatModal;