import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import {BrowserView, MobileView} from 'react-device-detect';
import DesktopContent from "pages/Vid/DesktopContent/DesktopContent";
import MobileContent from "pages/Vid/MobileContent/MobileContent";

const Index = () => {

  return <React.Fragment>
    <MetaTitle>ID Validation</MetaTitle>
    <BrowserView>
      <DesktopContent/>
    </BrowserView>
    <MobileView>
      <MobileContent />
    </MobileView>
  </React.Fragment>
}

export default Index;
