import config from "config";
import { autoCaptureHints, CAMERA_PERMISSION_DENIED, customMessages } from "constants/mitekMessages"

var timeout = null;

/* Force sdk stop after timeout */
const AUTO_CAPTURE_TIMEOUT = 20000;

export const forceStopSDK = () => {
  stopSDK();
  $(document).trigger('mitek:force-stop')
}

/* Stop auto capture execution and remove capture objects */
export const stopSDK = () => {
  clearForceStopTimeout();
  mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
  mitekScienceSDK.cmd('SDK_STOP');
  clearMitekUI();
}

export const clearMitekUI = () => {
  // DOM cleanup
  $('#captureHeader').addClass('d-none')
}

export const clearForceStopTimeout = () => {
  clearTimeout(timeout);
  timeout = null;
}

/******************* AUTO CAPTURE ************************/

export const startAuto = (docTypeSelection) => {

  /* When auto capture camera is visible */
  mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', function (result) {
    $(document).trigger('mitek:camera-started')
    // show the first initial hint message
    let firstMessage = customMessages[docTypeSelection].firstMessage;
    mitekScienceSDK.cmd('SHOW_HINT', firstMessage);
    $('#captureHeader').removeClass('d-none');
    timeout = setTimeout(forceStopSDK, AUTO_CAPTURE_TIMEOUT)
  });

  /* When we receive feedback and hints, regarding camera position and lighting */
  mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (result) {
    let recentHint = result.key;

    // SELFIE ONLY
    if (docTypeSelection === 'SELFIE') {
      // turn oval green if head is in guide
      let selfieElement = document.getElementById("mitekGuide");

      if (selfieElement) {
        if (result.key === 'MISNAP_SMILE'
          || result.key === 'MISNAP_STOP_SMILING'
          || result.key === 'MISNAP_READY_POSE') {
          selfieElement && selfieElement.classList.add('active');
        } else {
          selfieElement && selfieElement.classList.remove('active');
        }
      }
      if (recentHint !== null) {
        mitekScienceSDK.cmd('SHOW_HINT', autoCaptureHints[recentHint]);
      }
      // NOT SELFIE, EVERYTHING ELSE for feedback
    } else {
      if (recentHint !== null) {
        var hintMsg = autoCaptureHints[recentHint];
        // use a custom message for four corners not found
        if (recentHint === 'MITEK_ERROR_FOUR_CORNER') {
          hintMsg = customMessages[docTypeSelection].fourCornerMessage;
        }
        mitekScienceSDK.cmd('SHOW_HINT', hintMsg);
      }
    }
  });

  /* When we receive the final result */
  mitekScienceSDK.on('FRAME_CAPTURE_RESULT', (result) => {
    clearForceStopTimeout();
    if (result.response.error && result.response.error.type == CAMERA_PERMISSION_DENIED) {
      $(document).trigger('mitek:camera-permission-denied');
      return;
    }
    let image = result.response.imageData ?? result.response.failedImage;
    let message = autoCaptureHints[result.response.warnings.key]
    $(document).trigger('mitek:image-captured', [image, message])
  });

  /* When we receive any sdk error */
  mitekScienceSDK.on('SDK_ERROR', function (err) {
    console.log(err);
  });

  /* Initiate auto capture */
  mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
    mode: 'AUTO_CAPTURE',
    documentType: docTypeSelection,
    mitekSDKPath: "../../../mitekSDK",
    options: {
      qualityPercent: 100,
      hintFrequencyMS: 1000,
      hintAriaLive: 1,
      guidePaddingLevel: 1,
      hintMessageSize: 2,
      disableSmileDetection: false,
      disablePerpendicularCapture: true,
      license: config.MITEK_LICENSE
    }
  });
}

/******************** MANUAL CAPTURE ************************/

export const startManual = (docTypeSelection) => {

  setTimeout(() => {
    /* When user selected media image */
    mitekScienceSDK.on('IMAGE_CAPTURED', function () {
      $(document).trigger('mitek:media-image-selected', [])
    });

    /* When we receive feedback regarding image size and details */
    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (result) {
      if (result.key == "IMAGE_SMALLER_THAN_MIN_SIZE" || result.key == "CORRUPT_IMAGE") {
        $(document).trigger('mitek:image-captured', ['', 'Could not load image'])
      }
    });

    /* When we receive the last result */
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', (result) => {
      let image = result.response.imageData ?? result.response.failedImage;
      let message = autoCaptureHints[result.response.warnings.key]
      $(document).trigger('mitek:image-captured', [image, message])
    });

    /* When we receive any sdk error */
    mitekScienceSDK.on('SDK_ERROR', function (err) {
      console.log(err);
    });
  }, 0)

  /* Initiate manual capture */
  mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
    mode: 'MANUAL_CAPTURE',
    documentType: docTypeSelection,
    mitekSDKPath: "../../../mitekSDK",
    options: {
      qualityPercent: 70,
      license: config.MITEK_LICENSE,
    }
  });
}