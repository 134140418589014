import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import StepOptions from "../ContactModal/StepOptions";

const DesktopContactDirectModal = ({ open, onToggle, onOpenChat, selectedPerson }) => {

	return (
		<Modal
			className="contact-modal"
			isOpen={open}
			toggle={onToggle}
			centered
		>
			<ModalBody>
				<button type="button" className="btn-close" aria-label="Close" onClick={onToggle} />
				<StepOptions selectedPerson={selectedPerson} onOpenChat={onOpenChat} personRoleLabel={'Contact the '}/>
			</ModalBody>
		</Modal>
	);
}

DesktopContactDirectModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onOpenChat: PropTypes.func.isRequired,
	selectedPerson: PropTypes.object,
}

export default DesktopContactDirectModal;