import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { getBeUrl } from "helpers/utilHelper";
import { addAccessToken } from "helpers/tokenHelper";
import { timestamp } from "helpers/dateHelper";

const DocumentItem = ({ document, index }) => {

  return (<a href={getBeUrl(addAccessToken(`order-doc/${document.id}/pdf?${timestamp()}`))} target="_blank" rel="noreferrer">
    <div className="accordion-item" key={document.id} document-id={document.id}>
      <Row className="document-item gx-0 d-flex justify-content-between">
        <Col xs={8}>
          <div className="document-title">Document number {index + 1}</div>
          <div className="document-description">{document.name}</div>
        </Col>
        <Col xs={2} className="d-block my-auto text-end">
          <span className="badge rounded-pill bg-primary document-badge">{document.numOfPages}<i className="far fa-file ms-1"></i></span>
        </Col>
        <Col xs={1} className="accord-arrow">
          <i className="fas fa-angle-right" />
        </Col>
      </Row>
    </div>
  </a>);
};

DocumentItem.propTypes = {
  document: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default DocumentItem;