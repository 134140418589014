import React, { useState } from "react";
import DocViewer from 'components/Shared/DocViewer';
import PropTypes from "prop-types";
import { useAuth } from "context/auth";

const Sign = ({ id, goBack }) => {

  const { user: authUser } = useAuth();

  // by default, hide the signature fields so the user can read the doc
  const [isReadOnly, setIsReadOnly] = useState(true);

  return (
    <React.Fragment>
      <DocViewer
        docId={id}
        currentSignerId={authUser.id}
        readOnly={isReadOnly}
        onSigningStarted={() => setIsReadOnly(false)}
        onViewerClosed={() => goBack()}
        onSigningComplete={() => goBack()}
        onDocLoaded={doc => doc.signingId ? setIsReadOnly(false) : setIsReadOnly(true)} />
    </React.Fragment>
  )
}

Sign.propTypes = {
  id: PropTypes.number,
  goBack: PropTypes.func,
};

export default Sign;
