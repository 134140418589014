import Message from "model/message";
import {
  CREATE_MESSAGE,
  CREATE_MESSAGE_ERR,
  CREATE_MESSAGE_OK,

  DO_MESSAGES_CLEANUP,
  GET_MESSAGES,
  GET_MESSAGES_ERR,
  GET_MESSAGES_OK
} from "./actionTypes";

const defaultMessagesState = {
  messages: {
    [Message.CHANNEL_DEALER_CUSTOMER]: [],
    [Message.CHANNEL_SUPPORT_DEALER_CUSTOMER]: [],
    [Message.CHANNEL_SCHEDULER_CUSTOMER]: [],
    [Message.CHANNEL_NOTARY_CUSTOMER]: [],
  },
  isLoadBusy: false,
  messagesError: null,
  saved: null,
  isSaveInProgress: false,
  saveError: null,
};

const Messages = (state = defaultMessagesState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      state = {
        ...state,
        isLoadBusy: true,
        messagesError: null,
      };
      break
    case GET_MESSAGES_OK:
      state = {
        ...state,
        isLoadBusy: false,
        messages: {
          ...state.messages,
          [action.payload.channelId]: action.payload.response.messages
        }
      };
      break
    case GET_MESSAGES_ERR:
      state = {
        ...state,
        isLoadBusy: false,
        messages: null,
        messagesError: action.payload,
      };
      break
    case DO_MESSAGES_CLEANUP:
      state = {
        ...defaultMessagesState,
      };
      break
    case CREATE_MESSAGE:
      state = {
        ...state,
        isSaveInProgress: true,
        saveError: null,
        saved: null,
      };
      break
    case CREATE_MESSAGE_OK:
      state = {
        ...state,
        isSaveInProgress: false,
        saved: true
      };
      break
    case CREATE_MESSAGE_ERR:
      state = {
        ...state,
        isSaveInProgress: false,
        saved: false,
        saveError: action.payload.error
      };
      break
  }
  return state;
}

export default Messages;