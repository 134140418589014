import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDocViewer } from '../../Context';
import SpinnerChase from 'components/Shared/SpinnerChase';
import DocViewerDropLayer from './DropLayer';
import classnames from 'classnames';
import { getBeUrl } from 'helpers/utilHelper';
import { addAccessToken } from 'helpers/tokenHelper';

const DocViewerPage = props => {

  /**
   * Component props:
   * pageNum {int} the number of this page
   * onPageReady {func} callback to call when the page image has finished loading or has failed to load
   */
  const { pageNum, onPageReady } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, readOnly } = useDocViewer();

  /**
   * Bool flag that tells whether the image has loaded or not
   * The spinner will be visible or not based on this value
   */
  const [pageLoaded, setPageLoaded] = useState(false);

  /**
   * Event handler called when a page image has finished loading or has failed to load
   * @returns {void}
   */
  const pageReadyHandler = () => {
    setPageLoaded(true);
    onPageReady();
  }

  return <div className={'doc-viewer-page doc-viewer-page-' + pageNum} data-page-num={pageNum}>
    <div className={classnames('doc-viewer-page-content', { loaded: pageLoaded })}>
      {!pageLoaded && <SpinnerChase />}
      <img src={getBeUrl(addAccessToken(`order-doc/${orderDoc.id}/e-sign/page/${pageNum}.png`))} className="doc-viewer-page-image" onLoad={pageReadyHandler} onError={pageReadyHandler} />
      {pageLoaded && !readOnly && <DocViewerDropLayer pageNum={pageNum} />}
    </div>
  </div>
}

DocViewerPage.propTypes = {
  pageNum: PropTypes.number,
  onPageReady: PropTypes.func,
}

export default DocViewerPage;