import React, { useEffect, useState } from "react";
import { getOrder } from "helpers/backendHelper";

const AppointmentContext = React.createContext();

const AppointmentProvider = props => {
  const [isBusy, setIsBusy] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  // runs once on component mount
  useEffect(() => {
    refreshOrder();
  }, []);

  // the appointment data is included in the order
  // therefore, we must fetch the order
  // and display an error if the order doesn't have notary service
  // because, in that case, there won't be any appointment
  const refreshOrder = () => {
    setIsBusy(true);
    getOrder().then(res => {
      if (!res.order || !res.order.isNotaryRequired) {
        setOrderError(true);
        return;
      }
      setOrder(res.order)
    }).catch(err => {
      setOrderError(true);
    }).finally(() => {
      setIsBusy(false);
    });
  }

  return <AppointmentContext.Provider value={{
    order, refreshOrder, isDeclining, setIsDeclining, isBusy, orderError,
  }} {...props} />
}

// helper hook that makes context data available
export const useAppointment = () => React.useContext(AppointmentContext);

export default AppointmentProvider;