import React, { useState } from "react";
import Webcam from "react-webcam";
import PropTypes from "prop-types";
import Preloader from "components/Shared/Preloader";

const Capture = ({ getCapture, back, contract, pageId, nextPage, getToBeScannedPages }) => {

  const [preview, setPreview] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [isBusy, setIsBusy] = useState(true);

  Capture.propTypes = {
    getCapture: PropTypes.func,
    back: PropTypes.func,
    contract: PropTypes.object,
    pageId: PropTypes.number,
    nextPage: PropTypes.func,
    getToBeScannedPages: PropTypes.func,
  };

  const videoConstraints = {
    // letter size aspect ratio
    width: 1920,
    height: 1484,
    facingMode: "environment"
  };

  const retry = () => {
    setPreview(false);
    setImgData(null);
  }

  const captureTaken = (image) => {
    setPreview(true)
    setImgData(image);
  }

  const goToNextPage = () => {
    nextPage();
    setPreview(false)
  }

  const showCamera = () => {
    setIsBusy(false)
  }

  const confirmCapture = () => {
    getCapture(imgData);
    goToNextPage();
  }

  const endSession = () => {
    getCapture(imgData);
    back();
  }

  return <React.Fragment>
    <div id="captureParent">
      <div id="contractName">{contract.name}</div>
      <div id="contractPage">Scan contract page no {pageId}</div>
      <div id="cameraWrapper">
        {preview &&
          <div id="captureWrapper">
            <img id="capturePreview" className={preview ? 'preview' : ''} src={imgData}></img>
          </div>
        }
        <Webcam id="webcam" videoConstraints={videoConstraints} className={preview ? 'd-none' : ''} forceScreenshotSourceSize={true} onUserMedia={showCamera} screenshotFormat="image/jpeg">
          {({ getScreenshot }) => (
            <>
              {!preview &&
                <div id="previewFooter">
                  <div id="previewActionsRow" className="mt-2">
                    <div className="preview-action">
                      <button onClick={back}>
                        <i className="fas fa-arrow-left"></i>
                      </button>
                      <label>Back to contracts</label>
                    </div>
                    <div className="preview-action">
                      <button className="primary" onClick={() => captureTaken(getScreenshot())}>
                        <i className="fas fa-camera"></i>
                      </button>
                      <label>Take picture</label>
                    </div>
                    {!getToBeScannedPages().length ?
                      <div className="preview-action">
                        <button onClick={back}>
                          <i className="fas fa-arrow-right"></i>
                        </button>
                        <label>Finish</label>
                      </div>
                      :
                      <div className="preview-action">
                        <button onClick={goToNextPage}>
                          <i className="fas fa-arrow-right"></i>
                        </button>
                        <label>Next page</label>
                      </div>
                    }
                  </div>
                  <div className="powered">Powered by Mavsign</div>
                </div>
              }
              {preview &&
                <div id="previewFooter">
                  <div id="previewActionsRow" className="mt-2">
                    <div className="preview-action">
                      <button onClick={back}>
                        <i className="fas fa-arrow-left"></i>
                      </button>
                      <label>Back to contracts</label>
                    </div>
                    {!getToBeScannedPages().length ?
                      <div className="preview-action">
                        <button className="primary" onClick={endSession}>
                          <i className="fas fa-check"></i>
                        </button>
                        <label>Looks good, finish</label>
                      </div>
                      :
                      <div className="preview-action">
                        <button className="primary" onClick={confirmCapture}>
                          <i className="fas fa-check"></i>
                        </button>
                        <label>Looks good, scan next page</label>
                      </div>
                    }
                    <div className="preview-action">
                      <button onClick={retry}>
                        <i className="fas fa-undo"></i>
                      </button>
                      <label>Retry</label>
                    </div>
                  </div>
                  <div className="powered">Powered by Mavsign</div>
                </div>
              }
            </>
          )}
        </Webcam>
      </div>
    </div>
    {isBusy && <Preloader />}
  </React.Fragment>
}

export default Capture;