import React from "react";
import ViewAppointment from "./Partial/ViewAppointment";
import AppointmentPending from "./Partial/AppointmentPending";
import { useAppointment } from "context/appointment";
import OrderNotary from "model/orderNotary";
import AppointmentAccepted from "./Partial/AppointmentAccepted";

// compute the active step based on the order notary status
const getActiveStep = status => {
  switch (status) {
    case OrderNotary.STATUS_VIEWED:
    case OrderNotary.STATUS_ASSIGNED:
    case OrderNotary.STATUS_DOCS_RECEIVED:
      return <AppointmentPending />
    case OrderNotary.STATUS_APPOINTMENT_SET:
      return <ViewAppointment />
    case OrderNotary.STATUS_APPOINTMENT_ACCEPTED:
    case OrderNotary.STATUS_DOCS_PRINTED:
    case OrderNotary.STATUS_DOCS_UPLOADED:
    case OrderNotary.STATUS_ORDER_COMPLETE:
      return <AppointmentAccepted />
  }
}

const ActiveStep = () => {

  const { order } = useAppointment();

  // if there is no notary assigned yet, we are redirecting the user to a pending screen
  if (!order.orderNotary) {
    return <AppointmentPending />
  }

  return getActiveStep(order.orderNotary.status);
};

export default ActiveStep;