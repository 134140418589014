import MobileMenu from "components/Shared/MobileMenu";
import Preloader from "components/Shared/Preloader";
import { getOrder } from "helpers/backendHelper";
import NotFound from "pages/Error/NotFound";
import React, { useEffect, useState } from "react";
import ActionsRow from "./Partial/ActionsRow";
import Contracts from "./Steps/Contracts";
import { Row, Col, Container } from "reactstrap";
import { useAuth } from "context/auth";
import OrderSigner from "model/orderSigner";
import menuSteps from "constants/menuSteps";

const STEP_CONTRACTS = 0;
const currentStep = STEP_CONTRACTS;

const MobileContent = () => {

  const { user: authUser } = useAuth();

  const [isBusy, setIsBusy] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  // runs once on component mount
  useEffect(() => {
    setIsBusy(true);
    getOrder().then(res => {
      setIsBusy(false);
      // if the order has the notary service
      // redirect the user to the appointment screen
      // this is because the ink-signatures are managed by the notary in that case
      if (res.order && res.order.isNotaryRequired) {
        navigate(route("appointment"));
        return;
      }
      // there are some cases in which we should display an error if the users lands on this page:
      // - if we couldn't load the order
      // - if the order doesn't have the ink-sign service
      // - if the user is a dealer signer (they can only navigate in the e-sign screens)
      if (!res.order || !res.order.isInkSignRequired || authUser.type == OrderSigner.TYPE_DEALER) {
        setOrderError(true);
        return;
      }
      setOrder(res.order)
    }).catch(err => {
      setIsBusy(false);
      setOrderError(true);
    })
  }, []);

  const steps = [
    {
      component: Contracts,
      options: {
        hasESign: order ? order.isEsignRequired : null,
        order: order
      }
    }
  ];

  const ActiveStep = steps[currentStep].component;

  return <React.Fragment>
    <div id="signature_mobile_wrapper">
      {order && <>
        <MobileMenu activeStep={menuSteps.SIGNATURES} />
        <Container>
          <Row className="justify-content-center">
            <Col md={9} lg={6} xl={5} className="p-0">
              <ActionsRow />
              <ActiveStep {...steps[currentStep].options} />
            </Col>
          </Row>
        </Container>
      </>
      }
      {isBusy && <Preloader />}
      {orderError && <NotFound />}
    </div>
  </React.Fragment>
}

export default MobileContent;
