import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Button, Form, FormGroup, InputGroup, Input, Alert, UncontrolledTooltip, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Error from "pages/Error";
import { useDispatch, useSelector } from "react-redux";
import { formats, formatTimestamp, isSameDate } from "helpers/dateHelper";
import { createMessage, getMessages } from "store/actions";
import { bytesToSize, getBeUrl, randomStringSync, showError } from "helpers/utilHelper";
import channels from "constants/channels";
import "react-perfect-scrollbar/dist/css/styles.css";
import config from "config";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Message from "model/message";
import EventEmitter from 'helpers/eventsHelper';
import { useAuth } from "context/auth";
import { addAccessToken } from "helpers/tokenHelper";
import Templates from "./Templates";
import templateIcon from 'assets/images/chat-template-icon.svg';
import nextLineIcon from 'assets/images/next-line-icon.svg';
import useAutosizeTextArea from "hooks/useAutosizeTextArea";

const Conversation = ({ channelId, onGoBack, height }) => {

  const dispatch = useDispatch();

  const chatRef = useRef();
  const inputMessageRef = useRef();

  const { user } = useAuth();

  /********** STATE **********/

  const [searchMenu, setSearchMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchDirty, setIsSearchDirty] = useState(false);

  const [moreMenu, setMoreMenu] = useState(false);
  const [draftMessage, setDraftMessage] = useState("");
  const lastMessageRef = useRef(null);
  const [showFullTimestamp, setShowFullTimestamp] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState([]);

  // State for template messages carousel
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(1)
  const [isTemplatesOn, setIsTemplatesOn] = useState(true);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [numberOfMultipleItems, setNumberOfMultipleItems] = useState(3);

  useAutosizeTextArea(inputMessageRef.current, draftMessage)

  // get redux state from the store
  const { messages, isLoadBusy, messagesError, isSaveInProgress, saved } = useSelector(state => state.Message);
  const currentMessages = messages[channelId];

  const isValidChannel = Object.keys(channels).find(id => id == channelId);


  /********** DROPZONE **********/

  const dropFile = useCallback(file => {
    // we will be listening for file upload progress
    // however multiple files may be uploaded in the same time
    // so we need a way to identify which file the progress report belongs to
    const uid = randomStringSync(3);
    // add file to the preview list
    setFilesToUpload(files => [...files, {
      file,
      uid,
      preview: URL.createObjectURL(file),
      progress: 100,
    }]);
    // upload file to backend
    const formData = new FormData();
    formData.append('orderId', user.orderId);
    formData.append('channel', channelId);
    formData.append('content', file);
    formData.append('uid', uid);
    dispatch(createMessage(formData));
  }, [user.orderId, channelId]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: config.CHAT_MAX_IMAGES,
    maxSize: config.CHAT_IMG_MAX_SIZE,
    noClick: true,
    onDropAccepted: acceptedFiles => {
      for (const file of acceptedFiles) {
        dropFile(file);
      }
    },
    onDropRejected: rejectedFiles => {
      const firstFile = rejectedFiles[0];
      const firstError = firstFile.errors[0];
      let message;
      switch (firstError.code) {
        case 'file-invalid-type':
          message = 'Please upload only images (png, jpg, jpeg)';
          break;
        case 'too-many-files':
          message = `Please select a maximum of ${config.CHAT_MAX_IMAGES} files`;
          break;
        case 'file-too-large':
          message = `Please upload files up to ${bytesToSize(config.CHAT_IMG_MAX_SIZE)} in size`;
          break;
        default:
          message = firstError.message;
          break;
      }
      showError(message);
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // listen to image upload progress
    EventEmitter.on('message.uploadProgress', uploadProgressReceived);
    const chatDropzone = chatRef.current;
    chatDropzone.addEventListener('paste', onPasteInChat);
    return () => {
      EventEmitter.off('message.uploadProgress', uploadProgressReceived);
      // it is important to NOT remove event listeners from 'chatRef.current' directly
      // user a local variable like 'chatDropzone' instead
      // because by the time this component is unmounted 'chatRef.current' will be NULL
      chatDropzone.removeEventListener('paste', onPasteInChat);
    }
  }, []);

  // runs whenever the `currentMessages` list changes
  // including at component mount
  // if there are messages in the list, scroll to the bottom of the page
  useEffect(() => {
    if (currentMessages?.length) scrollToBottom();
  }, [currentMessages]);

  // runs whenever the `isLoadBusy` flag changes
  // we need to scroll to bottom in order to show the loading dots
  useEffect(() => {
    if (isLoadBusy) scrollToBottom();
  }, [isLoadBusy]);

  // runs whenever the `saved` flag changes
  useEffect(() => {
    // if save was successful, clear the draft messages
    if (saved === true) { setDraftMessage(""); }
    else if (saved === false) {
      showError("Unable to send message");
    }
  }, [saved])

  // runs whenever the channel changes
  // to erase draft message when switching the channel
  useEffect(() => {
    setDraftMessage("");
  }, [channelId])

  // runs whenever the search term changes
  // used to detect clearing of text
  useEffect(() => {
    if (isSearchDirty && !searchTerm) {
      dispatch(getMessages(channelId));
      // reset dirty flag after refreshing messages
      setIsSearchDirty(false);
    }
  }, [searchTerm])

  // runs on each channel id change, we use it to know which templates should we show
  useEffect(() => {
    switch (channelId) {
      case Message.CHANNEL_SCHEDULER_CUSTOMER:
        setTemplateMessages(Message.CUSTOMER_SCHEDULER_TEMPLATES);
        break;
      case Message.CHANNEL_NOTARY_CUSTOMER:
        setTemplateMessages(Message.CUSTOMER_NOTARY_TEMPLATES);
        break;
      case Message.CHANNEL_DEALER_CUSTOMER:
        setTemplateMessages(Message.CUSTOMER_DEALER_TEMPLATES)
        break;
      default:
        setTemplateMessages([]);
        break;
    }
  }, [channelId])

  // Runs on component mount to get the screen size
  useEffect(() => {
    window.addEventListener('resize', getNumberOfItemsPercentage);
    // Run just once to set the inital value
    getNumberOfItemsPercentage();
    return () => window.removeEventListener('resize', getNumberOfItemsPercentage);
  }, [channelId])

  /********** HANDLERS **********/

  const sendMessage = () => {
    dispatch(createMessage({ channel: channelId, content: draftMessage }))
  }

  const toggleFullTimestamp = () => {
    setShowFullTimestamp(current => !current)
  }

  const toggleMore = () => {
    setMoreMenu(current => !current);
  }

  const toggleSearch = () => {
    setSearchMenu(current => !current);
  }

  const handleSubmit = event => {
    // prevent refresh
    event.preventDefault();
    // mark search as dirty
    // in order to know that the user searched the chat for some term
    setIsSearchDirty(true);
    // refresh messages
    dispatch(getMessages(channelId, { search: searchTerm }));
  }

  const handleInputKeyDown = event => {
    if (!!draftMessage && !isSaveInProgress) {
      // Ctrl + Enter Action
      if (event.keyCode == 13 && event.ctrlKey) {
        // Get the position on the input cursor
        const position = event.target.selectionEnd;
        // Add next line from the position (where the cursor is)
        const newValue = draftMessage.substring(0, position) + '\n' + draftMessage.substring(position)
        // Move the cursor at the start of the new line created, we use setTimeout because the input renders first
        // so we need to wait a bit to set the input cursor
        setTimeout(() => {
          inputMessageRef.current.setSelectionRange(position + 1, position + 1)
        }, 0);
        // Changing the message draft
        setDraftMessage(newValue)
      }
      // Enter Action, send the message
      if (event.keyCode == 13 && !event.ctrlKey) {
        event.preventDefault();
        sendMessage();
      }
    } else {
      // Don't generate a new line if user presses enter when no message is entered
      if (event.keyCode == 13) {
        event.preventDefault();
      }
    }
  }

  // Handle when user clicks on next line icon
  const handleNextLine = () => {
    // Focus on the input
    inputMessageRef.current.focus();
    // Get the position on the input cursor
    const position = inputMessageRef.current.selectionEnd;
    // Add next line from the position (where the cursor is)
    const newValue = draftMessage.substring(0, position) + '\n' + draftMessage.substring(position)
    // Move the cursor at the start of the new line created, we use setTimeout because the input renders first
    // so we need to wait a bit to set the input cursor
    setTimeout(() => {
      inputMessageRef.current.setSelectionRange(position + 1, position + 1)
    }, 0);
    // Changing the message draft
    setDraftMessage(newValue)
  }

  // Handle for moving through the templates list (next carousel)
  const handleNextTemplate = () => {
    setCurrentTemplateIndex(prevState => prevState + 1)
  }

  // Handle for moving through the templates list (previous carousel)
  const handlePreviousTemplate = () => {
    setCurrentTemplateIndex(prevState => prevState - 1)
  }

  // Handle when user clicks on a template (it populates the input)
  const handleTemplateItemClick = (itemText) => {
    setDraftMessage(itemText)
  }

  // Handle when user clicks on toggle templates (show/hide templates)
  const handleToggleTemplates = () => {
    setIsTemplatesOn(prev => !prev)
  }

  // Function that sets the percentage for multiple items (33.3 means 3 per page)
  const getNumberOfItemsPercentage = () => {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if (channelId === Message.CHANNEL_NOTARY_CUSTOMER) {
      if (width < 1700) {
        setNumberOfMultipleItems(2);
        setCurrentTemplateIndex(0);
      };
      if (width < 1300) {
        setNumberOfMultipleItems(1);
        setCurrentTemplateIndex(0);
      };
    } else {
      if (width > 1700) {
        setNumberOfMultipleItems(3);
        setCurrentTemplateIndex(1);
      }
      if (width < 1700) {
        setNumberOfMultipleItems(2);
        setCurrentTemplateIndex(1);
      };
      if (width < 1300) {
        setNumberOfMultipleItems(1);
        setCurrentTemplateIndex(1);
      };
    }
  }

  // Function that determines if the next button on templates is disabled or not
  const nextButtonIsDisabled = () => {
    if (numberOfMultipleItems === 1) {
      return currentTemplateIndex === templateMessages.length - 1
    }
    if (numberOfMultipleItems === 2) {
      return currentTemplateIndex > templateMessages.length - 2
    }
    if (numberOfMultipleItems === 3) {
      return currentTemplateIndex > templateMessages.length - 3
    }
    return false;
  }

  // Function that determines if the previous button on templates is disabled or not
  const previousButtonIsDisabled = () => {
    if (numberOfMultipleItems === 1) {
      return currentTemplateIndex === 0
    }
    if (numberOfMultipleItems === 2) {
      return currentTemplateIndex < 1
    }
    if (numberOfMultipleItems === 3) {
      return currentTemplateIndex < 2
    }
    return false;
  }

  /********** OTHER **********/

  const scrollToBottom = () => {
    if (lastMessageRef?.current) {
      lastMessageRef.current.scrollIntoView();
    }
  };

  const getMessageImageUrl = messageId => getBeUrl(addAccessToken(`message/${messageId}/image.png`));

  const getMessageThumbnailUrl = messageId => getBeUrl(addAccessToken(`message/${messageId}/thumbnail.png`));

  const getGalleryItemContent = messageId => (<img src={getMessageImageUrl(messageId)} className="gallery-preview-img" />)

  const uploadProgressReceived = useCallback(data => {
    // see for which file this progres report is
    const uid = data._fileUid;
    const progress = 100 - Math.round(data.ev.loaded / data.ev.total * 100);
    setFilesToUpload(files => {
      if (progress == 0) {
        // upload is finished so remove the file from the preview queue
        return files.filter(f => f.uid != uid);
      } else {
        return files.map(f => {
          if (f.uid == uid) {
            // update the progress of the file
            f.progress = progress;
          }
          return f;
        });
      }
    });
  }, []);

  const onPasteInChat = useCallback(e => {
    const acceptedRegx = /^image\/(jpe?g|png)$/i;
    for (const item of e.clipboardData.items) {
      if (acceptedRegx.test(item.type)) {
        dropFile(item.getAsFile());
      }
    }
  }, [dropFile]);

  if (!isValidChannel) {
    return <Error title404="Channel not found" />;
  };

  return (<React.Fragment>
    <div className="p-3 border-bottom chat-header">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-start p-0">
          {onGoBack && <span className="back-btn" role="button" onClick={onGoBack}>
            <i className="bx bx-chevron-left text-primary" />
          </span>}
          <h5>
            {channels[channelId]}
          </h5>
        </div>
        <div>
          <ul className="list-inline user-chat-nav text-end mb-0">
            <li className="list-inline-item d-inline-block">
              <Dropdown
                isOpen={searchMenu}
                toggle={toggleSearch}
                direction="start"
              >
                <DropdownToggle className="btn nav-btn" tag="i">
                  <i className="bx bx-search-alt-2" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-md"
                >
                  <Form className="p-2 p-sm-3" onSubmit={handleSubmit}>
                    <FormGroup className="m-0 mb-sm-3">
                      <InputGroup>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Search in chat"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e.target.value)}
                        />
                        <Button color="primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </Button>
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item">
              <Dropdown
                isOpen={moreMenu}
                toggle={toggleMore}
              >
                <DropdownToggle className="btn nav-btn" tag="i">
                  <i className="bx bx-dots-horizontal-rounded" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={toggleFullTimestamp}>
                    {(showFullTimestamp ? 'Hide' : 'Show') + ' full timestamp'}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div {...getRootProps({ className: "chat-dropzone" })}>
      <div ref={chatRef}>
        {messagesError && <Alert color="danger" className="fade show text-center mb-4">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages
        </Alert>}

        {!messagesError && <div className="chat-conversation p-lg-3">
          {!currentMessages.length && isLoadBusy && <div className="dot-flashing mx-auto chat-item" />}
          <Gallery options={{ mainClass: "chat-gallery", bgClickAction: "close" }}>
            <PerfectScrollbar
              style={{ height }}
            >
              {!currentMessages.length && !isLoadBusy && <div className="text-center chat-item">
                <span className="text-muted">{isSearchDirty ? "No messages found" : "Write a message to start the conversation"}</span>
              </div>}
              {currentMessages.map((message, index) => {

                const dateLabel = isSameDate(message.createdTs) ? "Today" : formatTimestamp(message.createdTs, formats.CHAT_DATE)

                return (<div key={message.id}>
                  {index === 0 && (
                    <div className="chat-day-title chat-item">
                      <span className="title">{dateLabel}</span>
                    </div>
                  )}
                  {index > 0 && !isSameDate(message.createdTs, currentMessages[index - 1]?.createdTs) && (
                    <div className="chat-day-title chat-item">
                      <span className="title">{dateLabel}</span>
                    </div>
                  )}
                  <div
                    className={classnames("chat-item", message.userId === null ? "right" : "")}
                    {...(index === currentMessages.length - 1) && { ref: lastMessageRef }}
                  >
                    <div className="conversation-list">
                      <div className="ctext-wrap">
                        <div className="conversation-name">
                          {message.senderName}
                        </div>
                        {message.contentType == Message.CONTENT_TYPE_IMAGE && <Item content={getGalleryItemContent(message.id)}>
                          {({ ref, open }) => (<img src={getMessageThumbnailUrl(message.id)} className="conversation-content-image mt-1 mb-3" onLoad={scrollToBottom} ref={ref} onClick={open} />)}
                        </Item>}
                        {message.contentType == Message.CONTENT_TYPE_TEXT && <p className="message-content">{message.content}</p>}
                        <div className="chat-time mb-0">
                          <div style={{ cursor: 'pointer' }} onClick={toggleFullTimestamp}>
                            <i className="bx bx-time-five align-middle me-1" />
                            {formatTimestamp(message.createdTs, showFullTimestamp ? formats.DATETIME : formats.HOUR)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              })}
              {isLoadBusy && !!currentMessages.length && <div className="dot-flashing mx-auto chat-item" />}
            </PerfectScrollbar>
          </Gallery>
        </div>}

        {!!filesToUpload.length && <div className="dz-preview p-3 pt-0">
          {filesToUpload.map(entry => {
            return <div key={entry.uid} className="dz-preview-item">
              <img className="dz-preview-img" src={entry.preview} onLoad={() => { URL.revokeObjectURL(entry.preview) }} />
              <div className="dz-upload-progress" style={{ width: `${entry.progress}%` }}></div>
              <i className="mdi mdi-spin mdi-loading dz-preview-loader" />
            </div>
          })}
        </div>}

        <div className="p-3 chat-input-section">
          {
            !!templateMessages.length &&
            <Collapse isOpen={isTemplatesOn}>
              <div className={classnames("mb-3 mt-1", { "ms-3": numberOfMultipleItems > 1 })}>
                {/* Chat Templates */}
                <Templates
                  templateMessages={templateMessages}
                  activeIndex={currentTemplateIndex}
                  onItemClick={handleTemplateItemClick}
                  numberOfMultipleItems={numberOfMultipleItems}
                  onItemChange={(index) => setCurrentTemplateIndex(index)}
                />
              </div>
            </Collapse>
          }
          {/* Action Bar */}
          <Row>
            <Col>
              <div className="mb-3 d-flex justify-content-between">
                <div className="ms-3">
                  <img id='next-line-icon' onClick={handleNextLine} src={nextLineIcon} className="me-4" />
                  <UncontrolledTooltip placement="top" target='next-line-icon'>Press Ctrl + Enter to move to the next line</UncontrolledTooltip>
                  {
                    !!templateMessages.length &&
                    <>
                      <img id='template-icon' onClick={handleToggleTemplates} src={templateIcon} className="me-2" />
                      <UncontrolledTooltip placement="top" target='template-icon'>{isTemplatesOn ? "Hide templates" : "Display templates"}</UncontrolledTooltip>
                    </>
                  }
                </div>
                {
                  isTemplatesOn && !!templateMessages.length &&
                  <div className="d-flex">
                    <button
                      className="btn template-action-button me-2"
                      tag="button"
                      onClick={handlePreviousTemplate}
                      disabled={previousButtonIsDisabled()}
                    >
                      <i className='bx bxs-left-arrow text-primary template-action-icon' ></i>
                    </button>
                    <button
                      className="btn template-action-button"
                      tag="button"
                      onClick={handleNextTemplate}
                      disabled={nextButtonIsDisabled()}
                    >
                      <i className='bx bxs-right-arrow text-primary template-action-icon' ></i>
                    </button>
                  </div>
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="position-relative">
                <textarea
                  type="text"
                  value={draftMessage}
                  ref={inputMessageRef}
                  onChange={e => setDraftMessage(e.target.value)}
                  onKeyDown={handleInputKeyDown}
                  className="form-control chat-input"
                  placeholder="Enter Message..."
                  rows={1}
                />
                <div className="chat-input-links">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Button color="default" size="sm" onClick={open}>
                        <i className="mdi mdi-file-image-outline" id="Filetooltip" />
                        <UncontrolledTooltip placement="top" target="Filetooltip">Add Images</UncontrolledTooltip>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col className="col-auto">
              <Button
                type="button"
                color="primary"
                onClick={sendMessage}
                disabled={!draftMessage || isSaveInProgress}
                className="btn btn-primary btn-rounded chat-send w-md "
              >
                <span className="d-none d-sm-inline-block me-2">
                  Send
                </span>
                {isSaveInProgress ? <i className="mdi mdi-spin mdi-loading ms-1" /> : <i className="mdi mdi-send" />}
              </Button>
            </Col>
          </Row>
        </div>

        <div className={classnames('dropzone m-1', { 'is-drag-active': isDragActive })}>
          <div className="dz-message needsclick" onClick={open}>
            <input {...getInputProps()} />pe
            <div className="dz-message-text">
              <i className="display-4 dz-message-icon text-muted bx bxs-cloud-upload me-2" />
              <h4>Drop images here</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}

Conversation.propTypes = {
  channelId: PropTypes.number.isRequired,
  onGoBack: PropTypes.func,
  height: PropTypes.number,
};

export default Conversation;