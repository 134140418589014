import React, { useEffect, useState } from 'react';
import Preloader from "components/Shared/Preloader";
import { getAuthUser } from 'helpers/backendHelper';
import Error from 'pages/Error';
import { AccessDeniedException, NetworkErrorException } from 'helpers/errorHelper';
import { getAccessToken, isUserVerifiedLocal, removeVerifCodeSent, removeVerificationToken, resetVerification, saveAccessToken } from 'helpers/tokenHelper';

const AuthContext = React.createContext();

const AuthProvider = props => {

  // read signer uuid from url query
  const queryParams = new URLSearchParams(window.location.search);
  const orderSignerUuid = queryParams.get('s');

  // the authenticated user
  const [user, setUser] = useState(null);

  // bool flag telling if the user is authenticated or not
  // by default this is NULL (authentication unknown)
  // it will be set to TRUE/FALSE after checking with the backend
  const [isAuth, setIsAuth] = useState(null);

  // authentication error received from the backend
  const [userError, setUserError] = useState(null);

  const isLoggedIn = () => !!user;

  // this runs whenever the auth token changes
  // which happens once, when the page is first loaded or refreshed
  useEffect(() => {
    // the auth token is present in the url only the first time the user opens the app
    // as the user navigates through the app, the token is no longer present in the url
    // so here we check if the page url contains an auth token
    // if so, we save the token to session storage so we do not lose it as the user navigates
    // if there is no token in the url, we assume that the token has already been saved
    // and we will attempt to read it from session storage
    // if there is no token in the session storage then we conclude that the user has never authenticated
    if (orderSignerUuid) {
      // if access token has changed
      if (getAccessToken() != orderSignerUuid) {
        resetVerification();
      }
      saveAccessToken(orderSignerUuid);
    }
    // call the backend to check user token
    refreshAuthUser();
  }, [orderSignerUuid]);

  const refreshAuthUser = () => {
    getAuthUser()
      .then(response => {
        // if the backend responded with a user object then the token is valid
        // else the token is invalid
        if (!!response.user) {
          if (!response.user.isVerified) {
            if (isUserVerifiedLocal()) {
              // if user had a token, but expired in the meantime
              removeVerifCodeSent();
            }
            removeVerificationToken();
          }
          setUser(response.user);
          setIsAuth(true);
        } else {
          setUserError(new AccessDeniedException(response.message, response.code));
          setIsAuth(false);
        }
      })
      .catch(ex => {
        // and error has occurred and we cannot determine whether the token is valid or not
        // so we assume it to be invalid
        setUserError(new AccessDeniedException(null, null, ex));
        setIsAuth(false);
      });
  }

  // wait until we decide whether the user is authenticated or not
  if (isAuth !== null) {
    if (userError instanceof NetworkErrorException) {
      return <Error error={userError} />
    }
    return <AuthContext.Provider value={{ user, isLoggedIn, userError, refreshAuthUser }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

// helper hook that makes context data available
export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;