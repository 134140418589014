import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { getBeUrl, randomStringSync } from "helpers/utilHelper";
import { UncontrolledTooltip } from "reactstrap";
import {addAccessToken} from "../../helpers/tokenHelper";

const UserAvatar = props => {

  const { id, image, initials, size, className, icon, tooltip } = props;

  const [isImageError, setIsImageError] = useState(false);

  const imageUrl = getBeUrl(addAccessToken(`user/${id}/${image}`));

  const canShowImage = () => !!id && !!image && !isImageError;

  const uid = randomStringSync(2);

  return <div className={classnames("user-avatar", `user-avatar-${size}`, className)} id={"usravt" + uid}>
    {canShowImage() && <img src={imageUrl} className="user-avatar-img" onError={() => setIsImageError(true)} />}
    {!canShowImage() && <div className="user-avatar-noimg bg-primary bg-soft text-primary">
      {initials && <span className="user-avatar-initials">{initials}</span>}
      {!initials && <i className={classnames("user-avatar-icon", { [icon]: !!icon, "fas fa-user": !icon })}></i>}
    </div>}
    {!!tooltip && <UncontrolledTooltip placement="top" target={"usravt" + uid}>{tooltip}</UncontrolledTooltip>}
  </div>
}

UserAvatar.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  initials: PropTypes.string,
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
}

export default UserAvatar;