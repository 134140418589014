import React, { useState } from "react";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";
import SelfieInfo from "../Partial/SelfieInfo";
import Tips from "../Partial/Tips";
import classnames from "classnames";

import exampleFront from 'assets/images/instructions-examples/passport-example.png';
import exampleSelfie from 'assets/images/instructions-examples/selfie-example.png';
import PassportInfo from "../Partial/PassportInfo";

const PassportInstructions = ({ frontSrc, selfieSrc, startCapture, back, complete }) => {

  /********** STATE **********/
  const [isTipsVisible, setIsTipsVisible] = useState(false);

  /********** OTHER **********/
  const IMAGE_TYPE_PASSPORT = 'PASSPORT';
  const IMAGE_TYPE_SELFIE = 'SELFIE';

  const isFrontInfo = !frontSrc;
  const isSelfieInfo = frontSrc;

  return <React.Fragment>
    <div className="id-instructions">
      <CardBody>
        <div id="actionsRow" className="mb-4">
          <button className="back-btn" onClick={back}><i className="fas fa-arrow-left"></i></button>
          <button className={classnames('btn', 'tips-btn', { active: isTipsVisible })}
            onClick={() => setIsTipsVisible(!isTipsVisible)}>Important Tips</button>
        </div>

        {isTipsVisible && <Tips isFrontInfo={isFrontInfo} isSelfieInfo={isSelfieInfo} />}

        <div className="preview-row passport-preview">
          <div id="frontPreview" className="small-preview">
            <label className={!frontSrc || 'success'}>1. Scan your Passport</label>
            <div className="image-wrapper">
              <img src={frontSrc ? frontSrc : exampleFront}></img>
            </div>
            {!frontSrc ?
              <button className="btn btn-primary" onClick={() => startCapture(IMAGE_TYPE_PASSPORT)}>Scan</button>
              :
              <button className="btn btn-success" onClick={() => startCapture(IMAGE_TYPE_PASSPORT)}>Retake</button>
            }

          </div>
          <div id="selfiePreview" className="small-preview">
            <label className={!selfieSrc || 'success'}>2. Selfie</label>
            <div className="image-wrapper">
              <img src={selfieSrc ? selfieSrc : exampleSelfie}></img>
            </div>
            {!selfieSrc ?
              <button className={'btn btn-primary ' + (!frontSrc ? 'disabled' : '')} onClick={() => startCapture(IMAGE_TYPE_SELFIE)}>Scan</button>
              :
              <button className="btn btn-success" onClick={() => startCapture(IMAGE_TYPE_SELFIE)}>Retake</button>
            }
          </div>
        </div>
        {!frontSrc && <PassportInfo />}
        {frontSrc && <SelfieInfo />}
        <div className="submit-wrapper">
          <button className={'btn btn-primary w-100 ' + (!frontSrc || !selfieSrc ? 'disabled' : '')} onClick={complete}>Submit</button>
        </div>
      </CardBody>
    </div>
  </React.Fragment>
}


PassportInstructions.propTypes = {
  frontSrc: PropTypes.string,
  selfieSrc: PropTypes.string,
  startCapture: PropTypes.func,
  back: PropTypes.func,
  complete: PropTypes.func,
};

export default PassportInstructions;