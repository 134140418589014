import { get, post, put } from "./apiHelper";
import { flattenFilters } from "./utilHelper";

const getEntityList = (url, params) => get(url, { params: flattenFilters(params) });

// User

export const getAuthUser = () => get('/me');

export const getAuthUserSignatures = () => get('/me/signatures');

export const goIdle = () => put('/me/idle');

export const goActive = () => put('/me/active');

// Vid Request

export const getVidRequest = () => get(`/vid-request`);

export const uploadImages = (data, config = null) => post(`/vid-request/upload`, data, config);

export const retryVid = () => post(`/vid-request/retry`);

// Order

export const getOrder = () => get(`/order`);

export const getOrderInkSignContracts = () => get(`/order/docs/ink-sign`);

export const getOrderESignContracts = () => get(`/order/docs/e-sign`);

export const getOrderFinancialContracts = () => get(`/order/docs/finance`);

export const uploadContractPage = (data, pageId, id) => post(`/order-doc/${id}/page/${pageId}`, data);

export const uploadOrderDocPage = (data, id, page) => post(`/order-doc/${id}/page/${page}`, data);

export const createOrderMeeting = role => post(`/order/meet/${role}`);

export const acceptNotaryAppointment = () => put('/order/accept-notary-appointment');

export const declineNotaryAppointment = data => put('/order/decline-notary-appointment', data);

// Fedex and UPS

export const getOrderShipping = id => get(`/order/track/shipping`);

// OrderDoc

export const getOrderDoc = id => get(`/order-doc/${id}`);

export const signOrderDoc = (data, id) => put(`/order-doc/${id}/sign`, data);

export const getOrderDocSigners = orderDocId => get(`/order-doc/${orderDocId}/signers`);

export const getOrderDocSignings = orderDocId => get(`/order-doc/${orderDocId}/signings`);

// Verify User

export const generateCode = isResend => post(`/me/security-code/generate?resend=${isResend ? 1 : 0}`)

export const verifyCode = code => post(`/me/security-code/check`, { code })

// Messages

export const getMessages = (channelId, params) => getEntityList(`/messages/${channelId}`, params);

export const createMessage = (data, config) => post('/message', data, config);