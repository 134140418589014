import {
  GET_DOC_VIEWER_ORDER_DOC,
  GET_DOC_VIEWER_ORDER_DOC_OK,
  GET_ORDER_DOC_ERR,
  DO_ORDER_DOC_SINGLE_CLEANUP,
  SIGN_ORDER_DOC,
  SIGN_ORDER_DOC_OK,
  SIGN_ORDER_DOC_ERR,
  DO_ORDER_DOC_SIGNING_CLEANUP,
} from "./actionTypes";

/********** SINGLE **********/

export const getDocViewerOrderDoc = id => ({
  type: GET_DOC_VIEWER_ORDER_DOC,
  payload: { id },
});

export const getDocViewerOrderDocOk = (docResponse, signersResponse, signingsResponse, signaturesResponse) => ({
  type: GET_DOC_VIEWER_ORDER_DOC_OK,
  payload: { docResponse, signersResponse, signingsResponse, signaturesResponse },
});

export const getOrderDocErr = error => ({
  type: GET_ORDER_DOC_ERR,
  payload: { error },
});

export const doOrderDocSingleCleanup = () => ({
  type: DO_ORDER_DOC_SINGLE_CLEANUP,
});

/********** SIGNING **********/

export const signOrderDoc = (data, id) => ({
  type: SIGN_ORDER_DOC,
  payload: { data, id },
});

export const signOrderDocOk = response => ({
  type: SIGN_ORDER_DOC_OK,
  payload: { response },
});

export const signOrderDocErr = error => ({
  type: SIGN_ORDER_DOC_ERR,
  payload: { error },
});

export const doOrderDocSigningCleanup = () => ({
  type: DO_ORDER_DOC_SIGNING_CLEANUP,
});