import React from 'react';
import PropTypes from 'prop-types';
import DocViewerAddedField from './AddedField';
import DocViewerSignedField from './SignedField';
import { useDocViewer } from '../../Context';

const DocViewerDropLayer = props => {

  /**
   * Component props:
   * pageNum {int} the number of this page
   */
  const { pageNum } = props;

  /**
   * Context vars (see: Context.js)
   */
  const { addedFields, fieldIsSigned, fieldIsMine } = useDocViewer();

  return <div className="doc-viewer-drop-target">
    {/* loop through all added fields */}
    {addedFields.map((field, i) => {
      // check if the field is intended for this page
      if (field.page == pageNum) {
        return <React.Fragment key={field.id}>
          {/* if the field is permanently signed
          (a.k.a. signature is recorded in the db and is applied to the pdf doc) */}
          {fieldIsSigned(field) && <DocViewerSignedField field={field} />}
          {/* if the field is not signed or it is locally signed
          (a.k.a. signature is added to the field but is not yet recorded in the db nor it is applied to the pdf doc)
          and if the field is intended for the current signer */}
          {!fieldIsSigned(field) && fieldIsMine(field) && <DocViewerAddedField field={field} />}
        </React.Fragment>
      }
    })}
  </div>
}

DocViewerDropLayer.propTypes = {
  pageNum: PropTypes.number,
}

export default DocViewerDropLayer;