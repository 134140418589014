import React, { useEffect, useRef, useState } from 'react';
import { closeSocket, openSocket } from 'helpers/socketHelper';
import { getAccessToken, getVerificationToken } from 'helpers/tokenHelper';

export const SocketContext = React.createContext({ socket: null });

const SocketProvider = props => {

  // we use a ref to store the socket client as it won't be updated frequently
  const socket = useRef(openSocket(null, {
    // read the auth token from session storage
    // and send it with the connection request
    auth: { token: `${getAccessToken()}.${getVerificationToken()}` }
  }));

  // store the socket id in state
  // this will be updated every time the socket connects
  // we do not actually need the value
  // but we need a way to re-render the descendant components when the socket disconnects and reconnects
  // allowing them to re-subscribe to events with the new socket connection
  const [socketId, setSocketId] = useState(socket.current.id);

  // handler that helps emit an event
  const socketEmit = (event, data) => socket.current.emit(event, data);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('connect', () => {
        console.log('Socket connected. Id', socket.current.id);
        setSocketId(socket.current.id);
      });
      socket.current.on('connect_error', err => {
        console.error('Socket connection error:', err.data || err);
      });
      socket.current.on('error', err => {
        console.error('Socket error:', err);
      });
      socket.current.on('disconnect', reason => {
        console.error('Socket disconnected:', reason);
      });
    }
    // Remove all the listeners and close the socket upon unmount
    return () => closeSocket(socket.current);
  }, []);

  return <SocketContext.Provider value={{
    socket: socket.current,
    socketEmit: socketEmit,
  }} {...props} />
}

export const useSocket = () => React.useContext(SocketContext);

export default SocketProvider;