import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"
import IndexSaga from "./index/saga"
import OrderDocSaga from "./orderDoc/saga"
import MessageSaga from "./message/saga"

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(IndexSaga),
    fork(OrderDocSaga),
    fork(MessageSaga),
  ])
}
