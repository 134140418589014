import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import UserAvatar from "components/Shared/UserAvatar";
import { Link } from "react-router-dom";
import { getInitialsFromName, filteredChannels, filteredChannels2 } from "helpers/utilHelper";
import { formatTimestamp, getMessageDateFormat } from "helpers/dateHelper";
import { getOrder } from 'helpers/backendHelper';
import channels from "constants/channels";
import UserHeader from "./UserHeader";
import { useSelector } from "react-redux";
import Message from "model/message";
import { Col } from "reactstrap";

const Sidebar = ({ channelId, onOpenChannel }) => {

  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  // get redux state from the store
  const { messages } = useSelector(state => state.Message);

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      if (!res.order) {
        setOrderError(true);
        return;
      }
      setOrder(res.order)
    }).catch(err => {
      setOrderError(true);
    })
  }, []);

  const channelNotAvailable = (chId) => {
    switch (parseInt(chId)) {
      case Message.CHANNEL_SCHEDULER_CUSTOMER:
        // hide scheduler channel, if notary not required or scheduler not assigned
        if (order && (!order.isNotaryRequired || !order.schedulerId)) {
          return true;
        }
        break;
      case Message.CHANNEL_NOTARY_CUSTOMER:
        // hide notary channel, if notary not required or not assigned
        if (order && (!order.isNotaryRequired || !order.orderNotary)) {
          return true;
        }
        break;
    }
    return false;
  };

  return <Col sm={12} lg={3} className="chat-leftsidebar me-lg-4">
    <div className="py-2 border-bottom">
      <UserHeader />
    </div>
    <div className="chat-leftsidebar-nav">
      <div className="py-4">
        <h5 className="font-size-12 mb-3 fw-bolder">Conversations</h5>
        <ul className="list-unstyled chat-list" id="recent-list">
          <PerfectScrollbar>
            {Object.keys(channels).map(chId => {
              // hide channels not suited for order
              if (channelNotAvailable(chId)) {
                return;
              }
              const channel = channels[chId];
              const channelMessages = messages[chId];
              const lastMessage = channelMessages?.length ? channelMessages[channelMessages.length - 1] : ''
              return (
                <li
                  key={chId}
                  className={
                    channelId == chId
                      ? "active"
                      : ""
                  }
                >
                  <Link to="" onClick={onOpenChannel(chId)}>
                    <div className="d-flex">
                      <div className="align-self-center me-3">
                        <UserAvatar id={+chId} initials={getInitialsFromName(channel)} size="sm" />
                      </div>

                      <div className="flex-grow-1 overflow-hidden my-auto">
                        <h5 className="text-truncate font-size-14 mb-1 fw-bolder">
                          {channel}
                        </h5>
                        {lastMessage && <div className="text-truncate">
                          {lastMessage.contentType === Message.CONTENT_TYPE_TEXT && lastMessage.content}
                          {lastMessage.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex">
                            <i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span>
                          </div>}
                        </div>}
                      </div>
                      {lastMessage && <div className="font-size-11">
                        {formatTimestamp(lastMessage.createdTs, getMessageDateFormat(lastMessage.createdTs))}
                      </div>}
                    </div>
                  </Link>
                </li>
              )
            })}
          </PerfectScrollbar>
        </ul>
      </div>
    </div>
  </Col>
}

Sidebar.propTypes = {
  channelId: PropTypes.number,
  onOpenChannel: PropTypes.func.isRequired,
};

export default Sidebar;