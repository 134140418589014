import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { getOrder } from "helpers/backendHelper";
import NotFound from "pages/Error/NotFound";
import { useAuth } from "context/auth";
import OrderSigner from "model/orderSigner";
import DesktopMenu from "components/Shared/DesktopMenu";
import Footer from "components/Shared/Footer";
import classnames from "classnames";
import menuSteps from "constants/menuSteps";
import appointmentSetIcon from "assets/images/appointment-set-icon.svg";

const DesktopContent = () => {

  /********** STATE **********/
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  const { user: authUser } = useAuth();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      const order = res.order;
      if (!order || (!order.isInkSignRequired && !order.isEsignRequired) || authUser.type == OrderSigner.TYPE_DEALER) {
        setOrderError(true);
        return;
      }
      setOrder(order);
    }).catch(err => {
      setOrderError(true);
    });
  }, []);

  /********** OTHER **********/
  const inkSignInstructionsWithNotary = () => {
    return <React.Fragment>
      <div className="mb-3 sign-req">
        <i className="fas fa-phone me-3 fa-lg"></i>
        Wait to be contacted by the notary.
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={appointmentSetIcon} className="me-3" />
        Establish together a date and time for the signing.
      </div>
      <div className="mb-3 d-flex sign-req">
        <i className="fas fa-file-signature me-3 fa-lg"></i>
        Meet at the set time and date to sing the papers.
      </div>
      <div className="mb-1 d-flex sign-req">
        <i className="bx bx-file me-3 font-size-20"></i>
        Provide the signatures and forms of ID requested.
      </div>
    </React.Fragment>
  }

  const inkSignInstructionsWithoutNotary = () => {
    return <React.Fragment>
      <div className="mb-3 sign-req">
        <i className="fas fa-print me-3 fa-lg"></i>
        You can print all contracts
      </div>
      <div className="mb-3 d-flex sign-req">
        <i className="fas fa-file-signature me-3 fa-lg"></i>
        <div>You have all the contracts ready and signed</div>
      </div>
      <div className="mb-3 sign-req">
        <i className="fas fa-wifi me-3 fa-lg"></i>
        You have good internet connection
      </div>
      <div className="mb-1 sign-req">
        <i className="fas fa-sun me-3 fa-lg"></i>
        You are in a well-lit space
      </div>
    </React.Fragment>
  }

  return <React.Fragment>
    {order && <>
      <DesktopMenu title={"All contracts to be signed"} activeStep={menuSteps.SIGNATURES} />
      <div id="signatures" className="account-pages my-3 desktop">
        <Container>
          <Row className="justify-content-center">
            <Col md={9} lg={6} xl={5} className="sig-column">
              <Card className="overflow-hidden sig-card">
                <div className="sig-header">
                  <div id="opacity_overlay"></div>
                </div>
                <CardBody className="sig-desktop-body">
                  <div>
                    <h5 className="signatures-title">Continue with <strong>your signature.</strong> You can choose to start with <strong>electronic signature</strong> or go straight to <strong>ink paper signatures</strong>.</h5>
                  </div>
                  <div className="instr-parent">
                    <hr className="signatures-hr"></hr>
                    <div className="mb-3 mt-4 signatures-cat">For <strong>E-Signature</strong> make sure:</div>
                    <div className="mb-3 d-flex sign-req">
                      <i className="fas fa-file-signature me-3 fa-lg"></i>
                      <div>That all steps and signatures are completed</div>
                    </div>
                    <div className="mb-3 sign-req">
                      <i className="fas fa-wifi me-3 fa-lg"></i>
                      You have good internet connection
                    </div>
                    <hr className="signatures-hr"></hr>
                    <div className="mb-3 mt-4 signatures-cat">For <strong>Ink Paper signature</strong> make sure:</div>
                    {order.isNotaryRequired ? inkSignInstructionsWithNotary() : inkSignInstructionsWithoutNotary()}
                    {order.dealerInstructions && <>
                      <hr className="signatures-hr"></hr>
                      <div className="mb-3 mt-4 signatures-cat">Dealer special instructions:</div>
                      <div className="mb-3 d-flex sign-req">
                        <i className="fas fa-file-signature me-3 fa-lg"></i>
                        <div>{order.dealerInstructions}</div>
                      </div>
                    </>}
                  </div>
                  <Row className="btns-row">
                    <Col>
                      <Link to={route("e_signature")} className={classnames("btn btn-primary w-100", { disabled: !order.isEsignRequired })}>E-Signatures</Link>
                    </Col>
                    <Col>
                      <Link to={order.isNotaryRequired ? route("appointment") : route("ink_signature")} className={classnames("btn btn-primary w-100", { disabled: !order.isInkSignRequired })} id="inkBtn">Ink Paper Signature</Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
    }
    {orderError && <NotFound />}
  </React.Fragment>
}

export default DesktopContent;