import React from "react";
import iconSrc from "assets/images/appointment/pending.svg";

const AppointmentPending = () => {
  return <div className="text-center pb-4 appointment-result">
    <img className="pending-icon" src={iconSrc}></img>
    <h5 className="mt-4">Pending Appointment</h5>
    <div className="mt-2">A notary will be in contact to set a signing appointment within 24 hours after the documents are delivered to the notary. Thank you!</div>
  </div>
};

export default AppointmentPending;