export const autoCaptureHints = {
  /* MISNAP */
  MISNAP_HEAD_OUTSIDE: 'Place Face in Oval',
  MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
  MISNAP_AXIS_ANGLE: 'Hold Phone Upright',
  MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
  MISNAP_HEAD_TOO_FAR: 'Get Closer',
  MISNAP_STAY_STILL: 'Hold Still',
  MISNAP_SUCCESS: 'Success',
  MISNAP_STOP_SMILING: 'Stop Smiling',
  MISNAP_SMILE: 'Smile!',
  MISNAP_READY_POSE: 'Hold it There',
  NO_FACE_FOUND: 'Face not found',
  CV_NO_BARCODE_FOUND: 'Barcode Not Found',
  /* MITEK ERROR */
  MITEK_ERROR_GLARE: 'Reduce Glare',
  MITEK_ERROR_FOUR_CORNER: 'Document Not Found',
  MITEK_ERROR_TOO_DARK: 'Too Dark. Use good lighting',
  MITEK_ERROR_FOCUS: 'Hold Steady',
  MITEK_ERROR_MRZ_MISSING: 'Can\'t read every element on the photo page of your passport',
  MITEK_ERROR_TOO_FAR: 'Document Too Far',
  MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
  MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
  MITEK_ERROR_MIN_PADDING: 'Move further away',
  MITEK_ERROR_HORIZONTAL_FILL: 'Move closer',
  MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background',
  MITEK_ERROR_BUSY_BACKGROUND: 'Center document on a plain background',
  MITEK_ERROR_SKEW_ANGLE: 'Reduce angle',
  MITEK_ERROR_PERPENDICULAR_DOCUMENT: 'Change orientation of your document',
  MITEK_TOO_DARK: 'Too Dark. Use good lighting',
  MITEK_FOCUS: 'Hold Steady'
};

export const customMessages = {
  DL_FRONT: {
    firstMessage: 'Center document on a dark background',
    fourCornerMessage: 'Center document on a dark background'
  },
  PDF417_BARCODE: {
    firstMessage: 'Center document on a dark background',
    fourCornerMessage: 'Scanning for barcode'
  },
  PASSPORT: {
    firstMessage: 'Center photo page on a dark background',
    fourCornerMessage: 'Center photo page on a dark background'
  },
  SELFIE: {
    firstMessage: 'Place face in oval',
    fourCornerMessage: 'Face not found'
  },
  CHECK_FRONT: {
    firstMessage: 'Center Check Front on a dark background',
    fourCornerMessage: 'Scanning for Check Front'
  },
  CHECK_BACK: {
    firstMessage: 'Center Check Back on a dark background',
    fourCornerMessage: 'Scanning for Check Back'
  },
  DOCUMENT: {
    firstMessage: 'Center document on a dark background',
    fourCornerMessage: 'Center document on a dark background'
  }
};

export const previewRetakeHints = {
  DL_FRONT: 'Please Retake if there are any light reflections and try to reduce any glare over the ID.',
  PDF417_BARCODE: 'Please Retake if there are any light reflections and try to reduce any glare over the ID.',
  PASSPORT: 'Please Retake if there are any light reflections and try to reduce any glare over the ID.',
  SELFIE: 'Please Retake if the picture taken is blurry or grainy in appearance',
}

export const customMessagesHints = {
  DL_FRONT: 'Hold steady! The camera will autocapture the front of the ID',
  PDF417_BARCODE: 'Hold steady! The camera will autocapture the back of the ID',
  PASSPORT: 'Hold steady! The camera will autocapture your passport',
  SELFIE: 'Hold steady! The camera will autocapture your selfie'
};

export const CAMERA_PERMISSION_DENIED = "CAMERA_PERMISSION_DENIED";