import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Collapse } from "reactstrap";
import PropTypes from "prop-types";
import OrderDoc from 'model/orderDoc';
import { useSubscribeToOrderDoc } from 'hooks/socket';
import { getBeUrl } from 'helpers/utilHelper';
import { addAccessToken } from 'helpers/tokenHelper';
import { formats, formatTimestamp, timestamp } from 'helpers/dateHelper';

const ContractItem = ({ contract, scanPage, captures, getState, tabState, uploadHandler }) => {
  const [isActive, setIsActive] = useState(tabState);

  const fileInput = useRef([]);

  // start receiving updates about this particular order doc
  useSubscribeToOrderDoc(contract.id)

  useEffect(() => {
    setIsActive(tabState)
  }, []);

  const [reasonsExpanded, setReasonsExpanded] = useState(false);

  const imageCaptured = async e => {
    const pageId = parseInt($(e.target).attr('page-id'));
    uploadHandler(contract.id, pageId, e.target.files[0]);
  }

  // contract page list, inside accordion
  const getContractPages = () => {
    let content = [];
    for (let i = 1; i <= contract.numOfPages; i++) {
      let captureSrc = null;
      let pageStatus = null;
      // check if page has capture from api
      if (contract.pages && contract.pages[i] != null) {
        // we use timestamp() to avoid caching
        captureSrc = getBeUrl(addAccessToken(`order-doc/${contract.id}/ink-sign/page/${i}.png?${timestamp()}`));
        pageStatus = contract.pages[i].status;
      } else if (captures[contract.id] && captures[contract.id][i]) {
        // check if page has local capture
        captureSrc = captures[contract.id][i];
        pageStatus = OrderDoc.PAGE_STATUS_PENDING_REVIEW;
      }
      let page =
        <div className="contract-page" key={i}>
          <div className="contract-page-title">Page contract {i}</div>
          <div id="contract-page-capture" className={captureSrc ? 'has-capture' : ''}>
            <img src={captureSrc}></img>
            {pageStatus == OrderDoc.PAGE_STATUS_REJECTED && <button id="rejectedBadge" className="btn bg-danger text-light unclickable">Rejected</button>}
          </div>
          {!captureSrc && <>
            <button className="btn btn-danger page-status-btn" onClick={() => fileInput.current[i].click()} page-id={i}>
              Scan page
              <i className="mdi mdi-credit-card-scan-outline"></i>
            </button>
          </>
          }
          {captureSrc && pageStatus == OrderDoc.PAGE_STATUS_PENDING_REVIEW && <>
            <button className="btn bg-warning text-light page-status-btn unclickable" page-id={i}>
              Pending review
              <i className="bx bx-time-five"></i>
            </button>
          </>
          }
          {captureSrc && pageStatus == OrderDoc.PAGE_STATUS_REJECTED && <>
            <button className="btn btn-danger text-light page-status-btn" onClick={() => fileInput.current[i].click()} page-id={i}>
              Scan page again
              <i className="bx bx-x-circle"></i>
            </button>
          </>
          }
          {captureSrc && pageStatus == OrderDoc.PAGE_STATUS_ACCEPTED && <>
            <button className="btn bg-success text-light page-status-btn unclickable" page-id={i}>
              Accepted page
              <i className="bx bx-check-circle"></i>
            </button>
          </>
          }
          <input id={'fileInput-' + contract.id + '-' + i} type="file" accept="image/*" capture="environment" onChange={imageCaptured} className="d-none" ref={el => fileInput.current[i] = el} page-id={i} />
        </div>;
      content.push(page);
    }
    return content;
  }

  const scan = (e) => {
    let pageId = null;
    if ($(e.target).is('i')) {
      // when user clicks on the icon, the icon does not have 'page-id' attribute
      // so get the attribute from it's button parent
      pageId = parseInt($(e.target).parent().attr('page-id'))
    } else {
      pageId = parseInt($(e.target).attr('page-id'))
    }
    scanPage(contract.index, pageId)
  }

  /* Send this tab's closed/open state to parent */
  const getTabState = () => {
    setIsActive(!isActive)
    getState(!isActive, contract.id)
  }

  const getPagesRejectionReason = () => {
    let content = [];
    // get array of rejected pages
    const rejectedPages = Object.entries(contract.pages).reduce((list, entry) => {
      if (entry[1].status == OrderDoc.PAGE_STATUS_REJECTED) {
        list.push({ ...entry[1], num: entry[0] });
      }
      return list;
    }, []);
    // add reason for each rejected page
    rejectedPages.forEach((page, index) => {
      if (!reasonsExpanded && index > 0) {
        return 0;
      }
      const fullReason = page.rejectionReason;
      let shortReason = page.rejectionReason.substr(0, 120);
      if (shortReason != fullReason) {
        shortReason += ' ...';
      }
      let contentPage = <span key={index}>
        <div className="reason-label">Page {page.num} rejected reason</div>
        <div className="rejection-description">{reasonsExpanded ? fullReason : shortReason}</div>
      </span>;
      content.push(contentPage);
    })
    if (rejectedPages.length > 1 || (rejectedPages.length >= 1 && rejectedPages[0].rejectionReason.length > 120)) {
      // show 'see more' btn only if there are more than 1 reasons, or the one existing reason has more than 120 characters
      let btn = <button className="toggle-reasons-btn" onClick={() => setReasonsExpanded(!reasonsExpanded)} key="btn-key">
        {!reasonsExpanded && "see more"}
        {reasonsExpanded && "see less"}
      </button>
      content.push(btn);
    }
    return content;
  }

  // an order doc status is the min status of its pages, therefore we have to find out if any of the pages was rejected and display the correct label
  let isRejected = false;
  if (contract.pages) {
    const pagesArr = Object.values(contract.pages);
    isRejected = Boolean(pagesArr.find(page => page.status === OrderDoc.PAGE_STATUS_REJECTED));
  }

  return (
    <div className="accordion-item" key={contract.id} contract-id={contract.id}>
      <Row className={"contract-item " + (isActive ? 'open' : '')} onClick={contract.status >= OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE ? getTabState : null}>
        <Col className="p-0">
          <div className="contract-title">Contract number {contract.index + 1}</div>
          <div className="contract-desc">{contract.name}</div>
          <div className="badges-section">
            {contract.status < OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
              <span className="badge rounded-pill bg-secondary contract-badge status-name">Not ready<i className="bx bx-hourglass ms-1"></i></span>
              <span className="badge rounded-pill bg-secondary contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
              <span className="badge rounded-pill bg-primary contract-badge status-name">Ready to sign<i className="mdi mdi-credit-card-scan-outline ms-1"></i></span>
              <span className="badge rounded-pill bg-primary contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_PENDING_DEALER_REVIEW && <>
              <span className="badge rounded-pill bg-warning contract-badge status-name"><span>Pending review</span><i className="bx bx-time-five ms-1"></i></span>
              <span className="badge rounded-pill bg-warning contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_REJECTED && <>
              <span className="badge rounded-pill bg-danger contract-badge status-name">Rejected<i className="bx bx-x-circle ms-1"></i></span>
              <span className="badge rounded-pill bg-danger contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
            {contract.status == OrderDoc.STATUS_COMPLETE && <>
              <span className="badge rounded-pill bg-success contract-badge status-name">Accepted<i className="bx bx-check-circle ms-1"></i></span>
              <span className="badge rounded-pill bg-success contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
            </>
            }
          </div>
          <div className="font-size-11">
            {contract.signingInstructions}
          </div>
        </Col>
        {contract.status >= OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
          {!!contract.isUploaded &&
            <Col xs={1} className="p-0 accord-print">
              <a href={getBeUrl(addAccessToken(`order-doc/${contract.id}/pdf/download?${timestamp()}`))} onClick={(e) => e.stopPropagation()}>
                <i className="bx bxs-printer"></i>
              </a>
            </Col>
          }
          <Col xs={1} className="p-0 accord-arrow">
            <i className="fas fa-angle-down"></i>
          </Col>
        </>
        }
      </Row>
      <Collapse isOpen={isActive} className="accordion-collapse">
        <div className="accordion-body p-0">
          {isRejected &&
            <div className="rejected-reason-wrapper ink-sign">
              {getPagesRejectionReason()}
            </div>
          }
          <div className="contract-pages-wrapper">
            {getContractPages()}
            <hr className="contract-date-separator"></hr>
            <span className="contract-date-label">Contract date:</span>
            <span className="contract-date-value">{formatTimestamp(contract.createdTs, formats.CONTRACT_DATE)}</span>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

ContractItem.propTypes = {
  contract: PropTypes.object,
  scanPage: PropTypes.func,
  captures: PropTypes.array,
  getState: PropTypes.func,
  tabState: PropTypes.bool,
  uploadHandler: PropTypes.func,
};

export default ContractItem;