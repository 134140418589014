import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopContent from "./DesktopContent";
import MobileContent from "./MobileContent";
import AppointmentContext from "context/appointment";

const Index = () => {

  return <AppointmentContext>
    <MetaTitle>Set an appointment</MetaTitle>
    <BrowserView className="h-100">
      <DesktopContent />
    </BrowserView>
    <MobileView>
      <MobileContent />
    </MobileView>
  </AppointmentContext>
}

export default Index;
