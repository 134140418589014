import React from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import iconSrc from 'assets/images/desktop/pending_complete_icon.png';
import progressSrc from 'assets/images/desktop/progress-bar-complete.png';
import { Link } from "react-router-dom";
import { useNavigator } from "context/navigator";

const VidSuccess = () => {

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  return <React.Fragment>
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="vid-column mt-4">
          <Card>
            <CardBody>
              <div className="pt-2 text-center">
                <img className="mt-3" src={iconSrc}></img>
                <h5 className="mt-4 mb-4">ID Verification Successful</h5>
                <img src={progressSrc}></img>
                <div className="mt-4">Your documents have been reviewed.</div>
                <div>ID verification was successful.</div>
                <div>You may continue now.</div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <Link to={getNextRoute()} className="btn btn-primary">Continue</Link>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}

export default VidSuccess;
