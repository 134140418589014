import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopContent from "./DesktopContent/DesktopContent";
import MobileContent from "./MobileContent/MobileContent";

const Index = () => {

  return <React.Fragment>
    <MetaTitle>Preview Docs</MetaTitle>
    <BrowserView className="h-100">
      <DesktopContent />
    </BrowserView>
    <MobileView>
      <MobileContent />
    </MobileView>
  </React.Fragment>
}

export default Index;
