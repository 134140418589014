import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_ORDER,
} from "./actionTypes";

import {
  getOrderOk,
  getOrderErr,
} from "./actions";

import {
  getOrder, getOrderInkSignContracts,
} from "helpers/backendHelper";

function* onGetOrder({ payload: uuid }) {
  try {
    const response = yield call(getOrderInkSignContracts, uuid);
    yield put(getOrderOk(response));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_ORDER, onGetOrder);
}

export default userSaga;
