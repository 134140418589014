import React, { useState } from "react";
import { Button } from "reactstrap";
import { acceptNotaryAppointment, declineNotaryAppointment } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";
import { formatTimestampTz, formats } from "helpers/dateHelper";
import { useAppointment } from "context/appointment";

const ViewAppointment = () => {

  const [isBusy, setIsBusy] = useState(false);

  const { order, refreshOrder, setIsDeclining } = useAppointment();

  const acceptMeeting = () => {
    setIsBusy(true);
    acceptNotaryAppointment().then(res => {
      refreshOrder();
    }).catch(err => {
      showError("Unable to accept the appointment");
    }).finally(() => {
      setIsBusy(false);
    });
  }

  const declineMeeting = () => {
    setIsBusy(true);
    declineNotaryAppointment().then(res => {
      setIsDeclining(false);
      refreshOrder();
    }).catch(err => {
      showError("Unable to decline the appointment")
    }).finally(() => {
      setIsBusy(false);
    });
  }

  return <React.Fragment>
    <div className="appointment-card-title">Set an appointment</div>
    <div className="appointment-field mt-3">
      <label className="form-label">Meeting address:</label>
      <input className="form-control" type="text" readOnly value={order.orderNotary.meetingAddress || '--'} />
    </div>
    <div className="appointment-field mt-3">
      <label className="form-label">Meeting date:</label>
      <input className="form-control" type="text" readOnly value={order.orderNotary.meetingTs ? formatTimestampTz(order.orderNotary.meetingTs, formats.APPOINTMENT_DATETIME, order.orderNotary.notaryTimezone) : '--'} />
    </div>
    <div className="appointment-buttons mt-4 d-flex justify-content-center">
      <Button color="primary" className="flex-fill me-2" outline onClick={declineMeeting} disabled={isBusy}>Decline</Button>
      <Button color="primary" className="flex-fill ms-2" onClick={acceptMeeting} disabled={isBusy}>Accept</Button>
    </div>
  </React.Fragment>
}

export default ViewAppointment;
