import axios from "axios"
import config from "config";
import httpCode from "constants/httpCode";
import {
  AccessDeniedException,
  NotFoundException,
  BadRequestException,
  ValidationException,
  AuthException,
  ServerErrorException,
  NetworkErrorException,
} from "./errorHelper";
import { getAccessToken, getVerificationToken } from "./tokenHelper";

const axiosApi = axios.create({
  baseURL: config.API_BE_URL,
});

axiosApi.interceptors.request.use(config => {
  const authToken = getAccessToken();
  const verificationToken = getVerificationToken();
  config.headers.Authorization = `Bearer ${authToken}.${verificationToken}`;
  return config;
});

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (!!error.response) {
      switch (error.response.status) {
        case httpCode.unauthorized:
          error = new AuthException(null, error.response.data.code);
          break;
        case httpCode.forbidden:
          error = new AccessDeniedException(null, error.response.data.code);
          break;
        case httpCode.notFound:
          error = new NotFoundException(null, error.response.data.code);
          break;
        case httpCode.badRequest:
          if (error.response.data.fields) {
            error = new ValidationException(null, error.response.data.fields);
          } else {
            error = new BadRequestException(null, error.response.data.code);
          }
          break;
        case httpCode.internalServerError:
          error = new ServerErrorException(null, error.response.data.code);
          break;
      }
    } else if (!!error.request) {
      // we have a request but not a response
      // so this is most likely a network problem
      error = new NetworkErrorException();
    }
    return Promise.reject(error);
  }
);

export const get = (url, config = {}) => axiosApi
  .get(url, config)
  .then(response => response.data);

export const post = (url, data, config = {}) => axiosApi
  .post(url, data, config)
  .then(response => response.data);

export const put = (url, data, config = {}) => axiosApi
  .put(url, data, config)
  .then(response => response.data);

export const del = (url, config = {}) => axiosApi
  .delete(url, config)
  .then(response => response.data);