import React from "react";
import PropTypes from "prop-types";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = (props) => {

  Slider.propTypes = {
    items: PropTypes.array,
  };

  return <React.Fragment>
    <div id="carouselParent" className="mt-2">
      <OwlCarousel className="m-0 owl-theme" id="carouselContainer" items="2" autoplay="true" dotsEach="1" autoplayTimeout="3000" loop="true" autoplaySpeed="1000">
        {
          props.items.map((item) =>
            <div className="carouselItem me-2 ms-2" key={item.id}>
              <div className="position-relative">
                <img className="itemImg" src={item.imgUrl} />
                <div className="itemStop"><i className="fas fa-times"></i></div>
              </div>
              <div className="itemDescription">{item.description}</div>
            </div>
          )
        }
      </OwlCarousel>
    </div>
  </React.Fragment>
}

export default Slider;
