export default class OrderDoc {

  static STATUS_PENDING_INITIAL_PROCESSING = 0;
  static STATUS_PENDING_SIGNATURES_PLACEMENT = 1;
  static STATUS_PENDING_CUSTOMER_SIGNATURE = 2;
  static STATUS_PENDING_DEALER_REVIEW = 3;
  static STATUS_REJECTED = 4;
  static STATUS_PENDING_DEALER_SIGNATURE = 5;
  static STATUS_PENDING_FINAL_PROCESSING = 6;
  static STATUS_COMPLETE = 7;
  static STATUS_INITIAL_PROCESSING_ERROR = -1;
  static STATUS_FINAL_PROCESSING_ERROR = -2;

  static PAGE_STATUS_PENDING_REVIEW = 0;
  static PAGE_STATUS_REJECTED = 1;
  static PAGE_STATUS_ACCEPTED = 2;

  static FIELD_TYPE_SIGNATURE = 1;
  static FIELD_TYPE_INITIALS = 2;
  static FIELD_TYPE_NAME = 3;

  static getFieldOrientation = fieldType => {
    switch (fieldType) {
      default:
        return 'horizontal';
    }
  }
}