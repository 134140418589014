export default class Message {

  static CHANNEL_DEALER_CUSTOMER = 1;
  static CHANNEL_SUPPORT_DEALER = 2;
  static CHANNEL_SUPPORT_DEALER_CUSTOMER = 3;
  static CHANNEL_SCHEDULER_CUSTOMER = 4;
  static CHANNEL_SCHEDULER_NOTARY = 5;
  static CHANNEL_NOTARY_CUSTOMER = 6;

  static CONTENT_TYPE_TEXT = 1
  static CONTENT_TYPE_IMAGE = 2

  static getChannelMap() {
    return {
      [this.CHANNEL_DEALER_CUSTOMER]: 'Dealer - Customer',
      [this.CHANNEL_SUPPORT_DEALER]: 'Tech Support - Dealer',
      [this.CHANNEL_SUPPORT_DEALER_CUSTOMER]: 'Tech Support - Dealer - Customer',
      [this.CHANNEL_SCHEDULER_CUSTOMER]: 'Scheduler - Customer',
      [this.CHANNEL_SCHEDULER_NOTARY]: 'Notary - Scheduler',
      [this.CHANNEL_NOTARY_CUSTOMER]: 'Notary - Customer',
    };
  }

  static CUSTOMER_SCHEDULER_TEMPLATES = [
    "My address listed on the confirmation is incorrect. My correct address is:",
    "The listed vehicle information is incorrect. The correct information is:",
    "The dealership has not responded to me, please contact the dealership.",
    "When will the notary contact me?"
  ]

  static CUSTOMER_NOTARY_TEMPLATES = [
    "I would like to set an appointment, please contact me.",
    "I need to reschedule my appointment, please contact me."
  ]

  static CUSTOMER_DEALER_TEMPLATES = [
    "Have my documents been sent to the notary?",
    "I have questions about the documents. Please contact me.",
    "The listed vehicle information is incorrect. The correct information is:"
  ]
}