import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import DocViewerSignPad from './SignPad';
import DocViewerSignText from './SignText';
import OrderDoc from 'model/orderDoc';

const DocViewerSignModal = props => {

  const fullscreenWrapperRef = useRef();

  /**
   * Component props:
   * field {object} the signature field
   * isOpen {boolean} whether the modal is supposed to be open
   * closeHandler {func} callback to call in order to dismiss the modal
   */
  const { field, isOpen, closeHandler } = props;

  /**
   * Checks whether this field accepts a drawing signature
   * @returns {boolean}
   */
  const hasDrawTab = () => [
    OrderDoc.FIELD_TYPE_SIGNATURE,
  ].includes(field.type);

  /**
   * Checks whether this field accepts a typing signature
   * @returns {boolean}
   */
  const hasTypeTab = () => [
    OrderDoc.FIELD_TYPE_SIGNATURE,
    OrderDoc.FIELD_TYPE_INITIALS,
    OrderDoc.FIELD_TYPE_NAME,
  ].includes(field.type);

  /**
   * Returns the number of the tab that is supposed to be active by default
   * @returns {int}
   */
  const getDefaultTab = () => {
    if (hasDrawTab()) {
      return 1;
    }
    if (hasTypeTab()) {
      return 2;
    }
    return 0;
  }

  /**
   * The number of the active tab
   * Tabs will be activated or deactivated based on this value
   */
  const [activeTab, setActiveTab] = useState(getDefaultTab());

  /**
   * Sets the specified tab as active
   * @param {int} tabNum
   */
  const activateTab = tabNum => {
    if (activeTab !== tabNum) {
      setActiveTab(tabNum);
    }
  }

  useEffect(() => {
    /**
     * Fullscreen wrapper:
     * we need to get the element of the fullscreen wrapper because the
     * bootstrap modal by default is appended to the document body
     * when the wrapper is in fullscreen, the modal gets lost behind it
     * so we need the wrapper element to tell the modal where to render using
     * container props
     */
    fullscreenWrapperRef.current = document.getElementById("fullscreen-wrapper")
  }, [])

  return <React.Fragment>
    <Modal size="lg" isOpen={isOpen} toggle={closeHandler} className="doc-viewer-sign-modal" container={fullscreenWrapperRef.current}>
      <div className="modal-header pb-0">
        <Nav tabs className="nav-tabs-custom nav-justified">
          {hasDrawTab() && <NavItem>
            <NavLink className={classnames({ active: activeTab === 1 })} onClick={() => activateTab(1)}>
              <span className="d-block">Draw</span>
            </NavLink>
          </NavItem>}
          {hasTypeTab() && <NavItem>
            <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => activateTab(2)}>
              <span className="d-block">Type</span>
            </NavLink>
          </NavItem>}
        </Nav>
        <button type="button" onClick={closeHandler} className="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <TabContent activeTab={activeTab}>
          {hasDrawTab() && <TabPane tabId={1}>
            <DocViewerSignPad field={field} closeHandler={closeHandler} isActive={activeTab === 1} />
          </TabPane>}
          {hasTypeTab() && <TabPane tabId={2}>
            <DocViewerSignText field={field} closeHandler={closeHandler} isActive={activeTab === 2} />
          </TabPane>}
        </TabContent>
      </div>
    </Modal>
  </React.Fragment>
}

DocViewerSignModal.propTypes = {
  field: PropTypes.object,
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
}

export default DocViewerSignModal;
