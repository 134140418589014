import React from "react";
import PropTypes from "prop-types";

const CaptureTypeDialog = ({ retryAutocapture, switchToManual, exitDialog }) => {

  CaptureTypeDialog.propTypes = {
    retryAutocapture: PropTypes.func,
    switchToManual: PropTypes.func,
    exitDialog: PropTypes.func
  };

  return <React.Fragment>
    <div className="capture-type-dialog-wrapper">
      <button id="closeDialogBtn" onClick={exitDialog}>
        <i className="fas fa-times"></i>
      </button>
      <div className="capture-type-dialog-content">
        <div className="mt-3 mb-3 info">Autocapture failed to produce a viable image.</div>
        <div className="mb-3 info">
          Do you want to:
        </div>
        <div className="btns-wrapper mt-4">
          <button className="btn btn-primary" onClick={retryAutocapture}>Retry autocapture</button>
          <button className="btn btn-primary" onClick={switchToManual}>Switch to manual capture</button>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default CaptureTypeDialog;
