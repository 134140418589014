import React from "react";
import PropTypes from "prop-types";

const ModalHeader = ({ onCloseChat }) => {
  return <div className="modal-header justify-content-between w-100">
    <h4 className="font-size-24 fw-bolder text-primary">Mavsign Chat</h4>
    <div className="close-btn" role="button" onClick={onCloseChat}>
      <i className="mdi mdi-apple-keyboard-control text-primary" />
    </div>
  </div>
}

ModalHeader.propTypes = {
  onCloseChat: PropTypes.func.isRequired,
}

export default ModalHeader;