import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDocViewer } from '../../Context';
import DocViewerSignModal from '../../Signature/SignModal';
import DocViewerFieldSignature from './Field/Signature';
import OrderDoc from 'model/orderDoc';

const DocViewerAddedField = props => {

  /**
   * Component props:
   * field {object} the signature field
   */
  const { field } = props;

  /**
   * Fields themselves do not carry an orientation property
   * So we need to read and attach that again from the field config
   */
  field.orientation = OrderDoc.getFieldOrientation(field.type);

  /**
   * Context vars (see: Context.js)
   */
  const { getFieldIcon, fieldIsSigned, fieldIsMine, capturedSignatures, updateField } = useDocViewer();

  /**
   * Bool flag that turns the signature modal on and off
   * Modal will be visible or not based on this value
   */
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Opens the signature modal
   * @returns {void}
   */
  const openModal = () => setIsModalOpen(true);

  /**
   * Closes the signature modal
   * @returns {void}
   */
  const closeModal = () => setIsModalOpen(false);

  /**
   * Checks if the current user is allowed to sign the field
   * For exmaple, if the field is not already permanently signed
   * Or the field is not intended for another signer
   */
  const userCanSign = () => !fieldIsSigned(field) && fieldIsMine(field);

  /**
   * Checks if the user can sign
   * Applies an already captured signature is available
   * Opens the capture modal if not
   */
  const onFieldClicked = () => {
    // do nothing if for some reason the user cannot sign
    // ex. the field is already permanently signed or the field is intended for another signer
    if (!userCanSign()) {
      return;
    }
    // if the field already has a signature
    // open the modal to allow editing of the signature
    if (!!field.signature) {
      openModal();
      return;
    }
    // check if there is an already captured signature for this field type
    const capturedSignature = capturedSignatures[field.type];
    if (!!capturedSignature) {
      // we have a captured signature so apply it
      updateField({ ...field, signature: capturedSignature });
    } else {
      // we do NOT have a captured signature so prompt the user to add it
      openModal();
    }
  }

  /**
   * The icon for this field type
   * @type {ReactComponent}
   */
  const IconComponent = getFieldIcon(field.type);

  /**
   * The style of the field, mainly establishing position
   * We want to use percentage position so it works with every zoom level
   */
  const fieldStyle = {
    position: 'absolute',
    top: `${field.topPc}%`,
    left: `${field.leftPc}%`,
    width: `${field.widthPc}%`,
    height: `${field.heightPc}%`,
  };

  /**
   * Field css classed
   */
  const cssClass = classnames(
    'doc-viewer-field',
    'doc-viewer-field-added',
    `signer-type-${field.signer.type}`,
    `signer-position-${field.signer.position}`,
    'user-select-none',
    {
      'is-signable': userCanSign(),
      'has-signature': !!field.signature,
    }
  );

  return <React.Fragment>
    <div className={cssClass} style={fieldStyle} onClick={onFieldClicked} id={'doc-viewer-field-' + field.id}>
      <div className="doc-viewer-field-body">
        <IconComponent className="doc-viewer-field-icon" />
        <div className="doc-viewer-field-content">
          {!!field.signature && <DocViewerFieldSignature field={field} />}
          {!field.signature && <div className="doc-viewer-field-label">
            <div className="doc-viewer-field-text">{field.text}</div>
          </div>}
        </div>
      </div>
    </div>
    <DocViewerSignModal field={field} isOpen={isModalOpen} closeHandler={closeModal} />
  </React.Fragment>
};

DocViewerAddedField.propTypes = {
  field: PropTypes.object,
}

export default DocViewerAddedField;