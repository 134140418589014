import Preloader from 'components/Shared/Preloader';
import { getOrder } from 'helpers/backendHelper';
import { route } from 'helpers/routeHelper';
import { isUserVerifiedLocal } from 'helpers/tokenHelper';
import useFirstRender from 'hooks/firstRender';
import Order from 'model/order';
import React, { useEffect, useState } from 'react';
import { useAuth } from './auth';

const NavigatorContext = React.createContext();

const Navigator = props => {

  const { user: authUser } = useAuth();

  const { isFirstRender } = useFirstRender();

  const ROUTE_HOME = route('home');
  const ROUTE_MESSAGES = route('messages');
  const ROUTE_VID = route('vid');
  const ROUTE_APPOINTMENT = route('appointment');
  const ROUTE_FINANCIAL = route('finance');
  const ROUTE_SIGNATURES = route('signatures');
  const ROUTE_FINISH = route('finish');

  // ordered list of routes the user can navigate to
  const [routes, setRoutes] = useState(null);

  // we are able to navigate when routes are loaded from api
  const navIsReady = () => !!routes;

  // object that keeps track of routes order
  const routesOrder = {
    [ROUTE_HOME]: 0,
    [ROUTE_MESSAGES]: 0, // messages and home hold the same value because they both route to Home (the chat is render in a modal)
    [ROUTE_VID]: 1,
    [ROUTE_FINANCIAL]: 2,
    [ROUTE_SIGNATURES]: 3,
    [ROUTE_APPOINTMENT]: 3, // signatures and appointment hold the same value because they can be done in parallel
    [ROUTE_FINISH]: 4,
  }

  // runs whenever the `authUser` changes, including at component mount
  useEffect(() => {
    if (authUser && authUser.isVerified) {
      // don't fetch order until we are allowed to make backend calls
      fetchOrder();
    }
  }, [authUser]);

  const fetchOrder = () => {
    getOrder().then(res => {
      const order = res.order;
      if (!order) {
        return;
      }
      // determine accessible routes for this order
      setRoutes([
        {
          route: ROUTE_HOME,
          status: true,
        },
        {
          route: ROUTE_VID,
          status: order.isVidRequired,
        },
        {
          route: ROUTE_FINANCIAL,
          status: order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD,
        },
        {
          route: ROUTE_SIGNATURES,
          status: order.isEsignRequired || order.isInkSignRequired,
        },
        {
          route: ROUTE_APPOINTMENT,
          status: order.isNotaryRequired, // appointment feature is only available for order with notary
        },
        {
          route: ROUTE_FINISH,
          status: true,
        }
      ])
    })
  }

  // helper method that determines the next available route
  const getNextRoute = () => {
    const currentRoute = window.location.pathname;
    return findNextAvailableRoute(currentRoute);
  }

  // iterates the route array and finds the next route with status = true,
  // and with index greater than current route's index
  const findNextAvailableRoute = (currentRoute) => {
    let nextAvailableRoute = '';
    routes.some((elem, index) => {
      if (index > routesOrder[currentRoute] && elem.status) {
        nextAvailableRoute = elem.route;
        // persist query params from /messages
        if (currentRoute = route('messages') && window.location.search) {
          nextAvailableRoute += window.location.search;
        }
        // break loop as soon as we find the first available route
        return true;
      }
    })
    return nextAvailableRoute;
  }

  // when refreshing and still verified, show preloader until routes are loaded
  if (isFirstRender && !navIsReady() && isUserVerifiedLocal()) {
    return <Preloader />
  }

  return <NavigatorContext.Provider value={{ getNextRoute, navIsReady }} {...props} />
}

// helper hook that makes context data available
export const useNavigator = () => React.useContext(NavigatorContext);

export default Navigator;