import React from 'react';
import { useDocViewer } from './Context';
import Confirmation from '../Confirmation';

const DocViewerCloseConf = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { isCloseConfVisible, setIsCloseConfVisible, closeViewer } = useDocViewer();

  return <React.Fragment>
    {isCloseConfVisible && <Confirmation
      confirmBtnText="Discard and close"
      confirmBtnBsStyle="danger"
      cancelBtnText="Return to signing"
      cancelBtnBsStyle="secondary"
      onConfirm={() => {
        setIsCloseConfVisible(false);
        closeViewer();
      }}
      onCancel={() => setIsCloseConfVisible(false)}>
      You have uncommitted signatures that will be lost! Do you really want to close?
    </Confirmation>}
  </React.Fragment>

}

export default DocViewerCloseConf;