import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { useAuth } from "context/auth";
import Error from 'pages/Error';
import { route } from "helpers/routeHelper";
import { isUserVerifiedLocal } from "helpers/tokenHelper";
import { setDirectRoute } from "store/actions";
import { useDispatch } from "react-redux";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isVerifRequired,
}) => {

  const { isLoggedIn, userError } = useAuth();

  const navigate = useNavigate();

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // remove 's' queryParam from url
  const manageDirectRoute = () => {
    let params = new URLSearchParams(window.location.search);
    params.delete('s');
    dispatch(setDirectRoute(`${window.location.pathname}?${params}`));
  }
  if (!isLoggedIn()) {
    // user is not authenticated
    return <Error error={userError} />
  }
  if (isLoggedIn() && !isUserVerifiedLocal() && isVerifRequired) {
    // when url contains specific route, for user to access after verification
    manageDirectRoute();
    // user is authenticated but not verified and the current route requires the user to be verified
    // so redirect to the verification screen
    return navigate(route('welcome'));
  }
  // user is free to access the page
  return <Layout>
    <Component />
  </Layout>
}

Authmiddleware.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  isVerifRequired: PropTypes.bool,
}

export default Authmiddleware;
