import React from "react";

const HowToUse = () => {

  return <React.Fragment>
    <div className="how-to-use-row">
      <div className="title">How to use Ink Paper Signature</div>
      <ul className="list">
        <li><span className="bold">Before you begin the process make sure you have received instructions from your dealership. For certain states it may be required to sign on the
          original <u>documents sent to you by mail from the dealership.</u> If your dealership did not communicate such instructions to you,
          then PRINT them out by taping on <i className="bx bxs-printer print-icon"></i> the Print icon!</span></li>
        <li>Once you have all the documents in front of you select one of the contracts below and follow the steps on the screen
          by taking a photo of each individual page with your wet signature placed where indicated in the document. </li>
        <li>If you have any questions or concerns, please reach out to your contact at the dealership by clicking on the <i className="bx bxs-message mt-2 msg-icon"></i> top right icon before you begin the process of signing.</li>
      </ul>
    </div>
  </React.Fragment>
}

export default HowToUse;
