// socket events
// they need to match the back-end values
export default {
  subscribeToVidReq: 'subscribeToVidReq',
  unsubscribeFromVidReq: 'unsubscribeFromVidReq',
  takeOverVidReq: 'takeOverVidReq',
  vidReqTakenOver: 'vidReqTakenOver',
  vidProcessStarted: 'vidProcessStarted',
  vidProcessComplete: 'vidProcessComplete',
  vidSuccess: 'vidSuccess',
  vidFail: 'vidFail',
  vidMitekApiFail: 'vidMitekApiFail',
  subscribeToOrderDoc: 'subscribeToOrderDoc',
  unsubscribeFromOrderDoc: 'unsubscribeFromOrderDoc',
  orderDocChanged: 'orderDocChanged',
  subscribeToOrderMessages: 'subscribeToOrderMessages',
  unsubscribeFromOrderMessages: 'unsubscribeFromOrderMessages',
  messageReceived: 'messageReceived',
  subscribeToOrderSigner: 'subscribeToOrderSigner',
  unsubscribeFromOrderSigner: 'unsubscribeFromOrderSigner',
  orderSignerActivityStatusChanged: 'orderSignerActivityStatusChanged',
}