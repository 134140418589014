import { takeEvery, put, call } from "redux-saga/effects";

import {
  CREATE_MESSAGE,
  GET_MESSAGES,
} from "./actionTypes";

import {
  createMessage,
  getMessages,
} from "helpers/backendHelper";

import EventEmitter from "helpers/eventsHelper";

import { createMessageErr, createMessageOk, getMessagesErr, getMessagesOk } from "./actions";

function* onGetMessages({ payload: { channelId, params } }) {
  try {
    const response = yield call(getMessages, channelId, params);
    if (response.name) {
      yield put(getMessagesErr(response))
    } else {
      yield put(getMessagesOk(response, channelId))
    }
  } catch (error) {
    yield put(getMessagesErr(error))
  }
}

function* onCreateMessage({ payload: { data } }) {
  let config = null;
  if (data instanceof FormData) {
    config = {
      onUploadProgress: ev => EventEmitter.emit('message.uploadProgress', { ev, _fileUid: data.get('uid') })
    };
  }
  try {
    yield call(createMessage, data, config);
    yield put(createMessageOk());
  } catch (error) {
    yield put(createMessageErr(error));
  }
}

function* messagesSaga() {
  yield takeEvery(GET_MESSAGES, onGetMessages);
  yield takeEvery(CREATE_MESSAGE, onCreateMessage);
}

export default messagesSaga;
