import { useAuth } from "context/auth";
import { useNavigator } from "context/navigator";
import { getOrder } from "helpers/backendHelper";
import { route } from "helpers/routeHelper";
import OrderSigner from "model/orderSigner";
import NotFound from "pages/Error/NotFound";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  const { user: authUser } = useAuth();

  // context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  const [orderError, setOrderError] = useState(false);

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      const order = res.order;
      if (!order) {
        setOrderError(true);
        return;
      }
      if (authUser.type == OrderSigner.TYPE_DEALER) {
        // dealer user only performs electronic signing
        // redirect dealer to e-sign page
        navigate(route('e_signature'));
        return;
      }
      if (order.isInkSignRequired && order.isNotaryRequired && !order.isEsignRequired && !!order.orderNotary) {
        // for orders that have only ink-sign with notary required
        // redirect customer to appointment page
        let nextRoute = route('appointment');
        // persist queryParams for messages
        if (window.location.search) {
          nextRoute += window.location.search
        }
        navigate(nextRoute);
        return;
      }
      navigate(getNextRoute());
    }).catch(err => {
      setOrderError(true);
    })
  }, []);

  return <>
    {orderError && <NotFound />}
  </>
}

export default Home;
