/********** SINGLE **********/

export const GET_DOC_VIEWER_ORDER_DOC = 'GET_DOC_VIEWER_ORDER_DOC'
export const GET_DOC_VIEWER_ORDER_DOC_OK = 'GET_DOC_VIEWER_ORDER_DOC_OK'
export const GET_ORDER_DOC_ERR = 'GET_ORDER_DOC_ERR'
export const DO_ORDER_DOC_SINGLE_CLEANUP = 'DO_ORDER_DOC_SINGLE_CLEANUP'

/********** SIGNING **********/

export const SIGN_ORDER_DOC = 'SIGN_ORDER_DOC'
export const SIGN_ORDER_DOC_OK = 'SIGN_ORDER_DOC_OK'
export const SIGN_ORDER_DOC_ERR = 'SIGN_ORDER_DOC_ERR'
export const DO_ORDER_DOC_SIGNING_CLEANUP = 'DO_ORDER_DOC_SIGNING_CLEANUP'