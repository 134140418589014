import React from "react";
import PropTypes from "prop-types";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from 'reactstrap';

const InfoOffcanvas = ({ show, hide }) => {

  return <React.Fragment>
    <Offcanvas
      isOpen={show}
      direction="bottom"
      toggle={hide}>
      <OffcanvasHeader toggle={hide}>
      </OffcanvasHeader>
      <OffcanvasBody>
        <div className="how-to-use-row">
          <div className="title">How to use E-Signatures</div>
          <ul className="list">
            <li>Before you begin the e-signing process please review the contract carefully to make sure all the terms of the agreement are clear.</li>
            <li>Once ready, click Sign Contract.</li>
          </ul>
        </div>
      </OffcanvasBody>
    </Offcanvas>

  </React.Fragment>
}

InfoOffcanvas.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
};

export default InfoOffcanvas;
