import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DocViewerPage from './Content/Page';
import { useDocViewer } from '../Context';

const DocViewerContent = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { orderDoc, activePageNum, setActivePageNum, numPageScrollCommands, activeFieldId, numFieldScrollCommands,
    zoomLevel, naturalPageListWidth, getZoomScaleFactor, getZoomedPageListWidth, getZoomedSize,
    setFitWidthZoomLevel, setFitHeightZoomLevel, numSizeChanges } = useDocViewer();

  /**
   * Ref to the content holder dom element
   */
  const contentRef = useRef();

  /**
   * The number of pages that have finished loading
   * We use this to postpone certain calculations untill the layout is fully painted
   */
  const [numLoadedPages, setNumLoadedPages] = useState(0);

  /**
   * This effect runs once on component mount
   * Adds/removes the intersection observer that monitors pages entering/exiting view
   */
  useEffect(() => {
    const observer = initIntersectionObserver();
    return () => observer.disconnect();
  }, []);

  /**
   * This effect runs after the active page changes by other means than scrolling
   * Ex. when the user clicks on the left bar thumbnails or types into the header box
   */
  useEffect(() => {
    scrollToActivePage();
  }, [numPageScrollCommands]);

  /**
   * This effect runs after the active field changes as the user signs
   */
  useEffect(() => {
    scrollToActiveField();
  }, [numFieldScrollCommands]);

  /**
   * This effect runs whenever the number of loaded pages changes (initial render)
   * Or whenever there is a change that affects the page size
   * We use it to re/calculate the fit-width and fit-height zoom levels for the new page size
   */
  useEffect(() => {
    // abort if not all pages have loaded
    if (!areAllPagesReady()) {
      return;
    }
    // get content width excluding scrollbars
    const availableWidth = contentRef.current.clientWidth;
    // calculate the required zoom level so that the page width will fit perfectly in the container
    const fitWidthZoomLevel = availableWidth / naturalPageListWidth * 100;
    // save the value in the context
    setFitWidthZoomLevel(fitWidthZoomLevel);

    // get content height including scrollbars
    // this will give the wrong value in 'useLayoutEffect' so we have to use 'useEffect'
    const availableHeight = contentRef.current.offsetHeight;
    // get the first page so we can calculate its height
    const firstPage = contentRef.current.querySelector('.doc-viewer-page-content');
    // initially the page list does not have a set size
    // therefore it is sized automatically by the browser to fit the container width
    // which means that the current height of the pages is not the 'natural' height but a resized one
    // so let's see how much it has been scaled
    // calculate the current scale factor based on the current width and the natural width (which we both know)
    const scaleFactor = firstPage.offsetWidth / naturalPageListWidth;
    // now that we have the scale factor let's calculate the 'natural' height of the page based on the scaled one
    const naturalPageHeight = firstPage.offsetHeight / scaleFactor;
    // finally let's calculate the required zoom level so that the page height will fit perfectly in the container
    const fitHeightZoomLevel = availableHeight / naturalPageHeight * 100;
    // save the value in the context
    setFitHeightZoomLevel(fitHeightZoomLevel);
  }, [numLoadedPages, numSizeChanges]);

  /**
   * This effect runs whenever the zoom level changes
   * We use it to actually zoom the content
   * By applying a scale transformation
   */
  useLayoutEffect(() => {
    // abort if the zoom level hasn't been initialized yet
    // most likely the fit-width and fit-height zoom levels haven't been determined yet
    if (!zoomLevel) {
      return;
    }

    // content = the fixed-size frame that scrolls
    const content = contentRef.current;

    // css 'transform' leaves the original element untouched; it only affects how the element is rendered.
    // the original element however remains the same, hence occupying the same space in document flow
    // as a result, the scrollbars will not go away (when zooming out) unless you resize the element
    // https://stackoverflow.com/a/53799005
    // however if we resize the element then the next zoom will apply the transformation to an element of a different size
    // to overcome this we use a trick inspired from here https://stackoverflow.com/a/53490953
    // we use a parent (wrapper) and a child (list)
    // we resize the parent so that the scrollbars will adapt accordingly
    // and we scale the child while retaining the original size so the zoom will always work consistently
    // finally we hide parent overflow so the child 'excess' is not visible
    const pageListWrapper = content.querySelector('.doc-viewer-page-list-wrapper');
    const pageList = content.querySelector('.doc-viewer-page-list');

    // get the width of the list and the scroll of the content frame prior to applying the transformation
    // once we zoom, the browser will modify the scroll bars and we will loose this info
    // and we need it to calculate the new scroll bars position
    // so that the reference point always remains in the same spot (natural zoom)
    // we take the center of the visible content as the reference point
    // since the content is anchored top-left, when zooming in/out, the reference point will move to the right/left
    // to move it back into position we have to play with scrollLeft, scrollTop
    const oldListWidth = pageListWrapper.offsetWidth;
    const oldListHeight = pageListWrapper.offsetHeight;
    const oldScrollLeft = content.scrollLeft;
    const oldScrollTop = content.scrollTop;

    // calculate the coordinates of the reference point relative to the content frame
    const contentHorCenterPx = content.offsetWidth / 2;
    const contentVerCenterPx = content.offsetHeight / 2;

    // calculate the coordinates of the reference point relative to the page list
    // the page list can be:
    // 1. aligned with the content frame
    // 2. farther left than the content frame (a.k.a. the page list is larger than the content frame and the latter is scrolled)
    // 3. farther right than the content frame (a.k.a. the page list is smaller than the content frame and is centered into the latter)
    let oldViewHorCenterPx, oldViewVerCenterPx;

    // if the width of the page list is larger than the width of the content frame
    // then this means that the content frame has horizontal scroll
    // so the page list may be more to the left than the content frame (content.scrollLeft > 0)
    if (oldListWidth > content.clientWidth) {
      // add the content frame scrollLeft to the reference point coordinates
      oldViewHorCenterPx = contentHorCenterPx + oldScrollLeft;
      // if the width of the page list is smaller than the width of the content frame
      // then this means that the content frame has no horizontal scroll
      // but there is 'empty' space between the page list and the content frame
      // so the page list is farther to the right than the content frame
    } else if (oldListWidth < content.clientWidth) {
      // subtract half the 'empty' space from the reference point coordinates
      // since the page list is centered in the container, space is divided equaly between left and right
      const hSpace = content.clientWidth - oldListWidth;
      oldViewHorCenterPx = contentHorCenterPx - (hSpace / 2);
      // if the width of the page list equals the width of the content frame
      // no change to the coordinates is needed
    } else {
      oldViewHorCenterPx = contentHorCenterPx;
    }

    // if the height of the page list is larger than the height of the content frame
    // then this means that the content frame has vertical scroll
    // so the page list may be more to the top than the content frame (content.scrollTop > 0)
    if (oldListHeight > content.clientHeight) {
      // add the content frame scrollTop to the reference point coordinates
      oldViewVerCenterPx = contentVerCenterPx + oldScrollTop;
      // if the height of the page list is smaller than the height of the content frame
      // then this means that the content frame has no vertical scroll
      // also there is no 'empty' space between the page list and the content frame
      // because the page list is not vertically centered but anchored to the top
      // therefore no change to the coordinates is needed
    } else {
      oldViewVerCenterPx = contentVerCenterPx;
    }

    // translate px coordinates into percentages
    // how far (%) was the reference point from the top/left edge of the list
    const oldViewHorCenterPc = oldViewHorCenterPx / oldListWidth * 100;
    const oldViewVerCenterPc = oldViewVerCenterPx / oldListHeight * 100;

    // calculate and apply the new wrapper width based on the new zoom level
    // this will update the content frame horizontal scroll bar
    pageListWrapper.style.width = `${getZoomedPageListWidth()}px`;
    // calculate and apply the new list width based on the new zoom level
    pageList.style.width = `${getZoomedPageListWidth() / getZoomScaleFactor()}px`;
    // scale the list based on the new zoom level
    pageList.style.transform = `scale(${getZoomScaleFactor()})`;
    // calculate and apply the new wrapper height based on the new zoom level
    // this will update the content frame vertical scroll bar
    pageListWrapper.style.height = `${getZoomedSize(pageList.offsetHeight)}px`;

    // now that the zoom transformation has been applied, let's see where the reference point landed after the change
    // same percent but this time of a different width/height
    const newViewHorCenterPx = oldViewHorCenterPc / 100 * pageListWrapper.offsetWidth;
    const newViewVerCenterPx = oldViewVerCenterPc / 100 * pageListWrapper.offsetHeight;

    // calculate the difference between the old and new reference point coordinates
    const horDiffPx = newViewHorCenterPx - contentHorCenterPx;
    const verDiffPx = newViewVerCenterPx - contentVerCenterPx;

    // adjust the content frame scroll with the difference
    content.scrollLeft = horDiffPx;
    content.scrollTop = verDiffPx;
  }, [zoomLevel]);

  /**
   * Creates an IntersectionObserver that monitors pages entering/exiting view
   * We use it to set the active page based on the scroll position
   * @returns {IntersectionObserver}
   */
  const initIntersectionObserver = () => {
    const options = {
      root: contentRef.current,
      threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9],
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(en => {
        const intersectionPercent = en.intersectionRect.height / en.rootBounds.height;
        if (intersectionPercent > 0.5) {
          const targetPageNum = parseInt(en.target.dataset.pageNum);
          setActivePageNum(targetPageNum, false);
        }
      });
    }, options);
    contentRef.current.querySelectorAll('.doc-viewer-page').forEach(page => {
      observer.observe(page);
    });
    return observer;
  }

  /**
   * Scrolls the active page into view
   */
  const scrollToActivePage = () => {
    // get the metrics of the page list
    const list = contentRef.current.querySelector('.doc-viewer-page-list');
    const listRect = list.getBoundingClientRect();
    // get the metrics of the page to be activated
    const page = list.querySelector('.doc-viewer-page-' + activePageNum);
    const pageRect = page.getBoundingClientRect();
    // calculate the vertical offset of the page from the top of the list
    // there seems to be a little jump of 1px that we need to compensate for
    const scrollPos = pageRect.top - listRect.top + 1;
    // scroll the container to that position
    contentRef.current.scrollTo(0, scrollPos);
  }

  /**
   * Scrolls the active field (next field to be signed) into view
   */
  const scrollToActiveField = () => {
    // initially there is not active field
    if (!activeFieldId) {
      return;
    }
    // get the metrics of the page list
    const list = contentRef.current.querySelector('.doc-viewer-page-list');
    const listRect = list.getBoundingClientRect();
    // get the metrics of the field to be activated
    const field = list.querySelector('#doc-viewer-field-' + activeFieldId);
    // only added fields have ids, signed fields do not have an id
    // so when previewing a signed doc this will be null
    if (!field) {
      // there are checks in place to not reach this point but just in case
      return;
    }
    const fieldRect = field.getBoundingClientRect();
    // calculate the vertical offset of the field from the top of the list
    // set a margin (10px) between the field and the top of the container
    const scrollPos = fieldRect.top - listRect.top - 10;
    // scroll the container to that position
    contentRef.current.scrollTo(0, scrollPos);
  }

  /**
   * Event handler called when a page has finished loading
   * Increments the number of loaded pages
   * @returns {void}
   */
  const onPageReady = () => setNumLoadedPages(num => num + 1);

  /**
   * Checks whether all doc pages have loaded
   * @returns {boolean}
   */
  const areAllPagesReady = () => numLoadedPages === orderDoc.numOfPages;

  return <div className="doc-viewer-content" ref={contentRef}>
    <div className="doc-viewer-page-list-wrapper">
      <div className="doc-viewer-page-list">
        {[...Array(orderDoc.numOfPages).keys()].map(pageNum => <DocViewerPage key={pageNum} pageNum={pageNum + 1} onPageReady={onPageReady} />)}
      </div>
    </div>
  </div>
}

export default DocViewerContent;