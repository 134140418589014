import React, { useEffect, useState } from "react";
import DesktopMenu from "components/Shared/DesktopMenu";
import { Row, Col, Container } from "reactstrap";
import Footer from "components/Shared/Footer";
import DocumentItem from "./Partial/DocumentItem";
import { Link, useNavigate } from "react-router-dom";
import NotFound from "pages/Error/NotFound";
import Preloader from "components/Shared/Preloader";
import { getOrderFinancialContracts } from "helpers/backendHelper";
import { useNavigator } from "context/navigator";
import menuSteps from "constants/menuSteps";

const DesktopContent = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  /********** STATE **********/

  const [isBusy, setIsBusy] = useState(true);
  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // fetch financial documents
    getOrderFinancialContracts().then(res => {
      setIsBusy(false);

      // error fetching documents
      if (res.message) {
        setDocumentsError(true);
        return;
      }

      // go straight to the next step if there are no docs
      if (!res.docs.length) {
        return navigate(getNextRoute())
      }

      // if we have docs
      setDocuments(res.docs);
    }).catch(err => {
      setIsBusy(false);
      setDocumentsError(true);
    })
  }, []);

  return <React.Fragment>
    {/** Documents Preview is done before any signing process begins, therefore we consider the active step (last completed step) to be the previous step (VID) */}
    <DesktopMenu title={"Preview Documents"} activeStep={menuSteps.VID} />
    <Container className="finance-container">
      <Row className="justify-content-center">
        <Col md={10}>
          <Row className="justify-content-center pb-4">
            <div className="documents-actions-row">
              <div className="doc-title">Preview Documents</div>
            </div>
          </Row>
          {documents && <Row className="justify-content-center" id="finance_desktop_wrapper">
            <Col md={12} id="finance_docs_container" className="document-grid gx-0">
              {documents.map((document, index) => (
                <DocumentItem key={document.id} document={document} index={index} />
              ))}
            </Col>
          </Row>}
          {documentsError && <NotFound />}
          <Row className="justify-content-center pb-4">
            <Col md={12}>
              <Row className="justify-content-center">
                <Col md={6}>
                  <Link id="continueBtn" className="btn mt-4 btn-primary w-100" to={getNextRoute()}>Continue with signatures</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
    {isBusy && <Preloader />}
    <Footer />
  </React.Fragment>
}

export default DesktopContent;