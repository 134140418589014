import moment from "moment-timezone";

export const formats = {
  DATE: 'MMM D',
  DATETIME: 'MMM D, h:mm a',
  APPOINTMENT_DATETIME: 'MMMM D, yyy h:mm A z',
  US_DATE: 'MM/DD/Y',
  SHORT_US_DATETIME: 'M/D h:mm a',
  CONTRACT_DATE: 'MM.DD.Y',
  SIGNATURE_DATE: 'MMM D, Y | h:mm:ss a',
  CHAT_DATE: 'ddd, MMMM D',
  HOUR: 'kk:mm',
  FULL_DAY: 'dddd',
  TRACKING_DATE: 'MMM D, Y h:mm A',
  SHORT_DATE_FORMAT: 'DD MMM, YYYY',
  LONG_DATE: 'MMM D, Y',
}

export const formatDate = (date, format) => moment(date).format(format);

export const formatTimestamp = (timestamp, format) => moment.unix(timestamp).tz(moment.tz.guess()).format(format);

export const formatTimestampTz = (timestamp, format, timezone) => moment.unix(timestamp).tz(timezone).format(format);

export const timestamp = date => Math.floor((date || Date.now()) / 1000);

export const dateFromTimestamp = timestamp => new Date(timestamp * 1000);

// compares two timestamps and returns true if they represent the same date
// if only 1 arg is received, compares it to the current date
export const isSameDate = (ts1, ts2 = timestamp(new Date())) => {
  return moment(dateFromTimestamp(ts1)).isSame(dateFromTimestamp(ts2), "day");
}

export const isPast7days = ts => {
  return moment(dateFromTimestamp(ts)).isAfter(moment().subtract(7, 'd'))
}

export const getMessageDateFormat = ts => {
  if (isSameDate(ts)) return formats.HOUR;
  if (isPast7days(ts)) return formats.FULL_DAY;
  return formats.US_DATE;
}

