import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import QRCode from "react-qr-code";

const QrCode = (props) => {

  return <React.Fragment>
    <div id="qr_parent">
      <h5 className="qr-description">Scan the <strong>QR Code</strong> with your <strong>mobile device</strong> to complete de <strong>ID Verification</strong></h5>
      <div className="qr-wrapper">
        <QRCode value={props.qrSrc} size={170} />
      </div>
      <Col md={10} sm={10} className="m-auto pt-4">
        <div className="alert alert-danger fade show">
          <i className="fas fa-exclamation-triangle me-2"></i>
          Temporary IDs or paper IDs are not valid
        </div>
        <div className="mb-3 mt-4">Make sure:</div>
        <div className="mb-3">
          <i className="fas fa-address-card me-3 fa-lg"></i>
          You have your ID available
        </div>
        <div className="mb-3">
          <i className="fas fa-wifi me-3 fa-lg"></i>
          You have good internet connection
        </div>
        <div>
          <i className="fas fa-sun me-3 fa-lg"></i>
          You are in a well-lit space
        </div>
      </Col>
    </div>
  </React.Fragment>
}

QrCode.propTypes = {
  qrSrc: PropTypes.string,
};

export default QrCode;