import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoOffcanvas from "./Offcanvas";
import OrderSigner from "model/orderSigner";
import { useAuth } from "context/auth";

const ActionsRow = ({ back }) => {

  const { user: authUser } = useAuth();

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return <React.Fragment>
    <div className="signatures-actions-row">
      {/* if user is a dealer signer, hide the back button, as they should only navigate in the e-signature screens */}
      {authUser.type !== OrderSigner.TYPE_DEALER && <button id="signBackBtn" onClick={back}>
        <i className="fas fa-arrow-left"></i>
      </button>}
      <div className="sign-title">E-Signatures</div>
      <button id="infoBtn" onClick={() => setShowOffcanvas(true)}><i className="fas fa-info-circle"></i></button>
      <InfoOffcanvas show={showOffcanvas} hide={() => { setShowOffcanvas(false) }} />
    </div>
  </React.Fragment>
}

ActionsRow.propTypes = {
  back: PropTypes.func,
};

export default ActionsRow;
