import { Store } from 'react-notifications-component';
import config from 'config';
import randomBytes from 'randombytes';
import Message from "model/message";

export const sleep = time => new Promise((resolve) => setTimeout(resolve, time));

export const valueIsDefined = value => value !== undefined && value !== null;

export const valueIsEmpty = value => {
  if (typeof value === 'string') {
    value = value.trim();
  }
  // allow 0 and FALSE
  return value !== 0 && value !== false && !value;
}

export const mapNonEmpty = obj => Object.entries(obj).reduce((a, [k, v]) => (!valueIsEmpty(v) ? (a[k] = v, a) : a), {});

export const hasNonEmpty = obj => Object.keys(mapNonEmpty(obj)).length > 0;
export const isObject = obj => typeof obj === 'object' && obj !== null;

// axios serializes only plain objects
// so any nested objects will end up as json
// but we need the filters as a query string array
// so here we do the transformation
export const flattenFilters = params => {
  // clone the object to avoid changing the original
  const newParams = { ...params };
  if (newParams.filters) {
    // loop through all filters
    for (const [key, value] of Object.entries(newParams.filters)) {
      // if the filter value is not empty
      if (!valueIsEmpty(value)) {
        // add the filter under the root object
        // change the key so it will be decoded properly on the backend
        newParams[`filters[${key}]`] = value;
      }
    }
    // remove the filters key since it is no longer needed
    delete newParams.filters;
  }
  return newParams;
}

export const showNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 6000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showBriefNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showSuccess = (message, options) => showNotification('success', message, options);

export const showBriefSuccess = (message, options) => showBriefNotification('success', message, options);

export const showMessage = (message, options) => showNotification('info', message, options);

export const showWarning = (message, options) => showNotification('warning', message, options);

export const showError = (message, options) => showNotification('danger', message, options);

export const showBriefError = (message, options) => showBriefNotification('danger', message, options);

export const removeNotification = id => Store.removeNotification(id);

export const trim = (str, char) => {
  char = char.replace(/[-/^$*+?.()|[]{}]/g, '$&');
  return str.replace(new RegExp(
    "^[" + char + "]+|[" + char + "]+$", "g"
  ), "");
}

export const ltrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "^[" + c + "]+", "g"
  ), "");
}

export const rtrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "[" + c + "]+$", "g"
  ), "");
}

export const buildUrl = (base, fragment) => rtrim(base, '/') + (fragment ? '/' + ltrim(fragment, '/') : '');

export const getBeUrl = fragment => buildUrl(config.API_BE_URL, fragment);

export const randomString = len => new Promise((resolve, reject) => {
  randomBytes(len, function (ex, buffer) {
    if (ex) {
      reject(null);
    }
    resolve(buffer.toString('hex'));
  });
});

export const randomStringSync = len => randomBytes(len).toString('hex');

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const dictionaryToSelectOpt0ions = dict => Object.entries(dict).map(entry => ({ label: entry[1], value: entry[0] }));

export const formatPhone = phone => {
  if (!phone) {
    return;
  }
  const clean = phone.replace(/\D/g, '');
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return clean;
  }
  let result = '';
  if (!!match[1]) {
    result += match[1];
  }
  if (!!match[2]) {
    result += '-' + match[2];
  }
  if (!!match[3]) {
    result += '-' + match[3];
  }
  return result;
}

export const openInNewWindow = url => {
  // Safari is blocking any call to open a new window which is made inside an async call, treating it as a pop-up.
  // setTimeout code runs on the main thread, instead of the asynchronous one
  setTimeout(() => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
  })
}

export const stripTags = value => value.replace(/(<([^>]+)>)/gi, "");

export const getInitialsFromName = name => name.match(/\b(\w)/g).join('');

export const filterObjectKeys = (obj, keys) => {
  if (!obj) {
    return null;
  }
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
}

export const bytesToSize = bytes => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}
