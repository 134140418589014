import React, { useEffect, useState } from "react";
import logoSrc from 'assets/images/mavsign-logo-white.svg';
import contactSrc from 'assets/images/contact-modal/contact-topbar.svg';
import Message from "model/message";
import { getOrder } from "helpers/backendHelper";
import { route } from "helpers/routeHelper";
import DesktopChatModal from "components/Shared/DesktopChatModal";
import { useLocation } from "react-router-dom";
import { useMessageChannel } from "hooks/messageChannel";
import DesktopContactModal from "components/Shared/ContactModal/Desktop";

const Topbar = () => {

  const { pathname } = useLocation();

  /********** STATE **********/

  // extract the channel from url params
  // this is useful when the user receives an SMS with the URL for a specific message channel, for which we need to open the chat modal
  const routeChannel = useMessageChannel();
  const [channel, setChannel] = useState(routeChannel);

  // wait until we are redirected from /messages to open the chat
  const shouldOpenChat = !!channel && pathname !== route("messages");

  const [chatModal, showChatModal] = useState(shouldOpenChat);
  const [contactModal, showContactModal] = useState(false);

  const [order, setOrder] = useState(null);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // load order
    getOrder().then(res => {
      if (!res.order) {
        return;
      }
      setOrder(res.order);
    })
  }, []);

  /********** HANDLERS **********/

  const toggleContactModal = () => {
    showContactModal(current => !current);
  };

  const toggleChatModal = () => {
    showChatModal(current => !current);
  }

  const openChatModal = channel => {
    setChannel(channel)
    showContactModal(false);
    showChatModal(true);
  };

  return <React.Fragment>
    <div id="vid_desktop_topbar">
      <div className="topbar-logo">
        <img src={logoSrc} />
      </div>
      <div className="topbar-title">
        <span>Customer ID Validation Service</span>
      </div>
      <div className="topbar-actions">
        <button className="header-btn" onClick={toggleContactModal}>
          <img src={contactSrc} alt="contact" /> Contact us
        </button>
      </div>
    </div>
    {order && <DesktopContactModal order={order} open={contactModal} onToggle={toggleContactModal} onOpenChat={openChatModal} />}
    <DesktopChatModal open={chatModal} onToggle={toggleChatModal} channel={channel || Message.CHANNEL_DEALER_CUSTOMER} />
  </React.Fragment>
}

export default Topbar;