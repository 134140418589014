import React from "react";
import { Col } from "reactstrap";
import iconSrc from 'assets/images/desktop/vid-error-icon.png';
import ValidationNav from "../Partial/ValidationNav";

const VidLimitReached = () => {

  return <React.Fragment>
    <div className="vid-progress">
      <ValidationNav />
      <Col sm={7} md={7} xl={7} className="text-center m-auto pt-5">
        <img id="vid_icon" src={iconSrc}></img>
        <h5 id="vid_title" className="mt-5 mb-4">Verifications limit reached!</h5>
        <div className="vid-desc">Identity verification failed.</div>
        <div className="vid-desc">You have reached your identity verification tries limit.</div>
      </Col>
    </div>
  </React.Fragment>
}

export default VidLimitReached;