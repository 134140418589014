import Message from "model/message";

// allowed channels for customer users
const channels = {
  [Message.CHANNEL_DEALER_CUSTOMER]: "Dealer - Customer",
  [Message.CHANNEL_SUPPORT_DEALER_CUSTOMER]: "Tech Support - Dealer - Customer",
  [Message.CHANNEL_SCHEDULER_CUSTOMER]: "Scheduler - Customer",
  [Message.CHANNEL_NOTARY_CUSTOMER]: "Notary - Customer",
}

export default channels