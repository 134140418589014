import {
  GET_ORDER,
  GET_ORDER_OK,
  GET_ORDER_ERR,
} from "./actionTypes";

export const getOrder = uuid => ({
  type: GET_ORDER,
  payload: uuid,
});

export const getOrderOk = response => ({
  type: GET_ORDER_OK,
  payload: response,
});

export const getOrderErr = error => ({
  type: GET_ORDER_ERR,
  payload: error,
});