import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import StepRecipient from "./StepRecipient";
import StepOptions from "./StepOptions";

const DesktopContactModal = ({ order, open, onToggle, onOpenChat }) => {

  const [selectedPerson, setSelectedPerson] = useState();

  const clearSelectedPerson = () => {
    setSelectedPerson(null);
  }

  return (
    <Modal
      className="contact-modal"
      isOpen={open}
      toggle={onToggle}
      onClosed={clearSelectedPerson}
      centered
    >
      <ModalBody>
        <button type="button" className="btn-close" aria-label="Close" onClick={onToggle} />
        {!selectedPerson && <StepRecipient order={order} selectPerson={person => setSelectedPerson(person)} onOpenChat={onOpenChat} />}
        {!!selectedPerson && <StepOptions selectedPerson={selectedPerson} onOpenChat={onOpenChat} personRoleLabel={'Contact your '} />}
      </ModalBody>
    </Modal>
  );
}

DesktopContactModal.propTypes = {
  order: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onOpenChat: PropTypes.func.isRequired,
}

export default DesktopContactModal;