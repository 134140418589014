import React, { useEffect, useState } from "react";
import { getOrder } from "helpers/backendHelper";
import classnames from "classnames";

const ValidationNav = () => {

  const [menu, setMenu] = useState([]);
  const [itemWidth, setItemWidth] = useState();

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      let localMenu = [];

      if (!res.order) {
        return;
      }
      if (res.order.isVidRequired) {
        localMenu.push("ID verification");
      }
      if (res.order.isEsignRequired) {
        localMenu.push("E-signatures");
      }
      if (res.order.isInkSignRequired) {
        localMenu.push("Ink paper signatures");
      }
      localMenu.push("Finish");

      setMenu(localMenu);
      // set the step width dynamically based on the number of steps available:
      // for 2 steps - 50% each, 3 steps - 33% each, 4 steps - we leave it unset
      setItemWidth(localMenu.length === 3 ? '33%' : localMenu.length === 2 ? '50%' : undefined);
    })
  }, []);

  return <React.Fragment>
    <nav className="navbar progress-nav">
      {menu.map((step, index) => <span className={classnames("nav-item", { active: index === 0 })} key={index} style={{ width: itemWidth }}>
        <span className="nav-item-number">{index + 1}</span>
        {step}
      </span>)}
    </nav>
  </React.Fragment>
}

export default ValidationNav;