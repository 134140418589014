import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { getOrderDoc, getOrderESignContracts, getOrderInkSignContracts } from "helpers/backendHelper";
import Preloader from "components/Shared/Preloader";
import NotFound from "pages/Error/NotFound";
import { Link } from "react-router-dom";
import { useSocketOn } from "hooks/socket";
import { useAuth } from "context/auth";
import socketEvent from "constants/socketEvent";
import OrderDoc from "model/orderDoc";
import { route } from "helpers/routeHelper";
import PropTypes from "prop-types";
import ContractItem from "../Partial/ContractItem";
import OrderSigner from "model/orderSigner";
import OrderNotary from "model/orderNotary";

const Contracts = ({ order, next, goBack }) => {

  const { user: authUser } = useAuth();

  /********** STATE **********/

  const [isBusy, setIsBusy] = useState(true);
  const [contracts, setContracts] = useState(null);
  const [contractsError, setContractsError] = useState(false);
  // check whether we can continue or finish
  const [isEsignComplete, setIsEsignComplete] = useState(false);
  const [canMoveForward, setCanMoveForward] = useState(false);
  const [moveForwardRoute, setMoveForwardRoute] = useState('finish');

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // fetch order e-sign documents
    // signer uuid
    getOrderESignContracts().then(res => {
      setIsBusy(false);
      if (res.docs) {
        let docs = [];
        res.docs.forEach((contract, index) => {
          // this helps keeping contracts sorted
          contract.index = index;
          docs[index] = contract;
        })
        setContracts(docs);
      } else {
        // error fetching documents
        setContractsError(true);
        return;
      }
    }).catch(err => {
      // error fetching documents
      setContractsError(true);
      setIsBusy(false);
    })
    // check if order has ink-sign required
    // we need to know if we must continue with ink-sign or finish
    // if user is of type dealer skip to finish
    if (!order.isInkSignRequired || authUser.type === OrderSigner.TYPE_DEALER) {
      setCanMoveForward(true);
      return;
    }
    if (order.isNotaryRequired) {
      // for orders with notary, users can move forward only after appointment has been set by the notary
      setCanMoveForward(order?.orderNotary?.status >= OrderNotary.STATUS_APPOINTMENT_SET);
      // if current step is setting the appointment, redirect the user to the appointment screen so he can accept/reject it
      setMoveForwardRoute(order?.orderNotary?.status === OrderNotary.STATUS_APPOINTMENT_SET ? "appointment" : "finish");
      return;
    }
    // for ink orders without notary, check if there are ink sign contracts left to sign
    // if not, allow the user to move to finish
    setMoveForwardRoute("ink_signature");
    getOrderInkSignContracts().then(res => {
      // user cannot move forward in case of error fetching documents or no ink documents added yet
      if (!res.docs?.length) return;
      // check if all ink sign docs have been submitted
      let allSubmitted = true;
      res.docs.forEach(doc => {
        if (doc.status < OrderDoc.STATUS_PENDING_DEALER_REVIEW || doc.status == OrderDoc.STATUS_REJECTED) {
          allSubmitted = false;
        }
      });
      if (allSubmitted) {
        setMoveForwardRoute("finish");
      }
      setCanMoveForward(true);
    }).catch(err => {
      // error fetching documents
    });
  }, []);

  // runs whenever `contracts` change
  // to make verifications in order to determine if we are able to continue with ink-sign or finish
  useEffect(() => {
    if (!contracts?.length) return;

    let localCanMove = true;
    contracts.forEach(contract => {
      if (contract.signingId == null) {
        localCanMove = false;
      }
    })
    setIsEsignComplete(localCanMove);
  }, [contracts]);

  /********** SOCKET ********/

  // runs whenever the order doc changed event is received from the backend
  // in order to update the contracts
  const onOrderDocChanged = useCallback(({ id }) => {
    // load the doc that has changed
    getOrderDoc(id).then(res => {
      if (!res.orderDoc) {
        return;
      }
      setIsBusy(true);
      const orderDoc = res.orderDoc;
      let oldDocs = [...contracts];
      const index = oldDocs.findIndex(elem => elem.id == orderDoc.id);
      // set the new doc status, and signingId
      oldDocs[index].status = orderDoc.status;
      oldDocs[index].signingId = orderDoc.signingId;
      oldDocs[index].rejectionReason = orderDoc.rejectionReason;
      // update the contract list
      setContracts(oldDocs);
      setIsBusy(false);
    }).catch(err => {
      // if socket fails, don't show error.
      // page refresh will fix the issue
    })
  }, [contracts]);

  // listen for the order doc changed event
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  return <React.Fragment>
    {contracts && <>
      <Card id="contracts_card" className="desktop e-sign">
        <CardBody id="contracts_card_body">
          <Row className="contracts-row">
            <Col md={4}>
              {contracts.length ? contracts.map((contract, index) => (
                <ContractItem contract={contract} key={index} next={next} />
              ))
                : <div className="no-contracts-message">No contracts added</div>
              }
            </Col>
          </Row>
          <Row>
            <div className="btns-wrapper mt-4">
              {authUser.type !== OrderSigner.TYPE_DEALER && <button id="backBtn" className="btn btn-outline-primary" onClick={goBack}>Back</button>}
              {isEsignComplete && canMoveForward && <Link id="continueBtn" className="btn btn-primary" to={route(moveForwardRoute)}>Continue</Link>}
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
    }
    {isBusy && <Preloader />}
    {contractsError && <NotFound />}
  </React.Fragment>
}

Contracts.propTypes = {
  next: PropTypes.func,
  order: PropTypes.object,
  goBack: PropTypes.func,
};

export default Contracts;
