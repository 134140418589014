import React, { useState } from "react";
import { Link, useParams } from "react-router-dom"
import { route } from "helpers/routeHelper";
import InfoOffcanvas from "./Offcanvas";

const ActionsRow = () => {

  // read route parameters
  let { uuid } = useParams();

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return <React.Fragment>
    <div className="signatures-actions-row">
      <Link id="signBackBtn" to={route('signatures', { uuid: uuid })}>
        <i className="fas fa-arrow-left"></i>
      </Link>
      <div className="sign-title">Ink Paper Signature</div>
      <button id="infoBtn" onClick={() => setShowOffcanvas(true)}><i className="fas fa-info-circle"></i></button>
      <InfoOffcanvas show={showOffcanvas} hide={() => { setShowOffcanvas(false) }} />
    </div>

  </React.Fragment>
}

export default ActionsRow;
