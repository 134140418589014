import React from 'react';
import PropTypes from "prop-types";
import { getInitialsFromName} from "../../helpers/utilHelper";
import UserAvatar from "./UserAvatar";

const NotaryInfo = ({notaryInfo}) => {

  return <div className="notary-info-box mt-4 mb-4">
    <UserAvatar className='mt-4' size='xl' id={notaryInfo.notaryUserId} image={notaryInfo.notaryPicture} initials={getInitialsFromName(notaryInfo.notaryFullName)} />
    <div className="notary-name mt-2">{notaryInfo.notaryFullName}</div>
  </div>
}


NotaryInfo.propTypes = {
  notaryInfo: PropTypes.object,
};
export default NotaryInfo;