/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

// Direct route

export const SET_DIRECT_ROUTE = "DIRECT_ROUTE";

export const GO_IDLE = 'GO_IDLE'
export const GO_ACTIVE = 'GO_ACTIVE'
