import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";

const Confirmation = props => {

  return <SweetAlert
    title="Warning"
    warning
    showCancel
    confirmBtnBsStyle="danger"
    confirmBtnCssClass="me-2"
    cancelBtnBsStyle="secondary"
    cancelBtnCssClass="ms-2"
    btnSize="default"
    reverseButtons={true}
    {...props}>
    {props.children}
  </SweetAlert>
}

Confirmation.propTypes = {
  children: PropTypes.any,
}

export default Confirmation;