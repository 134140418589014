import Preloader from "components/Shared/Preloader";
import { getOrder } from "helpers/backendHelper";
import NotFound from "pages/Error/NotFound";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import DesktopMenu from "components/Shared/DesktopMenu";
import Footer from "components/Shared/Footer";
import Contracts from "./Steps/Contracts";
import Sign from "./Steps/Sign";
import { useNavigate } from "react-router-dom";
import { route } from "helpers/routeHelper";
import menuSteps from "constants/menuSteps";

// the e-signature process has 2 steps
const STEP_CONTRACTS = 0; // selecting the contract to sign
const STEP_SIGN = 1; // signing the contract

const DesktopContent = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  const [currentStep, setCurrentStep] = useState(STEP_CONTRACTS);
  const [contractId, setContractId] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    setIsBusy(true);
    getOrder().then(res => {
      setIsBusy(false);
      if (!res.order || !res.order.isEsignRequired) {
        setOrderError(true);
        return;
      }
      setOrder(res.order)
    }).catch(err => {
      setOrderError(true);
    })
  }, []);

  /********** HANDLERS **********/

  // if we are on the first step of e-signature (selecting the contract)
  // it means that on back, we should redirect the user to the signatures page
  const decreaseStep = () => {
    if (currentStep - 1 < STEP_CONTRACTS) {
      navigate(route('signatures'));
    } else {
      setCurrentStep(currentStep - 1);
    }
  }

  /********** OTHER **********/

  // move to the signing step
  const signContract = (id) => {
    setContractId(id);
    setCurrentStep(STEP_SIGN);
  }

  // list of steps in the e-sign process
  // each step contains:
  // - component -> the component to render
  // - options -> props sent to that component
  const steps = [
    {
      component: Contracts,
      options: {
        next: signContract,
        order,
        goBack: decreaseStep
      },
    },
    {
      component: Sign,
      options: {
        id: contractId,
        goBack: decreaseStep,
      }
    }
  ];

  const ActiveStep = steps[currentStep].component;

  return <React.Fragment>
    <div id="signature_desktop_wrapper">
      {order && <>
        <DesktopMenu title={"All contracts to be signed"} activeStep={menuSteps.SIGNATURES} />
        <Container>
          <Row className="justify-content-center e-sign-row">
            <Col md={12} className="p-0">
              <ActiveStep {...steps[currentStep].options} />
            </Col>
          </Row>
        </Container>
      </>
      }
      <Footer />
      {isBusy && <Preloader />}
      {orderError && <NotFound />}
    </div>
  </React.Fragment>
}

export default DesktopContent;
