import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, CardBody, Card, Container, Input, Label } from "reactstrap";

const Instructions = ({ next, consent, accepted, customerName }) => {

  const [termsAccepted, setTermsAccepted] = useState(accepted);
  const [name, setName] = useState(customerName);

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked)
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  return <React.Fragment>
    <div id="id_instructions" className="account-pages my-3">
      <Container>
        <Row className="justify-content-center">
          <Col md={9} lg={6} xl={5} className="vid-column">
            <Card className="overflow-hidden">
              <CardBody className="pt-3">
                <div className="p-2 mb-2">
                  <h5 className="confirm-title">Scan your <strong>driver license/passport</strong> and take a selfie to <strong>confirm your identity</strong></h5>
                </div>
                <div className="alert alert-danger fade show">
                  <i className="fas fa-info-circle me-2"></i>
                  Temporary IDs or paper IDs are not valid
                </div>
                <div className="mb-3 mt-4">Make sure:</div>
                <div className="mb-3">
                  <i className="fas fa-address-card me-3 fa-lg"></i>
                  You have your Driver License/Passport
                </div>
                <div className="mb-3">
                  <i className="fas fa-address-card me-3 fa-lg"></i>
                  For Passport Cards go to Driver License tab
                </div>
                <div className="mb-3">
                  <i className="fas fa-wifi me-3 fa-lg"></i>
                  You have good internet connection
                </div>
                <div className="mb-3">
                  <i className="fas fa-sun me-3 fa-lg"></i>
                  You are in a well-lit space
                </div>
              </CardBody>
            </Card>
            <Card className="overflow-hidden">
              <CardBody>
                <Input type="text" className="form-control consent-signer-name" placeholder="First and Last name" onChange={handleNameChange} value={name} />
                <div className="consent-wrapper">
                  <div className="form-check form-switch form-switch-lg">
                    <Input
                      type="checkbox"
                      name="terms-consent"
                      onChange={handleTermsChange}
                      id="termsConsentCheck"
                      className="form-check-input-lg me-2"
                      defaultChecked={accepted}
                    />
                    <Label className="form-check-label" htmlFor="terms-consent" />
                  </div>
                  <button id="consentClick" className="ms-0 pt-1" onClick={() => consent(termsAccepted, name)}>Consent to <u>the use and release of my digital photograph</u></button>
                </div>
              </CardBody>
            </Card>
            <button to="" className={`btn btn-primary w-100 ${!termsAccepted || !name.trim() ? "disabled" : ""}`} onClick={() => next(name, termsAccepted)}>Start</button>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

Instructions.propTypes = {
  next: PropTypes.func,
  consent: PropTypes.func,
  accepted: PropTypes.bool,
  customerName: PropTypes.string
};

export default Instructions;