import { combineReducers } from "redux";
import {
  GET_DOC_VIEWER_ORDER_DOC,
  GET_DOC_VIEWER_ORDER_DOC_OK,
  GET_ORDER_DOC_ERR,
  DO_ORDER_DOC_SINGLE_CLEANUP,
  SIGN_ORDER_DOC,
  SIGN_ORDER_DOC_OK,
  SIGN_ORDER_DOC_ERR,
  DO_ORDER_DOC_SIGNING_CLEANUP,
} from "./actionTypes";

/********** SINGLE **********/

const defaultSingleState = {
  orderDoc: null,
  orderDocError: null,
  isLoadBusy: false,
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_DOC_VIEWER_ORDER_DOC:
      state = {
        ...state,
        orderDocError: null,
        isLoadBusy: true,
      };
      break
    case GET_DOC_VIEWER_ORDER_DOC_OK:
      state = {
        ...state,
        orderDoc: {
          ...action.payload.docResponse.orderDoc,
          signers: action.payload.signersResponse.signers,
          signings: action.payload.signingsResponse.signings,
          capturedSignatures: action.payload.signaturesResponse.signatures,
        },
        isLoadBusy: false,
      };
      break
    case GET_ORDER_DOC_ERR:
      state = {
        ...state,
        orderDoc: null,
        orderDocError: action.payload.error,
        isLoadBusy: false,
      };
      break
    // cleanup
    case DO_ORDER_DOC_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
      break
  }
  return state;
}

/********** SIGNING **********/

const defaultSigningState = {
  signed: null,
  signError: null,
  isBusy: false,
};

const Signing = (state = defaultSigningState, action) => {
  switch (action.type) {
    // save
    case SIGN_ORDER_DOC:
      state = {
        ...state,
        signed: null,
        signError: null,
        isBusy: true,
      };
      break
    case SIGN_ORDER_DOC_OK:
      state = {
        ...state,
        signed: true,
        isBusy: false,
      };
      break
    case SIGN_ORDER_DOC_ERR:
      state = {
        ...state,
        signed: false,
        signError: action.payload.error,
        isBusy: false,
      };
      break
    // cleanup
    case DO_ORDER_DOC_SIGNING_CLEANUP:
      state = { ...defaultSigningState };
      break
  }
  return state;
}


export default combineReducers({
  Single,
  Signing,
})