import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Index from "./index/reducer"
import OrderDoc from "./orderDoc/reducer"
import Message from "./message/reducer"

export default combineReducers({
  Layout,
  Index,
  OrderDoc,
  Message,
})
