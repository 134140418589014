import React, { useState } from 'react';
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import OrderDoc from 'model/orderDoc';
import { useSubscribeToOrderDoc } from 'hooks/socket';

const charLimit = 61 // this limit covers one card line length for most smartphones, but it depends on the different lengths of characters used

const getShortRejectionReason = reason => reason.length > charLimit ? `${reason.substr(0, charLimit).trim()}...` : reason;

const ContractItem = ({ contract, next }) => {

  // start receiving updates about this particular order doc (e.g. updates on signing id, order document status)
  useSubscribeToOrderDoc(contract.id)

  const [reasonExpanded, setReasonExpanded] = useState(false);

  return (
    <div className="accordion-item" key={contract.id} contract-id={contract.id}>
      <Row className="contract-item e-sign-item">
        <Row className="contract-description" onClick={contract.status >= OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE ? () => next(contract.id) : null}>
          <Col className="p-0">
            <div className="contract-title">Contract number {contract.index + 1}</div>
            <div className="contract-desc">{contract.name}</div>
            <div className="badges-section">
              {contract.status < OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
                <span className="badge rounded-pill bg-secondary contract-badge status-name">Not ready<i className="bx bx-hourglass ms-1"></i></span>
                <span className="badge rounded-pill bg-secondary contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
              </>
              }
              {contract.status == OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE && <>
                <span className="badge rounded-pill bg-primary contract-badge status-name">Ready to sign<i className="mdi mdi-credit-card-scan-outline ms-1"></i></span>
                <span className="badge rounded-pill bg-primary contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
              </>
              }
              {contract.status == OrderDoc.STATUS_PENDING_DEALER_SIGNATURE && <>
                <span className="badge rounded-pill bg-warning contract-badge status-name"><span>Pending dealership signature</span><i className="bx bx-time-five ms-1"></i></span>
                <span className="badge rounded-pill bg-warning contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
              </>}
              {contract.status == OrderDoc.STATUS_PENDING_DEALER_REVIEW && <>
                <span className="badge rounded-pill bg-warning contract-badge status-name"><span>Pending review</span><i className="bx bx-time-five ms-1"></i></span>
                <span className="badge rounded-pill bg-warning contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
              </>
              }
              {contract.status == OrderDoc.STATUS_REJECTED && <>
                <span className="badge rounded-pill bg-danger contract-badge status-name">Rejected<i className="bx bx-x-circle ms-1"></i></span>
                <span className="badge rounded-pill bg-danger contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
              </>
              }
              {contract.status == OrderDoc.STATUS_COMPLETE && <>
                <span className="badge rounded-pill bg-success contract-badge status-name">Accepted<i className="bx bx-check-circle ms-1"></i></span>
                <span className="badge rounded-pill bg-success contract-badge ms-2">{contract.numOfPages}<i className="far fa-file ms-1"></i></span>
              </>
              }
            </div>
            <div className="font-size-11">
              {contract.signingInstructions}
            </div>
          </Col>
          {contract.status >= OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE &&
            <Col xs={1} className="p-0 accord-arrow">
              <i className="fas fa-angle-right"></i>
            </Col>
          }
        </Row>
        {contract.status == OrderDoc.STATUS_REJECTED &&
          <div className="rejected-reason-wrapper">
            <div className="reason-label">Rejected reason</div>
            <div className="rejection-description">
              {contract.rejectionReason ? (reasonExpanded ? contract.rejectionReason : getShortRejectionReason(contract.rejectionReason)) : ''}
              {contract.rejectionReason && contract.rejectionReason.length > charLimit &&
                // show 'see more' btn only if existing reason has more than a number of characters (61)
                <button className="toggle-reasons-btn" onClick={() => setReasonExpanded(!reasonExpanded)}>
                  {!reasonExpanded && "see more"}
                  {reasonExpanded && "see less"}
                </button>
              }
            </div>
          </div>
        }
      </Row>
    </div>
  );
};

ContractItem.propTypes = {
  contract: PropTypes.object,
  next: PropTypes.func,
};

export default ContractItem;